const Notification = (props) => {
  return (
    <svg
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M15.3604 11.0938C15.3193 11.0664 14.9844 10.6494 14.3555 9.84277C13.7266 9.03613 13.4121 7.52539 13.4121 5.31055C13.3984 4.57227 13.2412 3.88867 12.9404 3.25977C12.6533 2.61719 12.2637 2.06348 11.7715 1.59863C11.293 1.12012 10.7324 0.744141 10.0898 0.470703C9.44727 0.197266 8.75684 0.0605469 8.01855 0.0605469C7.99121 0.0605469 7.96387 0.0605469 7.93652 0.0605469C7.90918 0.0605469 7.88867 0.0673828 7.875 0.0810547C7.84766 0.0673828 7.82031 0.0605469 7.79297 0.0605469C7.7793 0.0605469 7.75879 0.0605469 7.73145 0.0605469C6.99316 0.0605469 6.30273 0.197266 5.66016 0.470703C5.01758 0.744141 4.4502 1.12012 3.95801 1.59863C3.47949 2.06348 3.09668 2.61719 2.80957 3.25977C2.50879 3.88867 2.35156 4.56543 2.33789 5.29004V5.31055C2.33789 7.52539 2.02344 9.03613 1.39453 9.84277C0.779297 10.6494 0.451172 11.0664 0.410156 11.0938C0.287109 11.1758 0.18457 11.2852 0.102539 11.4219C0.0341797 11.5449 0 11.6816 0 11.832C0 12.0781 0.0820312 12.29 0.246094 12.4678C0.423828 12.6318 0.635742 12.7139 0.881836 12.7139H4.40918C4.50488 13.4932 4.80566 14.3066 5.31152 15.1543C5.81738 16.002 6.67188 16.4258 7.875 16.4258C9.07812 16.4258 9.93262 16.002 10.4385 15.1543C10.9443 14.3066 11.2451 13.4932 11.3408 12.7139H14.8682C15.0732 12.7139 15.251 12.6592 15.4014 12.5498C15.5518 12.4268 15.6543 12.2764 15.709 12.0986V12.0781C15.7227 12.0508 15.7295 12.0166 15.7295 11.9756C15.7432 11.9209 15.75 11.873 15.75 11.832C15.75 11.6816 15.709 11.5449 15.627 11.4219C15.5586 11.2852 15.4697 11.1758 15.3604 11.0938ZM7.875 14.6826C7.28711 14.6826 6.87012 14.4502 6.62402 13.9854C6.3916 13.5205 6.24805 13.0967 6.19336 12.7139H9.55664C9.50195 13.0967 9.35156 13.5205 9.10547 13.9854C8.87305 14.4502 8.46289 14.6826 7.875 14.6826Z' />
    </svg>
  );
};

export default Notification;
