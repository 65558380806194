import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon, ContextMenu as CommonContextMenu } from 'components/common';
import { useGet, useDel } from 'hooks';
import { sharePet, pet } from 'services/api';
import { webURL } from 'utils/constants';

import Status from './Status';
import IconText from './IconText';
import Actions from './Actions';

const Container = styled(Link)`
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  min-width: 0px;
  text-decoration: none;
  color: ${(p) => p.theme.colours.primary.dark2};
`;

const Details = styled.div`
  padding: 15px;
`;

const Name = styled.h5`
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 20px;
    font-weight: 700;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
`;

const NoImage = styled.div`
  width: 100%;
  aspect-ratio: 4/3;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PawIcon = styled(Icon)`
  height: 10vh;
  fill: rgba(0, 0, 0, 0.1);
  ${(p) => p.theme.breakpoints.desktop} {
    height: 13vh;
  }
`;

// const Divider = styled.div`
//   height: 1px;
//   width: 100%;
//   background: ${(p) => p.theme.colours.primary.dark1};
//   opacity: 0.1;
//   margin: 10px 0;
// `;

const ContextMenu = styled(CommonContextMenu)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const CopyButton = styled.div`
  position: absolute;
  bottom: 15px;
  right: 10px;
  background: ${(p) => p.theme.colours.primary.main}B0;
  border-radius: 10px;
  color: white;
  font-size: 13px;
  padding: 6px 12px;
  font-weight: 600;
  text-shadow: 0px 1px rgba(0, 0, 0, 0.4);
`;

const Image = ({ src }) => {
  if (!src)
    return (
      <NoImage>
        <PawIcon icon='pawPrint' />
      </NoImage>
    );
  return <StyledImage src={src} />;
};

const Pet = ({
  image,
  name,
  status,
  location,
  _id,
  getPets,
  applicationCount,
  unprocessedApplicationCount,
}) => {
  const [{ res: deleteRes }, deletePet] = useDel({ url: `${pet}/${_id}` });
  const [, getSharePet] = useGet({ url: `${sharePet}/${_id}` });
  const [copyText, setCopyText] = useState('Copy Link');
  const publicProfileURL = `${webURL}/pet/${_id}`;

  useEffect(() => {
    if (deleteRes) {
      getPets();
    }
  }, [deleteRes, getPets]);

  useEffect(() => {
    let timeout;
    if (copyText !== 'Copy Link') {
      timeout = setTimeout(() => setCopyText('Copy Link'), 2000);
    }
    return () => clearTimeout(timeout);
  }, [copyText]);

  const handleShare = () => {
    getSharePet();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${publicProfileURL}`,
      '',
      'width=410,height=330'
    );
  };

  const handleCopy = async (e) => {
    e.preventDefault();
    await navigator.clipboard.writeText(publicProfileURL);
    setCopyText('Copied!');
  };

  const appStatuses = ['Adopted', 'Rainbow Bridge'];
  const showApplicationBadge =
    unprocessedApplicationCount > 0 && !appStatuses.includes(status);

  return (
    <Container to={`/pet/${_id}`}>
      <ContextMenu
        actions={
          <Actions
            petId={_id}
            onDelete={deletePet}
            onShare={handleShare}
            publicProfileURL={publicProfileURL}
          />
        }
      />
      <ImageContainer>
        <Image src={image} />
        <CopyButton onClick={handleCopy}>{copyText}</CopyButton>
      </ImageContainer>
      <Details>
        <Status status={status} />
        <Name>{name}</Name>
        <IconText icon='location' label={location} />
        {applicationCount > 0 && (
          <IconText
            icon='application'
            label={`${applicationCount} Applications`}
            badge={
              showApplicationBadge &&
              `${unprocessedApplicationCount} Unprocessed`
            }
          />
        )}
        {/* <IconText icon='message' label='4 Messages' badge={1} /> */}
      </Details>
    </Container>
  );
};

export default Pet;
