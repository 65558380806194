import styled from 'styled-components';

import { DownIcon, InputContainer } from './styled';

export const StyledSelect = styled.select`
  -webkit-appearance: none;
  background: #f3f5f9;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colours.primary.main}00;
  border-radius: 16px;
  outline: none;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  padding: ${(p) => (p.icon ? '15px 15px 15px 50px' : '15px')};
  color: ${(p) => p.theme.colours.primary.dark2};
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const Option = styled.option`
  color: ${(p) => p.theme.text};
`;

const MobileSelect = ({
  placeholder,
  options,
  multi,
  onChange,
  value,
  className,
}) => {
  const handleChange = (e) => {
    if (multi) {
      onChange(Object.values(e.target.selectedOptions).map((x) => x.value));
    } else {
      onChange(e.target.value);
    }
  };

  return (
    <InputContainer className={className} mobile>
      <DownIcon icon='down' />
      <StyledSelect
        className='select-component'
        multiple={multi}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        size={1}
      >
        {
          <option hidden disabled>
            {placeholder}
          </option>
        }
        {options.map((o) => (
          <Option key={o.value} value={o.value}>
            {o.label}
          </Option>
        ))}
      </StyledSelect>
    </InputContainer>
  );
};

export default MobileSelect;
