const Charity = (props) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#charityClip)'>
        <path d='M23.6868 13.6267C22.9372 13.1187 22.054 13.2626 21.2718 13.7141C20.4897 14.1655 15.9991 18.0051 15.9991 18.0051L11.5787 18.006C11.3731 18.006 11.1504 17.7747 11.1504 17.5699C11.1504 17.3292 11.3602 17.1416 11.5787 17.1416H14.2832C15.2298 17.1416 16.2895 16.5248 16.2895 15.4283C16.2895 14.2632 15.2298 13.7149 14.2832 13.7149C12.6409 13.7149 12.3214 13.7218 12.3214 13.7218C11.6635 13.7218 10.9962 13.6824 10.4822 13.4074C9.79682 13.0562 9.00697 12.8574 8.16402 12.8574C6.74881 12.8574 5.4818 13.422 4.61314 14.312L0 18.8541L5.13999 23.9941L6.85332 22.2807H15.5288C16.3923 22.2807 17.2258 21.9638 17.8709 21.3907L23.745 14.8012C24.104 14.4825 24.0834 13.8966 23.6868 13.6267Z' />
        <g opacity='0.8'>
          <path d='M14.8736 5.97847C14.0663 5.00366 13.0457 4.4668 12 4.4668C10.9543 4.4668 9.93373 5.00366 9.12641 5.97847C8.40071 6.85468 7.96747 7.95896 7.96747 8.93241C7.96747 9.40529 8.11203 9.76547 8.39712 10.003C8.67852 10.2375 9.05002 10.3168 9.46449 10.3168C9.90551 10.3168 10.3952 10.227 10.8764 10.1387C11.2968 10.0616 11.6939 9.98872 12 9.98872C12.2642 9.98872 12.6367 10.0579 13.031 10.1311C13.9525 10.3021 14.997 10.496 15.6003 9.99437C15.8871 9.75589 16.0326 9.39858 16.0326 8.93241C16.0326 7.95896 15.5994 6.85468 14.8736 5.97847Z' />
        </g>
        <path d='M14.9561 0.685313C14.6607 0.249774 14.2377 0 13.7955 0C13.3534 0 12.9304 0.249797 12.635 0.685313C12.3636 1.08541 12.2142 1.61084 12.2142 2.1648C12.2142 2.71877 12.3636 3.24419 12.635 3.6443C12.9304 4.07981 13.3534 4.32961 13.7955 4.32961C14.2377 4.32961 14.6607 4.07981 14.9561 3.6443C15.2275 3.24419 15.3769 2.71877 15.3769 2.1648C15.3769 1.61084 15.2275 1.08539 14.9561 0.685313Z' />
        <path d='M11.365 0.685312C11.0696 0.249773 10.6466 0 10.2044 0C9.76225 0 9.33925 0.249797 9.04387 0.685312C8.77251 1.08541 8.62305 1.61084 8.62305 2.1648C8.62305 2.71877 8.77248 3.24419 9.04387 3.6443C9.33927 4.07983 9.76225 4.32963 10.2044 4.32963C10.6466 4.32963 11.0696 4.07983 11.365 3.6443C11.6363 3.24419 11.7858 2.7188 11.7858 2.1648C11.7858 1.61084 11.6363 1.08539 11.365 0.685312Z' />
        <path d='M17.9301 4.0833C17.811 3.67821 17.5558 3.38385 17.2114 3.2545C16.9343 3.15042 16.6215 3.16806 16.3306 3.30421C15.9297 3.49181 15.602 3.87918 15.4316 4.36699C15.2923 4.76561 15.275 5.18695 15.3827 5.55344C15.5018 5.95853 15.7571 6.25289 16.1015 6.38224C16.2206 6.427 16.3464 6.44924 16.4742 6.44924C16.6435 6.44924 16.8165 6.41013 16.9823 6.33253C17.3832 6.14493 17.7109 5.75756 17.8813 5.26978V5.26975C18.0205 4.87115 18.0379 4.44979 17.9301 4.0833Z' />
        <path d='M8.56843 4.36695C8.39801 3.87915 8.07033 3.4918 7.66946 3.3042C7.37853 3.16805 7.06569 3.15043 6.78858 3.25449C6.44419 3.38387 6.18894 3.67822 6.06985 4.08329C5.96211 4.44978 5.97947 4.87114 6.11872 5.26974V5.26977C6.28915 5.75757 6.61681 6.14492 7.01769 6.33251C7.18353 6.41012 7.35647 6.44923 7.52586 6.44923C7.65364 6.44923 7.7794 6.42697 7.89856 6.38223C8.243 6.25285 8.49823 5.9585 8.61732 5.55343C8.72501 5.18696 8.70765 4.7656 8.56843 4.36695Z' />
      </g>
      <defs>
        <clipPath id='charityClip'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Charity;
