import styled from 'styled-components';
import { useState } from 'react';

import { useAuth, useGet } from 'hooks';
import { charity, charitySettings } from 'services/api';

import Details from './Details';
import Fields from './Fields';
import Notifications from './Notifications';
import Automations from './Automations';
import FosterFields from './FosterFields';
import Integrations from './Integrations';
import { Nav, Option, Panel } from '../shared';
import { Container } from '../styled';
import { Button, Icon } from 'components/common';

const FieldsActions = ({ onAdd }) => <Button onClick={onAdd}>Add</Button>;

const CodeIcon = styled(Icon)`
  height: 16px;
  stroke: ${(p) =>
    p.isActive ? p.theme.colours.secondary.two : p.theme.colours.primary.dark4};
  fill: none !important;
  margin-right: 12px;
`;

const CharitySettings = () => {
  const { user } = useAuth();
  const [activeIndex, setActiveIndex] = useState();
  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [showAddFosterFieldModal, setShowAddFosterFieldModal] = useState(false);

  const [{ res: details, loading: loadingDetails }] = useGet({
    url: `${charity}/${user.charityId}`,
    onMount: true,
  });

  const [{ res: settings, loading: loadingSettings }, getSettings] = useGet({
    url: charitySettings,
    onMount: true,
  });

  return (
    <Container>
      <h5>Charity Settings</h5>
      <Nav
        title={user.charityName}
        activeIndex={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        <Option label='Details' icon='profile'>
          <Panel title='Details'>
            <Details
              details={details}
              loading={loadingDetails}
              goBack={() => setActiveIndex()}
            />
          </Panel>
        </Option>
        <Option label='Application Fields' icon='applicationFill'>
          <Panel
            title='Application Fields'
            actions={<FieldsActions onAdd={() => setShowAddFieldModal(true)} />}
          >
            <Fields
              showAddModal={showAddFieldModal}
              settings={settings}
              loading={loadingSettings}
              onCloseAddModal={() => setShowAddFieldModal(false)}
              onSave={getSettings}
            />
          </Panel>
        </Option>
        <Option label='Foster Application Fields' icon='applicationFill'>
          <Panel
            title='Foster Application Fields'
            actions={
              <FieldsActions onAdd={() => setShowAddFosterFieldModal(true)} />
            }
          >
            <FosterFields
              showAddModal={showAddFosterFieldModal}
              settings={settings}
              loading={loadingSettings}
              onCloseAddModal={() => setShowAddFosterFieldModal(false)}
              onSave={getSettings}
            />
          </Panel>
        </Option>
        <Option label='Automations' icon='settings'>
          <Panel title='Automations'>
            <Automations
              settings={settings}
              loading={loadingSettings}
              onSave={getSettings}
            />
          </Panel>
        </Option>
        <Option label='Notifications' icon='notification'>
          <Panel title='Notifications'>
            <Notifications />
          </Panel>
        </Option>
        <Option
          label='Integrations'
          overrideIcon={<CodeIcon icon='code' isActive={activeIndex === 5} />}
        >
          <Panel title='Integrations'>
            <Integrations />
          </Panel>
        </Option>
      </Nav>
    </Container>
  );
};

export default CharitySettings;
