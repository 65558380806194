import styled from 'styled-components';

export const Required = styled.span`
  color: ${(p) => p.theme.colours.secondary.three};
  ${(p) =>
    p.text &&
    `
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 10px;
  `}
  ${(p) =>
    p.noMargin &&
    `
    margin-left: 0px;
  `}
`;
