import { useCallback, useEffect, useRef, useState } from 'react';

import { getNextPosition } from './helpers';
import {
  Container,
  Image,
  Boundary,
  Cropper,
  ResizeHandleContainer,
  ResizeHandle,
} from './styled';

const Resizer = (props) => {
  return (
    <ResizeHandleContainer {...props}>
      <ResizeHandle />
    </ResizeHandleContainer>
  );
};

const Crop = ({ onChange, src }) => {
  const containerRef = useRef();
  const imageRef = useRef();
  const boundaryRef = useRef();
  const touchRef = useRef();
  const [boundaryDimensions, setBoundaryDimensions] = useState({});
  const [draggingTarget, setDraggingTarget] = useState();
  const [cropPosition, setCropPosition] = useState({
    top: 10,
    left: 10,
    width: 200,
    height: 150,
  });

  const handleChange = useCallback(() => {
    const scaled = {
      top: cropPosition.top / boundaryDimensions.ratio,
      left: cropPosition.left / boundaryDimensions.ratio,
      width: cropPosition.width / boundaryDimensions.ratio,
      height: cropPosition.height / boundaryDimensions.ratio,
    };
    onChange(scaled);
  }, [onChange, cropPosition, boundaryDimensions]);

  useEffect(() => {
    const handleMouseUp = () => {
      setDraggingTarget(null);
      handleChange();
    };

    const handleTouchEnd = () => {
      setDraggingTarget(null);
      handleChange();
      touchRef.current = null;
    };

    const handleMouseMove = (e) => {
      if (boundaryRef && draggingTarget) {
        const nextPosition = getNextPosition(
          cropPosition,
          e,
          draggingTarget,
          boundaryDimensions
        );
        setCropPosition(nextPosition);
      }
    };

    const handleTouchMove = (e) => {
      const movedTouch = e.touches[0];
      const lastTouch = touchRef.current || {};
      const movementX = movedTouch.clientX - lastTouch.clientX;
      const movementY = movedTouch.clientY - lastTouch.clientY;
      const movement = { movementX, movementY };

      if (boundaryRef && draggingTarget) {
        const nextPosition = getNextPosition(
          cropPosition,
          movement,
          draggingTarget,
          boundaryDimensions
        );
        setCropPosition(nextPosition);
        touchRef.current = movedTouch;
      }
    };

    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [draggingTarget, cropPosition, boundaryDimensions, handleChange]);

  const handleImageLoad = () => {
    let ratio;
    const image = imageRef.current;
    const imageRatio = image.naturalHeight / image.naturalWidth;
    const container = containerRef.current;
    const containerRatio = container.clientHeight / container.clientWidth;

    if (imageRatio > containerRatio) {
      ratio = container.clientHeight / image.naturalHeight;
    } else {
      ratio = container.clientWidth / image.naturalWidth;
    }

    if (cropPosition.width + 10 > image.naturalWidth * ratio) {
      setCropPosition({
        top: 10,
        left: 10,
        width: 160,
        height: 120,
      });
    }

    setBoundaryDimensions({
      width: image.naturalWidth * ratio,
      height: image.naturalHeight * ratio,
      ratio,
    });
  };

  const handleSetDragTarget = (target) => (e) => {
    e.stopPropagation();
    setDraggingTarget(target);
  };

  const handleTouchStart = (target) => (e) => {
    e.stopPropagation();
    setDraggingTarget(target);
    touchRef.current = e.touches[0];
  };

  return (
    <Container ref={containerRef}>
      <Image ref={imageRef} src={src} onLoad={handleImageLoad} />
      <Boundary ref={boundaryRef} {...boundaryDimensions}>
        <Cropper
          onTouchStart={handleTouchStart('base')}
          onMouseDown={handleSetDragTarget('base')}
          {...cropPosition}
        >
          <Resizer
            position='topLeft'
            onMouseDown={handleSetDragTarget('nw')}
            onTouchStart={handleTouchStart('nw')}
          />
          <Resizer
            position='topRight'
            onMouseDown={handleSetDragTarget('ne')}
            onTouchStart={handleTouchStart('ne')}
          />
          <Resizer
            position='bottomLeft'
            onMouseDown={handleSetDragTarget('sw')}
            onTouchStart={handleTouchStart('sw')}
          />
          <Resizer
            position='bottomRight'
            onMouseDown={handleSetDragTarget('se')}
            onTouchStart={handleTouchStart('se')}
          />
        </Cropper>
      </Boundary>
    </Container>
  );
};

Crop.defaultProps = {
  onChange: () => {},
  src: '',
};

export default Crop;
