import styled from 'styled-components';
import { Fragment } from 'react';

import Input from '../Input';
import Select from '../Select';
import TextArea from '../TextArea';
import CurrencyInput from '../CurrencyInput';

const Container = styled.div`
  height: 100%;
  overflow: visible;
`;

const InputSetContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.desktop} {
    flex-flow: wrap;
    > * {
      margin-left: 10px;
      flex: 1;
    }
    > :first-child {
      margin-left: 0px;
    }
  }
`;

const components = {
  select: Select,
  textarea: TextArea,
  currency: CurrencyInput,
};

const InputSet = ({
  onChange,
  index,
  value,
  isListOfStrings,
  inputProps,
  alwaysShowLabel,
}) => {
  const _value = value || (isListOfStrings ? [] : {});

  const handleChange = (key) => (itemValue) => {
    if (isListOfStrings) {
      onChange(itemValue);
    } else {
      onChange({
        ..._value,
        [key]: itemValue,
      });
    }
  };

  return (
    <InputSetContainer isListOfStrings={isListOfStrings}>
      {inputProps.map(({ type, label, key, ...rest }, i) => {
        const Component = components[type] || Input;
        return (
          <Fragment key={i}>
            {rest.newRow && (
              <hr
                style={{ height: 0, flexBasis: '100%', border: 0, margin: 0 }}
              />
            )}
            <Component
              {...rest}
              type={type}
              label={(index === 0 || alwaysShowLabel) && label}
              onChange={handleChange(key)}
              value={isListOfStrings ? _value : _value[key]}
              style={rest.newRow && { marginLeft: 0 }}
            />
          </Fragment>
        );
      })}
    </InputSetContainer>
  );
};

const ListInput = ({ value, onChange, inputProps, alwaysShowLabel }) => {
  const isListOfStrings = inputProps.length === 1;

  const handleChange = (index) => (itemValue) => {
    let newValue = value ? [...value] : [];
    newValue[index] = itemValue;

    if (
      (isListOfStrings && !itemValue) ||
      (!isListOfStrings &&
        Object.values(itemValue).filter((v) => !!v).length === 0)
    ) {
      newValue.splice(index, 1);
    }
    onChange(newValue);
  };

  const renderInputs = () => {
    let inputs = [];
    for (let i = 0; i < value.length + 1; i++) {
      inputs.push(
        <InputSet
          key={i}
          onChange={handleChange(i)}
          value={value[i]}
          index={i}
          inputProps={inputProps}
          isListOfStrings={isListOfStrings}
          alwaysShowLabel={alwaysShowLabel}
        />
      );
    }

    return inputs;
  };

  return <Container>{renderInputs()}</Container>;
};

ListInput.defaultProps = {
  onChange: () => {},
  value: [],
  inputProps: [],
};

export default ListInput;
