import styled from 'styled-components';
import { useRef, useEffect } from 'react';

import { format } from 'utils/date';

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #f3f5f9;
  padding: 13px 15px;
  border-radius: 16px;
  flex: 1;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px;
  }
`;

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  font-size: 16px;
  padding: 0px;
  border: none;
  font-family: 'Nunito';
  outline: none;
  width: 30px;
  background: none;
  color: ${(p) => p.theme.colours.primary.dark2};
`;

const lengths = [2, 2, 4];
const limits = [31, 12];

const DateInput = ({ value, onChange }) => {
  const refs = useRef([]);

  useEffect(() => {
    if (refs.current[0]) {
      const formatted = format(value, 'YYYY-MM-DD');
      if (formatted !== 'Invalid Date') {
        const [year, month, day] = formatted.split('-');
        refs.current[0].value = day;
        refs.current[1].value = month;
        refs.current[2].value = year.replace('+', '');
      }
    }
  }, [value]);

  const handleChange = (index) => {
    const day = refs.current[0]?.value;
    const month = refs.current[1]?.value;
    const year = refs.current[2]?.value;

    const asString = `${year}-${month}-${day}`;
    const asDate = new Date(asString);
    const isValid =
      asDate.toString() !== 'Invalid Date' &&
      day.length === 2 &&
      month.length === 2 &&
      year.length === 4;

    if (isValid) {
      onChange({ target: { value: asDate.toISOString() } });
    } else {
      onChange({ target: { value: undefined } });
    }

    if (index < 2 && refs.current[index].value.length === lengths[index]) {
      refs.current[index + 1].focus();
    }
  };

  const handleBlur = (index) => {
    let value = refs.current[index]?.value;
    if (value) {
      if (value.length < lengths[index]) {
        value = value.padStart(lengths[index], '0');
      }
      if (+value > limits[index]) {
        value = limits[index];
      }
      if (index < 2 && +value === 0) {
        value = '01';
      }
      refs.current[index].value = value;
      handleChange();
    }
  };

  const handleKeyDown = (index) => (e) => {
    if (e.key === 'Backspace' && !e.target.value) {
      e.stopPropagation();
      e.preventDefault();
      if (refs.current[index - 1].value) {
        refs.current[index - 1].value = refs.current[index - 1].value[0];
      }
      refs.current[index - 1].focus();
    }
  };

  const handleFocus = (index) => {
    const toFocus = [0, 1, 2].find(
      (i) => refs.current[i].value?.length !== lengths[i]
    );
    if (toFocus <= index) {
      refs.current[toFocus].focus();
    }
  };

  return (
    <Container>
      <Input
        ref={(el) => (refs.current[0] = el)}
        placeholder='DD'
        maxLength={lengths[0]}
        type='tel'
        onChange={() => handleChange(0)}
        onBlur={() => handleBlur(0)}
      />
      <Input
        ref={(el) => (refs.current[1] = el)}
        placeholder='MM'
        maxLength={lengths[1]}
        style={{ width: 35 }}
        type='tel'
        onKeyDown={handleKeyDown(1)}
        onChange={() => handleChange(1)}
        onBlur={() => handleBlur(1)}
        onFocus={() => handleFocus(1)}
      />
      <Input
        ref={(el) => (refs.current[2] = el)}
        placeholder='YYYY'
        maxLength={lengths[2]}
        style={{ width: '100%' }}
        type='tel'
        onKeyDown={handleKeyDown(2)}
        onChange={() => handleChange(2)}
        onBlur={() => handleBlur(2)}
        onFocus={() => handleFocus(2)}
      />
    </Container>
  );
};

export default DateInput;
