import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Page, Button } from 'components/common';
import { useGet, usePost, useDel } from 'hooks';
import { charity } from 'services/api';

import Form from './Form';
import { Container, Header, Back, BinIcon, Delete, Actions } from './styled';

const Charity = () => {
  const { id } = useParams();
  const { push, goBack } = useHistory();
  const [details, setDetails] = useState({});

  const [{ res: charityInfo, loading }] = useGet({
    url: `${charity}/${id}`,
    onMount: !!id,
  });

  const [{ res }, saveCharity] = usePost({ url: charity });
  const [{ res: delRes, loading: deleting }, deleteCharity] = useDel({
    url: `${charity}/${id}`,
  });

  useEffect(() => {
    if (res || delRes) {
      push('/charities');
    }
  }, [res, delRes, push]);

  useEffect(() => {
    if (charityInfo) {
      setDetails(charityInfo);
    }
  }, [charityInfo]);

  const handleSave = () => {
    saveCharity(details);
  };

  const handleChange = (key) => (value) =>
    setDetails((s) => ({ ...s, [key]: value }));

  const handleDelete = () => {
    deleteCharity();
  };

  const getPageTitle = () => {
    if (id && !charityInfo) return '-';
    if (charityInfo) return charityInfo.name;
    return 'New Charity';
  };

  return (
    <Page>
      <Container>
        <Header>
          <Back onClick={goBack} icon='back' />
          <h5>{getPageTitle()}</h5>
          {id && (
            <Delete onClick={handleDelete}>
              <BinIcon icon='bin' />
              <span>Delete</span>
            </Delete>
          )}
        </Header>
        <Form
          details={details}
          onChange={handleChange}
          loading={loading || deleting}
        />
        <Actions>
          <Button type='secondary' onClick={goBack}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Actions>
      </Container>
    </Page>
  );
};

export default Charity;
