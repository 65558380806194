import { useContext, createContext, useState } from 'react';

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const value = useGenerateMenu();
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export const useMenu = () => useContext(MenuContext);

const useGenerateMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  return {
    showMenu,
    setShowMenu,
  };
};
