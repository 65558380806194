const Security = (props) => {
  return (
    <svg
      viewBox='0 0 14 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12.793 2.64453L6.95312 0.398438C6.91406 0.372396 6.86849 0.359375 6.81641 0.359375C6.76432 0.346354 6.71224 0.339844 6.66016 0.339844C6.60807 0.339844 6.55599 0.346354 6.50391 0.359375C6.45182 0.359375 6.39974 0.372396 6.34766 0.398438H6.36719L0.527344 2.64453C0.371094 2.70964 0.240885 2.8138 0.136719 2.95703C0.0455729 3.08724 0 3.24349 0 3.42578V8.66016C0 9.89714 0.3125 11.0169 0.9375 12.0195C1.57552 13.0221 2.27865 13.8815 3.04688 14.5977C3.8151 15.3138 4.53125 15.8672 5.19531 16.2578C5.85938 16.6615 6.22396 16.8763 6.28906 16.9023C6.34115 16.9414 6.39974 16.9674 6.46484 16.9805C6.52995 16.9935 6.59505 17 6.66016 17C6.72526 17 6.79036 16.9935 6.85547 16.9805C6.92057 16.9674 6.98568 16.9414 7.05078 16.9023H7.03125C7.09635 16.8763 7.46094 16.6615 8.125 16.2578C8.78906 15.8672 9.50521 15.3138 10.2734 14.5977C11.0417 13.8815 11.7448 13.0221 12.3828 12.0195C13.0078 11.0169 13.3203 9.89714 13.3203 8.66016V3.42578C13.3203 3.24349 13.2682 3.08724 13.1641 2.95703C13.0729 2.8138 12.9492 2.70964 12.793 2.64453ZM9.76562 7.35156L6.32812 11.1016C6.26302 11.1927 6.17188 11.2643 6.05469 11.3164C5.95052 11.3555 5.83984 11.375 5.72266 11.375C5.60547 11.375 5.48828 11.3555 5.37109 11.3164C5.26693 11.2643 5.18229 11.1927 5.11719 11.1016L3.55469 9.40234C3.47656 9.32422 3.41797 9.23958 3.37891 9.14844C3.33984 9.04427 3.32031 8.9401 3.32031 8.83594C3.32031 8.60156 3.39844 8.40625 3.55469 8.25C3.72396 8.08073 3.92578 7.99609 4.16016 7.99609C4.27734 7.99609 4.38802 8.02214 4.49219 8.07422C4.60938 8.1263 4.70052 8.19141 4.76562 8.26953L5.72266 9.30469L8.55469 6.23828C8.61979 6.14714 8.70443 6.08203 8.80859 6.04297C8.92578 5.99089 9.04297 5.96484 9.16016 5.96484C9.39453 5.96484 9.58984 6.04948 9.74609 6.21875C9.91536 6.375 10 6.5638 10 6.78516C10 6.90234 9.98047 7.01302 9.94141 7.11719C9.90234 7.20833 9.84375 7.28646 9.76562 7.35156Z' />
    </svg>
  );
};

export default Security;
