import styled from 'styled-components';
import { useEffect, useState } from 'react';

import {
  Modal as CommonModal,
  Button,
  Input,
  Checkbox,
} from 'components/common';
import { usePost } from 'hooks';
import { charitySettings } from 'services/api';

import { FormContainer } from '../../shared';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${(p) => p.theme.colours.primary.dark1};
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  > button {
    margin-left: 15px;
  }
`;

const Modal = ({ show, onClose, onSaveSuccess, settings }) => {
  const [isEnabled, setIsEnabled] = useState(
    settings?.automations?.changeStatusApplicationLimit?.isEnabled
  );
  const [value, setValue] = useState(
    settings?.automations?.changeStatusApplicationLimit?.value || '200'
  );
  const [{ res: saveRes }, saveSettings, setSaveRes] = usePost({
    url: charitySettings,
  });

  useEffect(() => {
    if (saveRes) {
      onSaveSuccess();
      setSaveRes(null);
    }
  }, [saveRes, onSaveSuccess, setSaveRes]);

  const handleSave = () => {
    saveSettings({
      ...settings,
      automations: {
        changeStatusApplicationLimit: {
          isEnabled,
          value,
        },
      },
    });
  };

  const handleCheckboxChange = (value) => {
    setIsEnabled(value);
  };

  const handleLimitChange = (value) => {
    setValue(value);
  };

  return (
    <CommonModal show={show} onClose={onClose}>
      <Content>
        <h5>Automations</h5>
        <div style={{ height: '100%', overflow: 'auto' }}>
          <FormContainer>
            <Checkbox
              size='large'
              label={
                <span>
                  Update pet status to <strong>Processing</strong> after
                  receiving a certain number of applications
                </span>
              }
              value={isEnabled}
              onChange={handleCheckboxChange}
            />
            <Input
              label='Number of applications'
              style={{ marginTop: 10 }}
              disabled={!isEnabled}
              value={value}
              onChange={handleLimitChange}
              type='tel'
            />
          </FormContainer>
        </div>
        <Actions>
          <Button type='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Save</Button>
        </Actions>
      </Content>
    </CommonModal>
  );
};

export default Modal;
