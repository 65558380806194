import styled from 'styled-components';

import { Icon as CommonIcon } from 'components/common';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 6px 0px;
  }
`;

const Icon = styled(CommonIcon)`
  stroke: ${(p) => (p.useStroke ? p.theme.colours.secondary.two : 'none')};
  fill: ${(p) => (p.useStroke ? 'none' : p.theme.colours.secondary.two)};
  width: 12px;
  margin-right: 5px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 15px;
  }
`;

const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

const Badge = styled.div`
  color: white;
  background: ${(p) => p.theme.colours.shade.five};
  color: ${(p) => p.theme.colours.secondary.four};
  border: 1px solid ${(p) => p.theme.colours.secondary.four};
  border-radius: 10px;
  font-size: 9px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 10px;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

const IconText = ({ icon, label, badge }) => {
  return (
    <Container>
      <LabelContainer>
        <Icon icon={icon} useStroke={icon === 'location'} />
        <Label>{label}</Label>
      </LabelContainer>
      {badge && <Badge>{badge}</Badge>}
    </Container>
  );
};

export default IconText;
