import styled from 'styled-components';

import { Icon } from 'components/common';

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark2}1A;
  padding-bottom: 20px;
  margin-bottom: 20px;
  cursor: ${(p) => (p.mandatory ? 'default' : 'pointer')};
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${(p) => p.theme.colours.primary.dark2};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${(p) => p.theme.breakpoints.desktop} {
    min-width: 100px;
    max-width: 350px;
    margin-right: 10px;
  }
`;

export const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${(p) => p.theme.colours.primary.dark3};
  margin-top: 10px;
  display: flex;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-top: 0px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const Chevron = styled(Icon)`
  flex: 0 0 14px;
  fill: ${(p) => p.theme.colours.primary.dark4};
  transform: rotate(270deg);
  margin-top: 6px;
`;

const Detail = ({ title, subtitle, loading, onClick, mandatory }) => {
  return (
    <DetailContainer onClick={onClick} mandatory={mandatory}>
      <Column>
        <Title>{title}</Title>
        <Subtitle>{loading ? '-' : subtitle}</Subtitle>
      </Column>
      <Chevron icon='chevron' />
    </DetailContainer>
  );
};

export default Detail;
