import { useHistory } from 'react-router';

import {
  Container,
  Content,
  CharityIcon,
  AddCharity,
  AddCharityIcon,
} from './styled';

const Header = ({ charityCount }) => {
  const { push } = useHistory();

  const handleAdd = () => {
    push('/charity');
  };

  return (
    <Container>
      <Content>
        <CharityIcon icon='charity' />
        <h6>Charities</h6>
      </Content>
      <Content>
        <span>{charityCount} Charities</span>
        <AddCharity onClick={handleAdd}>
          <AddCharityIcon icon='charity' />
          Add Charity
        </AddCharity>
      </Content>
    </Container>
  );
};

export default Header;
