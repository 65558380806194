import { useEffect, useState } from 'react';
import NoScroll from '../NoScroll';

import { Container, Content, CloseIcon } from './styled';

const Modal = ({ show, onClose, children }) => {
  const [transition, setTransition] = useState('entry');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let visibleTimeout;
    let animationTimeout;
    if (show) {
      setVisible(true);
    } else {
      setTransition('exit');
      visibleTimeout = setTimeout(() => {
        setVisible(false);
      }, 300);
      animationTimeout = setTimeout(() => setTransition('entry'), 600);
    }
    return () => {
      clearTimeout(visibleTimeout);
      clearTimeout(animationTimeout);
      setTransition('entry');
    };
  }, [show]);

  if (!visible) return null;

  return (
    <Container transition={`modal-container-${transition}`}>
      <Content transition={`modal-content-${transition}`}>
        <CloseIcon icon='close' onClick={onClose} />
        {children}
      </Content>
      <NoScroll />
    </Container>
  );
};

export default Modal;
