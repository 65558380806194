import { useState } from 'react';
import { useAuth } from 'hooks';

import Security from './Security';
import { Nav, Option, Panel } from '../shared';
import { Container } from '../styled';

const UserSettings = () => {
  const [activeIndex, setActiveIndex] = useState();
  const { user } = useAuth();

  return (
    <Container>
      <h5>User Settings</h5>
      <Nav
        title={user.name}
        subtitle={user.email}
        activeIndex={activeIndex}
        onChangeIndex={setActiveIndex}
      >
        <Option label='Details' icon='profile'>
          <Panel title='Details'>Details</Panel>
        </Option>
        <Option label='Security Settings' icon='security'>
          <Panel title='Security Settings'>
            <Security goBack={() => setActiveIndex()} />
          </Panel>
        </Option>
        <Option label='Notifications' icon='notification'>
          <Panel title='Notifications'>Notifications</Panel>
        </Option>
      </Nav>
    </Container>
  );
};

export default UserSettings;
