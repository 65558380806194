import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { usePost, useNotification } from 'hooks';
import { petImages } from 'services/api';
import { Modal, ImageUpload } from 'components/common';

import SavedImage from './Image';
import {
  Pane,
  Header,
  Upload,
  UploadIcon,
  NoImages,
  Button,
  SavedImages,
} from './styled';

const Images = ({ pet, onFetch }) => {
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([]);
  const { id } = useParams();
  const { notify } = useNotification();
  const [{ res: uploadSuccess, loading }, postImages, setUploadSuccess] =
    usePost({ url: petImages });

  useEffect(() => {
    if (uploadSuccess) {
      notify(`Successfully uploaded ${images.length ? 'images' : 'image'}`);
      handleCloseModal();
      onFetch();
      setUploadSuccess();
    }
  }, [uploadSuccess, onFetch, notify, images, setUploadSuccess]);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setImages([]);
  };

  const handleUpload = () => {
    postImages({ _id: id, images });
  };

  return (
    <Pane>
      <Header>
        <h6>Images</h6>
        <Upload onClick={handleShowModal}>
          <UploadIcon icon='upload' />
          Upload
        </Upload>
      </Header>
      {pet?.images?.length === 0 && <NoImages>No Images Uploaded</NoImages>}
      <SavedImages>
        {pet?.images?.map((image, i) => (
          <SavedImage key={i} image={image} onFetch={onFetch} />
        ))}
      </SavedImages>
      <Modal show={showModal} onClose={handleCloseModal}>
        <ImageUpload value={images} onChange={setImages} showBlanks={false} />
        <Button
          disabled={loading || images.length === 0}
          onClick={handleUpload}
        >
          Upload
        </Button>
      </Modal>
    </Pane>
  );
};

export default Images;
