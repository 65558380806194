import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 16px;

  > h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

export const FieldsHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
