import styled from 'styled-components';
import { useState, useEffect } from 'react';

import { usePost, useNotification } from 'hooks';
import { userSettings } from 'services/api';
import {
  Modal,
  TabbedPanel,
  Tab,
  Input,
  Button,
  Alert,
} from 'components/common';

import { FormContainer as BaseFormContainer } from '../../shared';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${(p) => p.theme.colours.primary.dark1};
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  > button {
    margin-left: 15px;
  }
`;

const Panel = styled(TabbedPanel)`
  flex: 1;
`;

const FormContainer = styled(BaseFormContainer)`
  margin-bottom: 15px;
`;

const PasswordModal = ({
  onClose,
  onSaveSuccess,
  show,
  onTabChange,
  activeTab,
}) => {
  const { notify } = useNotification();
  const [form, setForm] = useState({});
  const [showError, setShowError] = useState();
  const [{ res: saveRes, loading: saving, error: saveError }, save] = usePost({
    url: userSettings,
  });

  useEffect(() => {
    if (saveRes) {
      onSaveSuccess();
      setForm({});
      notify('Password successfully changed');
    }
  }, [saveRes, onSaveSuccess, notify]);

  const getFormProps = (key) => ({
    value: form[key],
    onChange: (value) => setForm((s) => ({ ...s, [key]: value })),
  });

  const handleSave = () => {
    save(form);
  };

  let error;
  if (!form.currentPassword || !form.newPassword || !form.confirmPassword) {
    error = 'Please fill in all fields';
  } else if (form.newPassword !== form.confirmPassword) {
    error = 'Confirmation password incorrect';
  } else if (saveError) {
    error = 'Invalid current password';
  }

  const disableSave =
    saving ||
    !form.currentPassword ||
    !form.newPassword ||
    !form.confirmPassword ||
    (error && !saveError);

  return (
    <Modal show={show} onClose={onClose}>
      <Content>
        <h5>Update Security Settings</h5>
        <Panel onChange={onTabChange} activeTab={activeTab}>
          <Tab label='Change Password'>
            <FormContainer>
              <Input
                type='password'
                label='Current Password'
                {...getFormProps('currentPassword')}
              />
              <Input
                type='password'
                label='New Password'
                {...getFormProps('newPassword')}
              />
              <Input
                type='password'
                label='Confirm New Password'
                onBlur={() => setShowError(true)}
                {...getFormProps('confirmPassword')}
              />
            </FormContainer>
            {error && showError && <Alert>{error}</Alert>}
          </Tab>
        </Panel>
        <Actions>
          <Button disabled={saving} type='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={saving || disableSave} onClick={handleSave}>
            Save
          </Button>
        </Actions>
      </Content>
    </Modal>
  );
};

export default PasswordModal;
