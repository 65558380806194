import styled from 'styled-components';

import {
  Icon,
  Select as CommonSelect,
  Button as CommonButton,
  Alert as CommonAlert,
} from 'components/common';

export const Container = styled.div`
  background: white;
  border-radius: 16px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const ApplicationContainer = styled.div`
  ${(p) => p.theme.breakpoints.desktop} {
    display: flex;
    padding-bottom: 15px;
    border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  padding-bottom: 15px;
  align-items: center;
  h6 {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const FieldsContainer = styled.div`
  height: ${(p) => p.height}px;
  transition: 0.3s all ease;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 100%;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-right: 15px;
  }
`;

export const Fields = styled.div``;

export const Field = styled.div`
  margin: 10px 0;
  display: inline-block;
  margin-right: 15px;
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark2};
`;

export const Value = styled.div`
  color: ${(p) => p.theme.colours.primary.dark4};
  font-size: 13px;
  font-weight: 400;
  margin-top: 4px;
`;

export const Expand = styled.div`
  width: 100%;
  height: 40px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  bottom: 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`;

export const IconContainer = styled.div`
  background: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ExpandIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.main};
  width: 15px;
  transition: 0.2s all ease;
  transform: rotate(${(p) => (p.expanded ? '180' : '0')}deg);
`;

export const RightActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Details = styled.div`
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-bottom: 0px;
    border-bottom: none;
  }
`;

export const Calendar = styled(Icon)`
  width: 17px;
  stroke: ${(p) => p.theme.colours.secondary.two};
  margin-right: 10px;
`;

export const SubmittedDate = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  opacity: ${(p) => (p.disabled ? '0.5' : 1)};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
`;

export const ActionIconContainer = styled.div`
  transition: 0.2s all ease;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  border: 1px solid
    ${(p) =>
      p.isActive
        ? p.no
          ? p.theme.colours.secondary.three
          : p.theme.colours.secondary.two
        : p.theme.colours.primary.dark4};
  border-radius: 12px;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
  background: ${(p) =>
    p.isActive
      ? p.no
        ? p.theme.colours.secondary.three
        : p.theme.colours.secondary.two
      : 'white'};
`;

export const ActionIcon = styled(Icon)`
  transition: 0.2s all ease;
  width: 17px;
  margin-top: ${(p) => (p.no ? '0' : '-1px')};
  fill: ${(p) => (p.isActive ? 'white' : p.theme.colours.primary.dark4)};
  transform: rotate(${(p) => (p.no ? '180' : '0')}deg);
`;

export const Select = styled(CommonSelect)`
  width: 150px;
`;

export const Button = styled(CommonButton)`
  margin-top: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    margin: 15px auto 0;
  }
`;

export const Alert = styled(CommonAlert)`
  margin: 5px 0px;
  font-size: 13px;
  padding: 10px;
  border-radius: 10px;
`;
