import { Container, Content, UsersIcon, AddUser, AddUserIcon } from './styled';

const Header = ({ users, onAdd }) => {
  const count = users && users.length ? `${users.length} Users` : '-';
  return (
    <>
      <Container>
        <Content>
          <UsersIcon icon='users' />
          <h6>Users</h6>
        </Content>
        <Content>
          <span>{count}</span>
          <AddUser onClick={onAdd}>
            <AddUserIcon icon='addUser' />
            Add User
          </AddUser>
        </Content>
      </Container>
    </>
  );
};

export default Header;
