import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, auto);
  background: white;
  padding: 0px 15px 15px;
  border-radius: 0px 0px 20px 20px;
  .header {
    background: ${(p) => p.theme.colours.secondary.one}1A;
    padding: 12px 0px;
    color: ${(p) => p.theme.colours.primary.dark4};
    font-size: 14px;
    font-size: 600;
  }
  .header:nth-child(1) {
    border-radius: 10px 0 0 10px;
    padding-left: 15px;
  }
  .header:nth-child(${(p) => p.columns}) {
    border-radius: 0px 10px 10px 0px;
    padding-right: 15px;
  }
`;

export const Row = styled.div`
  display: contents;
  position: relative;
  border-bottom: 1px solid red;
  overflow: hidden;
  .cell {
    padding: 30px 0px;
    background: white;
    transition: 0.3s ease all;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    .border {
      position: absolute;
      height: 1px;
      width: 100%;
      background: #f3f4f6;
      bottom: 0;
      left: 0;
    }
  }
  .cell:first-child {
    border-radius: 20px 0 0 20px;
    padding-left: 15px;
    .border {
      left: 15px;
    }
  }
  .cell:last-child {
    border-radius: 0px 20px 20px 0px;
    padding-right: 15px;
    .border {
      left: -15px;
    }
  }
  :hover {
    .cell {
      background: #f3f4f6;
    }
  }
`;
