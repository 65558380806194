import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Page, Select as CommonSelect } from 'components/common';
import { charities, users } from 'services/api';
import { useGet, useAuth } from 'hooks';

import Header from './Header';
import List from './List';

const Container = styled.div`
  padding: 20px 16px;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

const Select = styled(CommonSelect)`
  margin-bottom: 20px;
  .select-control {
    background: white;
  }
`;

const Users = () => {
  const { user } = useAuth();
  const { push } = useHistory();
  const [activeCharity, setActiveCharity] = useState();
  const [{ res: usersList, loading: loadingUsers }] = useGet({
    url: users,
    onMount: true,
    query: { charityId: activeCharity },
  });
  const [{ res: charitiesList, loading: loadingCharities }] = useGet({
    url: charities,
    onMount: user.isPawAdmin,
    query: { asList: true },
  });

  useEffect(() => {});

  const handleAdd = () => {
    push(activeCharity ? `/user?charityId=${activeCharity}` : '/user');
  };

  return (
    <Page>
      <Container>
        {user.isPawAdmin && (
          <Select
            placeholder='Select a charity'
            options={charitiesList}
            disabled={loadingCharities}
            value={activeCharity}
            onChange={setActiveCharity}
            clearable
          />
        )}
        <Header users={usersList} onAdd={handleAdd} />
        <List users={usersList} loading={loadingUsers} />
      </Container>
    </Page>
  );
};

export default Users;
