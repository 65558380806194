import styled from 'styled-components';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from 'hooks';

import Menu from '../Menu';

const Pending = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
`;

const AuthedRoute = ({ children, needs, ...rest }) => {
  const { authPending, user } = useAuth();

  if (authPending) return <Pending />;

  let hasPermission = !needs;
  if (needs && user) {
    hasPermission = Array.isArray(needs)
      ? needs.some((n) => user[n])
      : user[needs];
  }

  return (
    <>
      <Menu />
      <Route
        {...rest}
        render={() => {
          if (!user) return <Redirect to='/login' />;
          if (!hasPermission) return <Redirect to='/' />;
          return children;
        }}
      />
    </>
  );
};

export default AuthedRoute;
