import styled, { css } from 'styled-components';

import { default as CommonIcon } from '../Icon';

const getSidePadding = (pad) => {
  if (pad === 'prefix' || pad === 'suffix') return '60px';
  if (pad) return '50px';
  return '15px';
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  /* overflow: hidden; */
  input[type='date']::-webkit-inner-spin-button {
    z-index: 1;
  }
  input[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
`;

export const Icon = styled(CommonIcon)`
  position: absolute;
  width: 20px;
  ${(p) => (p.iconPlacement === 'start' ? 'left: 20px' : 'right: 20px')};
  stroke: ${(p) => p.theme.colours.primary.dark4};
  pointer-events: none;
`;

export const Counter = styled.div`
  position: absolute;
  right: 15px;
  bottom: -20px;
  font-size: 12px;
  color: ${(p) => p.theme.colours.primary.main};
`;

export const StyledInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background: #f3f5f9;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colours.primary.main}00;
  border-radius: 16px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 13px 15px;
  padding-left: ${(p) => getSidePadding(p.pad.left)};
  padding-right: ${(p) => getSidePadding(p.pad.right)};
  color: ${(p) => p.theme.colours.primary.dark2};
  width: 100%;
  transition: 0.3s ease all;
  min-height: 51px;
  font-family: 'Nunito';
  :focus {
    border: 1px solid ${(p) => p.theme.colours.primary.main}FF;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark2};
  margin-bottom: 10px;
`;

export const fixBase = css`
  position: absolute;
  background: ${(p) => p.theme.colours.primary.dark5}26;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-weight: 600;
  font-size: 14px;
`;

export const Select = styled.select`
  -webkit-appearance: none;
  appearance: none;
  ${fixBase}
  position: absolute;
  border: none;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
  background: #e3e7ed;
  font-family: 'Nunito', -apple-system, sans-serif;
  text-align: center;
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const Suffix = styled.div`
  ${fixBase}
  right: 0px;
  border-radius: 0px 16px 16px 0px;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 0px 20px 20px 0px;
  }
`;
