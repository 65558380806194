import { decodeJwt } from 'jose';
import dayjs from 'dayjs';
import {
  useEffect,
  useContext,
  useCallback,
  createContext,
  useState,
} from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const value = useGenerateAuth();
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);

const parseUser = (decoded) => {
  const user = decoded.data;
  return user;
};

const useGenerateAuth = () => {
  const [authPending, setAuthPending] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    const decode = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = await decodeJwt(token);
        if (dayjs(new Date(decoded.exp * 1000)).isAfter(new Date())) {
          const user = parseUser(decoded);
          setUser(user);
        }
      }
      setAuthPending(false);
    };

    decode();
  }, []);

  const setToken = useCallback((token) => {
    const decode = async () => {
      localStorage.setItem('token', token);
      const decoded = await decodeJwt(token);
      const user = parseUser(decoded);
      setUser(user);
    };

    decode();
  }, []);

  const logOut = () => {
    localStorage.removeItem('token');
    setUser();
  };

  return {
    setToken,
    logOut,
    user,
    authPending,
  };
};
