import styled from 'styled-components';

import { ContextMenu, Icon } from 'components/common';
import { format } from 'utils/date';

import {
  Container,
  Actions,
  ControlsContainer,
  Action,
  Details,
  DetailContainer,
} from './styled';

const Check = styled(Icon)`
  width: 16px;
  stroke: ${(p) => p.theme.colours.secondary.two};
  fill: none;
`;

const Detail = ({ label, value }) => {
  return (
    <DetailContainer>
      <span>{label}</span>
      <span>{value}</span>
    </DetailContainer>
  );
};

const Controls = () => {
  return (
    <ControlsContainer>
      <Action>Update</Action>
      <Action>Delete</Action>
    </ControlsContainer>
  );
};

const MobileCard = ({ user, onclick }) => {
  return (
    <Container>
      <Actions>
        <ContextMenu actions={<Controls />} orientation='horizontal' />
      </Actions>
      <Details>
        <Detail label='Name' value={user.name} />
        <Detail label='Date Added' value={format(user.createdAt)} />
        <Detail
          label='Last Login'
          value={user.lastLogin ? format(user.lastLogin) : '-'}
        />
        <Detail label='Status' value={user.status} />
        <Detail
          label='Fosterer'
          value={user.isFosterer && <Check icon='check' />}
        />
      </Details>
    </Container>
  );
};
export default MobileCard;
