const Rehome = (props) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M19.5429 8.36035H16.3781V11.9999H12.7385V15.1648H16.3781V18.8043H19.5429V15.1648H23.1824V11.9999H19.5429V8.36035Z' />
      <path d='M11.156 10.4174H14.7956V6.7778H21.1253V10.4174H22.9608C23.6185 9.36242 24 8.16643 24 6.85534C24 3.20091 21.0381 0.237779 17.3844 0.237779C15.1644 0.237779 13.2 1.33128 12 3.00933C10.8 1.33123 8.83559 0.237305 6.61561 0.237305C2.96192 0.237305 0 3.19975 0 6.85424C0 8.44288 0.559754 9.90049 1.49269 11.0411L12 23.7625L14.7956 20.3777V16.747H11.156V10.4174Z' />
    </svg>
  );
};

export default Rehome;
