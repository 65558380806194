import { useWidth, useAuth } from 'hooks';
import { Select, CharitySelect } from 'components/common';

import { FilterBar, DogIcon, Header, Filters } from './styled';

const Filter = ({ onChange, value, options, loading }) => {
  const { isDesktop } = useWidth();
  const { user } = useAuth();

  return (
    <FilterBar>
      <Header>
        <DogIcon icon='dog' />
        <h6>Pets</h6>
      </Header>
      <Filters>
        {user.isPawAdmin && (
          <CharitySelect
            label=''
            placeholder='Select Charity'
            onChange={onChange('charityId')}
            value={value.charityId}
          />
        )}
        <Select
          multi
          label={!isDesktop && 'Filter Status'}
          placeholder='Filter Status'
          options={options?.status || []}
          onChange={onChange('status')}
          value={value.status}
          disabled={loading}
        />
      </Filters>
    </FilterBar>
  );
};

export default Filter;
