const MessageFill = (props) => {
  return (
    <svg viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.4573 0.15625C11.396 0.15625 12.299 0.529826 12.9633 1.20015C13.6283 1.86906 14 2.77128 14 3.7158V9.2842C14 11.2508 12.411 12.8438 10.4573 12.8438H3.542C1.5883 12.8438 0 11.2508 0 9.2842V3.7158C0 1.74924 1.5813 0.15625 3.542 0.15625H10.4573ZM11.5699 4.76604L11.6259 4.70965C11.7932 4.50524 11.7932 4.2092 11.6182 4.00479C11.5209 3.89977 11.3872 3.83562 11.2479 3.82153C11.1009 3.81377 10.9609 3.86382 10.8552 3.9625L7.69895 6.5C7.29295 6.83904 6.71125 6.83904 6.29895 6.5L3.14895 3.9625C2.93125 3.80038 2.63025 3.82153 2.44895 4.01184C2.25995 4.20215 2.23895 4.50524 2.39925 4.7167L2.49095 4.80833L5.67595 7.31059C6.06795 7.62073 6.54325 7.7899 7.04095 7.7899C7.53725 7.7899 8.02095 7.62073 8.41225 7.31059L11.5699 4.76604Z'
      />
    </svg>
  );
};

export default MessageFill;
