import { useState } from 'react';

import { Alert } from 'components/common';

import Modal from './Modal';
import { Pane, Header, Upload, ImageIcon, ThumbnailImage } from './styled';

const Thumbnail = ({ pet, onFetch, loading }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const thumbnail = !loading && pet?.thumbnail;

  return (
    <Pane>
      <Header>
        <h6>Face Photo</h6>
        <Upload onClick={handleShowModal}>
          <ImageIcon icon='image' />
          Choose Photo
        </Upload>
      </Header>
      {!thumbnail && (
        <Alert type='error'>
          Add a face photo of the pet to increase the chances of adoption!
        </Alert>
      )}
      {thumbnail && <ThumbnailImage src={thumbnail.url} />}
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        pet={pet}
        onFetch={onFetch}
      />
    </Pane>
  );
};

export default Thumbnail;
