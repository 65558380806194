import { ContextMenuOptions, ContextMenuOption } from 'components/common';

const Actions = ({ onShare, publicProfileURL }) => {
  const handleViewPublic = () => {
    window.open(publicProfileURL, '_blank');
  };

  return (
    <ContextMenuOptions>
      <ContextMenuOption onClick={handleViewPublic}>
        Public Profile
      </ContextMenuOption>
      <ContextMenuOption onClick={onShare}>Share to FB</ContextMenuOption>
    </ContextMenuOptions>
  );
};

export default Actions;
