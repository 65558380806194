import { Redirect, Route } from 'react-router-dom';

import { useAuth } from 'hooks';

import Login from './Login';
import SetPassword from './SetPassword';
import ResetPassword from './ResetPassword';
import Signup from './Signup';
import {
  Container,
  Banner,
  WhiteLogo,
  PawPrintTop,
  PawPrintBottom,
} from './styled';

const Auth = () => {
  const { user } = useAuth();

  if (user) {
    return <Redirect to='/' />;
  }

  return (
    <Container>
      <PawPrintTop icon='pawPrint' />
      <PawPrintBottom icon='pawPrint' />
      <Banner>
        <WhiteLogo icon='logo' />
      </Banner>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/setPassword/:token'>
        <SetPassword />
      </Route>
      <Route path='/resetPassword'>
        <ResetPassword />
      </Route>
      <Route path='/signup'>
        <Signup />
      </Route>
    </Container>
  );
};

export default Auth;
