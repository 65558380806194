import styled from 'styled-components';

import { Icon } from 'components/common';

export const FilterBar = styled.div`
  background: white;
  border-radius: 12px;
  padding: 15px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 15px 30px;
    h6 {
      font-size: 20px;
      font-weight: 700;
    }
  }
  .select {
    flex: 0 1 200px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const DogIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.secondary.four};
  padding: 11px;
  width: 20px;
  background: ${(p) => p.theme.colours.secondary.four}1A;
  border-radius: 50%;
  margin-right: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 28px;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  > * {
    margin-left: 15px;
  }
`;
