import { useEffect, useState } from 'react';

import BaseModal from './BaseModal';

const EditModal = ({ field, onSave, onDelete, ...rest }) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    if (field) {
      setForm({ ...field });
    }
  }, [field]);

  const handleChangeField = (key, value) => {
    setForm((s) => ({ ...s, [key]: value }));
  };

  return (
    <BaseModal
      {...rest}
      field={form}
      onChangeField={handleChangeField}
      title='Edit Foster Application Field'
      onSave={() => onSave(form)}
      onDelete={onDelete}
      allowDelete
    />
  );
};

export default EditModal;
