import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  ${(p) => p.padForMaxLength && 'padding-bottom: 15px'};
`;

export const StyledTextArea = styled.textarea`
  -webkit-appearance: none;
  background: #f3f5f9;
  resize: ${(p) => (p.resize ? 'vertical' : 'none')};
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colours.primary.main}00;
  border-radius: 16px;
  outline: none;
  font-size: 16px;
  line-height: 19px;
  padding: 13px 15px;
  color: ${(p) => p.theme.colours.primary.dark2};
  width: 100%;
  transition: 0.3s ease border;
  min-height: ${(p) => p.lines * 19 + 30}px;
  font-family: 'Nunito';
  :focus {
    border: 1px solid ${(p) => p.theme.colours.primary.main}FF;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px;
  }
`;

export const Label = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark2};
  margin-bottom: 10px;
`;

export const Counter = styled.div`
  position: absolute;
  right: 15px;
  bottom: -10px;
  font-size: 12px;
  color: ${(p) => p.theme.colours.primary.main};
`;

export const Required = styled.span`
  color: ${(p) => p.theme.colours.secondary.three};
  ${(p) =>
    p.text &&
    `
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 10px;
  `}
  ${(p) =>
    p.noMargin &&
    `
    margin-left: 0px;
  `}
`;
