import { useState, useEffect, useRef } from 'react';

import Checkbox from '../../Checkbox';

import { DesktopOptions, DesktopOption } from '../styled';

const Options = ({
  multi,
  value,
  onChange,
  options,
  filterable,
  maxHeight,
}) => {
  const [optionsTranslateY, setOptionsTranslateY] = useState(0);
  const optionsRef = useRef();

  useEffect(() => {
    if (optionsRef && !filterable) {
      const { top, height } = optionsRef.current.getBoundingClientRect();
      if (top + height > window.innerHeight) {
        setOptionsTranslateY(window.innerHeight - (top + height) - 15);
      }
    }
  }, [filterable]);

  const handleOptionClick = (option, e) => {
    if (multi) {
      e.stopPropagation();
      e.preventDefault();
      const currentValue = value || [];
      if (currentValue.includes(option.value)) {
        const without = currentValue.filter((v) => v !== option.value);
        return onChange([...without]);
      } else {
        return onChange([...currentValue, option.value]);
      }
    } else {
      onChange(option.value);
    }
  };

  return (
    <DesktopOptions
      ref={optionsRef}
      translateY={optionsTranslateY}
      maxHeight={maxHeight}
      className='select-options'
    >
      {options.map((o) => (
        <DesktopOption
          key={o.value}
          value={o.value}
          onClick={(e) => handleOptionClick(o, e)}
        >
          {multi && (
            <Checkbox
              onClick={(e) => handleOptionClick(o, e)}
              value={value?.includes(o.value)}
            />
          )}
          {o.expandedLabel || o.label}
        </DesktopOption>
      ))}
    </DesktopOptions>
  );
};

export default Options;
