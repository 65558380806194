import styled from 'styled-components';

import { colours } from 'utils/theme';

const alertColours = {
  error: colours.shade.three,
  info: colours.shade.two,
  success: colours.shade.four,
  warning: colours.shade.five,
};

const Container = styled.div`
  background: ${(p) => alertColours[p.type]};
  padding: 15px 30px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
`;

const Alert = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default Alert;
