const UploadLarge = (props) => {
  return (
    <svg viewBox='0 0 60 60' fill='none' {...props}>
      <g clipPath='url(#avatarLargeClip)'>
        <path
          d='M47.0998 17.5217C45.0139 7.38469 35.1051 0.858034 24.9681 2.94395C16.288 4.73015 10.0459 12.3527 10.0061 21.2145C10.0061 21.6367 10.0235 22.069 10.061 22.5263C3.88585 23.1799 -0.590265 28.7157 0.0633843 34.8909C0.669248 40.6146 5.49965 44.9566 11.2553 44.951H12.567C12.5371 44.5362 12.5046 44.124 12.5046 43.7016C12.5046 34.7322 19.7758 27.4609 28.7454 27.4609C37.7149 27.4609 44.986 34.7322 44.986 43.7016C44.986 44.1238 44.9535 44.5361 44.9236 44.951H46.2353C53.8165 44.9717 59.9792 38.8427 59.9999 31.2613C60.0198 23.9869 54.3612 17.96 47.0998 17.5217Z'
          fill='#E7FBFF'
        />
        <path
          d='M28.745 57.4442C36.3346 57.4442 42.4871 51.2916 42.4871 43.7021C42.4871 36.1125 36.3346 29.96 28.745 29.96C21.1555 29.96 15.0029 36.1125 15.0029 43.7021C15.0029 51.2916 21.1555 57.4442 28.745 57.4442Z'
          fill='#36DBFF'
        />
        <path
          d='M28.9038 49.0907C28.4815 49.0907 28.1392 48.7418 28.1392 48.3115V38.9609C28.1392 38.5305 28.4815 38.1816 28.9038 38.1816C29.3261 38.1816 29.6685 38.5305 29.6685 38.9609V48.3115C29.6685 48.7418 29.3261 49.0907 28.9038 49.0907Z'
          fill='#FAFAFA'
        />
        <path
          d='M31.9627 42.857C31.7597 42.8574 31.565 42.7756 31.4213 42.6295L28.904 40.0627L26.3868 42.6295C26.083 42.9285 25.5989 42.9199 25.3055 42.6104C25.0193 42.3084 25.0193 41.8296 25.3055 41.5277L28.3642 38.4108C28.6627 38.1066 29.1468 38.1066 29.4454 38.4108L32.5041 41.5277C32.8022 41.8324 32.8015 42.3258 32.5023 42.6296C32.3591 42.7752 32.1651 42.857 31.9627 42.857Z'
          fill='#FAFAFA'
        />
      </g>
      <defs>
        <clipPath id='avatarLargeClip'>
          <rect width={60} height={60} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadLarge;
