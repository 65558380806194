export const colours = {
  primary: {
    // main: '#4E2D69',
    main: '#063964',
    dark1: '#0C1B39',
    dark2: '#1F345D',
    dark3: '#4C5C7B',
    dark4: '#768197',
    dark5: '#8892A5',
  },
  secondary: {
    one: '#36DBFF',
    two: '#19BC90',
    three: '#FF4242',
    four: '#FFA800',
  },
  shade: {
    one: '#F9F1FF',
    two: '#E7FBFF',
    three: '#FFEDED',
    four: '#E2FEF7',
    five: '#FFF3DA',
    six: '#E8ECF3',
  },
  gradient: {
    one: 'linear-gradient(99.42deg, #FD4D81 0%, #FC6D71 100%)',
    two: 'linear-gradient(99.42deg, #3699FF 0%, #36CFFF 100%)',
    three: 'linear-gradient(99.42deg, #FFA800 0%, #FFD600 100%)',
  },
};

const breakpoints = {
  desktop: '@media only screen and (min-width: 1200px)',
  tablet: '@media only screen and (min-width: 800px)',
};

const theme = { colours, breakpoints };

export default theme;
