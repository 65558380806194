const Email = (props) => {
  return (
    <svg
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.9188 6.37598L11.2161 9.38684C10.5165 9.94184 9.5322 9.94184 8.83262 9.38684L5.09863 6.37598'
        stroke='#8892A5'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.0907 16.5C16.6251 16.507 18.3333 14.4246 18.3333 11.8653V6.14168C18.3333 3.58235 16.6251 1.5 14.0907 1.5H5.90924C3.37478 1.5 1.66663 3.58235 1.66663 6.14168V11.8653C1.66663 14.4246 3.37478 16.507 5.90924 16.5H14.0907Z'
        stroke='#8892A5'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Email;
