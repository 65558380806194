import styled from 'styled-components';

import { Icon } from 'components/common';

export const NavContainer = styled.div`
  background: white;
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-top: 25px;
    padding: 20px 25px;
  }
`;

export const OptionContainer = styled.div`
  pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
  cursor: pointer;
  user-select: none;
  margin-right: 25px;
  padding: 8px 0px;
  color: ${(p) =>
    p.active ? p.theme.colours.secondary.two : p.theme.colours.primary.dark4};
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  opacity: ${(p) => (p.disabled ? '0.5' : 1)};
  transition: 0.2s all ease;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-right: 20px;
    border-radius: 12px;
    padding: 8px 16px;
    border: 1px solid
      ${(p) =>
        p.active
          ? p.theme.colours.secondary.two
          : p.theme.colours.primary.dark4};
    background: ${(p) => (p.active ? p.theme.colours.secondary.two : 'white')};
    color: ${(p) => (p.active ? 'white' : p.theme.colours.primary.dark4)};
  }
`;

export const NavIcon = styled(Icon)`
  width: 14px;
  fill: ${(p) =>
    p.active ? p.theme.colours.secondary.two : p.theme.colours.primary.dark4};
  margin-right: 5px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 16px;
    fill: ${(p) => (p.active ? 'white' : p.theme.colours.primary.dark4)};
  }
`;

export const ComingSoon = styled.div`
  position: absolute;
  font-size: 9px;
  text-transform: uppercase;
  bottom: -5px;
  right: 0px;
  background: ${(p) => p.theme.colours.secondary.one};
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
  font-weight: 700;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
`;
