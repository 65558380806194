import { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { usePost, useAuth } from 'hooks';
import { resetPassword } from 'services/api';

import {
  FormContainer,
  Logo,
  H2,
  H5,
  Form,
  Button,
  Input,
  Alert,
} from './styled';

const ResetPassword = () => {
  const [form, setForm] = useState({ email: '' });
  const { user } = useAuth();
  const [{ res, loading, error }, postResetPassword] = usePost({
    url: resetPassword,
  });

  const handleChange = (key) => (value) =>
    setForm((s) => ({ ...s, [key]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    postResetPassword(form);
  };

  if (user) {
    return <Redirect to='/' />;
  }

  const disableSubmit = !form.email || res || error;

  return (
    <FormContainer>
      <Logo icon='logo' />
      <H2>Reset Password</H2>
      <H5>Enter account email</H5>
      <Form onSubmit={handleSubmit}>
        <Input
          disabled={loading}
          placeholder='Email'
          value={form.email}
          onChange={handleChange('email')}
        />
        <Button
          disabled={disableSubmit}
          fullWidth
          size='large'
          onClick={handleSubmit}
        >
          Reset Password
        </Button>
      </Form>
      {res && (
        <Alert type='info'>
          Thanks, if an account is found with the provided email address, we
          will send it an email containing password reset instructions
        </Alert>
      )}
      {error && (
        <Alert type='error'>
          Something went wrong, please try again later.
        </Alert>
      )}
    </FormContainer>
  );
};

export default ResetPassword;
