import styled from 'styled-components';
import { forwardRef } from 'react';

const HeaderContainer = styled.div`
  background: ${(p) => p.theme.colours.primary.main};
  color: white;
  padding: 30px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  align-items: center;
  h5 {
    font-size: 20px;
    font-weight: 700;
  }
  span {
    font-size: 14px;
    font-weight: 400;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px 0px 0px 0px;
  }
`;

const Avatar = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: white;
  margin-right: 20px;
`;

const Header = ({ title, avatar, subtitle }, ref) => {
  return (
    <HeaderContainer ref={ref}>
      {avatar && <Avatar />}
      <div>
        <h5>{title}</h5>
        <span>{subtitle}</span>
      </div>
    </HeaderContainer>
  );
};

export default forwardRef(Header);
