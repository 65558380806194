import styled from 'styled-components';

import {
  Modal,
  Button,
  Input,
  Select,
  Checkbox,
  ListInput,
} from 'components/common';

import { FormContainer, Row } from '../../shared';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${(p) => p.theme.colours.primary.dark1};
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  > button {
    margin-left: 15px;
  }
`;

const types = [
  { value: 'text', label: 'Text' },
  { value: 'multiText', label: 'Multiline Text' },
  { value: 'number', label: 'Number' },
  { value: 'select', label: 'Select' },
  { value: 'date', label: 'Date' },
  { value: 'checkbox', label: 'Checkbox' },
];

const BaseModal = ({
  show,
  onClose,
  title,
  field,
  onChangeField,
  onSave,
  allowDelete,
  onDelete,
}) => {
  const getFormProps = (key) => ({
    value: field[key],
    onChange: (value) => onChangeField(key, value),
  });

  return (
    <Modal show={show} onClose={onClose}>
      <Content>
        <h5>{title}</h5>
        <div style={{ height: '100%', overflow: 'auto' }}>
          <FormContainer>
            <Input label='Label' {...getFormProps('label')} />
            <Select label='Type' options={types} {...getFormProps('type')} />
            <Row>
              <Checkbox
                size='large'
                label='Required'
                {...getFormProps('required')}
              />
            </Row>
            {field.type === 'select' && (
              <Row style={{ flex: '1 1 100%' }}>
                <ListInput
                  inputProps={[{ label: 'Options' }]}
                  {...getFormProps('options')}
                />
              </Row>
            )}
          </FormContainer>
        </div>
        <Actions>
          {allowDelete && (
            <Button type='secondary' onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button type='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onSave}>Save</Button>
        </Actions>
      </Content>
    </Modal>
  );
};

export default BaseModal;
