const Menu = (props) => {
  return (
    <svg viewBox='0 0 24 25' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M4.00024 6.02219L20.0002 6.02219M4.00024 18.0664L20.0002 18.0664L4.00024 18.0664ZM4.00024 12.0443L12.0002 12.0443L4.00024 12.0443Z'
        stroke='#063964'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Menu;
