import { useGet } from 'hooks';
import { charities } from 'services/api';
import Select from '../Select';

const CharitySelect = ({ label = 'Charity', ...rest }) => {
  const [{ res }] = useGet({
    url: charities,
    query: { asList: true },
    onMount: true,
  });

  return <Select label={label} options={res} {...rest} />;
};

export default CharitySelect;
