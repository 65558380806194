const Thumb = (props) => {
  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M9.10453 5.69315C9.61859 5.69315 10.1284 5.72281 10.6299 5.68891C11.6032 5.62112 12.4459 6.43463 12.2099 7.58288C11.9445 8.86248 11.7338 10.1548 11.4979 11.4429C11.3588 12.2055 10.7984 12.6843 10.0315 12.6843C8.63682 12.6886 7.2379 12.6886 5.8432 12.6843C4.99206 12.6843 4.37687 12.0615 4.37687 11.1971C4.37266 9.70142 4.37687 8.20573 4.37687 6.71004C4.37687 6.38379 4.40637 6.34142 4.71396 6.24396C5.32914 6.0533 5.87691 5.76094 6.28985 5.24825C6.648 4.79489 6.81655 4.26525 6.85868 3.68901C6.87554 3.43478 6.87554 3.18056 6.8966 2.92634C6.94717 2.30349 7.28004 2.07892 7.8868 2.23569C8.51884 2.40094 8.84329 2.87125 9.04554 3.44326C9.27307 4.08306 9.26886 4.74404 9.15931 5.40502C9.14245 5.49824 9.1256 5.58722 9.10453 5.69315Z' />
      <path d='M3.5 9.18963C3.5 9.9528 3.5 10.716 3.5 11.4791C3.5 12.1745 3.12454 12.6875 2.62182 12.6875C2.12864 12.679 1.75 12.1618 1.75 11.4834C1.75 9.9528 1.75 8.41798 1.75 6.8874C1.75 6.20479 2.13818 5.68329 2.63773 5.68753C3.12136 5.69177 3.5 6.22175 3.5 6.90012C3.5 7.66329 3.5 8.42646 3.5 9.18963Z' />
    </svg>
  );
};

export default Thumb;
