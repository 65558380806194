const Message = (props) => {
  return (
    <svg viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.19734 7.55707C6.84711 7.55707 6.49794 7.44138 6.20582 7.20999L3.8579 5.317C3.68881 5.18089 3.66264 4.93327 3.79822 4.7647C3.93486 4.59666 4.18195 4.56996 4.35052 4.70554L6.69634 6.59644C6.99108 6.82992 7.40622 6.82992 7.70304 6.59435L10.0253 4.70659C10.1939 4.56891 10.441 4.59508 10.5781 4.76365C10.7148 4.9317 10.6891 5.17879 10.5211 5.31595L8.19461 7.20685C7.9004 7.44033 7.54861 7.55707 7.19734 7.55707Z'
      />
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='1'
        width='12'
        height='11'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.55554 1.55566H12.8108V11.764H1.55554V1.55566Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.70902 10.8112H9.66326C9.66427 10.8102 9.6683 10.8112 9.67133 10.8112C10.247 10.8112 10.7575 10.6064 11.149 10.2173C11.6036 9.76702 11.8533 9.11991 11.8533 8.3955V4.94762C11.8533 3.54548 10.9321 2.52789 9.66326 2.52789H4.71003C3.4412 2.52789 2.51998 3.54548 2.51998 4.94762V8.3955C2.51998 9.11991 2.77021 9.76702 3.22427 10.2173C3.61576 10.6064 4.12683 10.8112 4.70196 10.8112H4.70902ZM4.60339 11.764C3.79562 11.764 3.07423 11.4708 2.51722 10.9159C1.89687 10.2971 1.55554 9.42498 1.55554 8.45964V4.86421C1.55554 2.97803 2.87006 1.55566 4.61333 1.55566H9.7531C11.4964 1.55566 12.8109 2.97803 12.8109 4.86421V8.45964C12.8109 9.42498 12.4696 10.2971 11.8492 10.9159C11.2927 11.4703 10.5708 11.764 9.76147 11.764H9.7531H4.61333H4.60339Z'
        />
      </g>
    </svg>
  );
};

export default Message;
