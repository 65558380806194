import styled from 'styled-components';

import { Alert as CommonAlert } from 'components/common';

export const Container = styled.div`
  overflow: auto;
`;

export const SummaryContainer = styled.div`
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.02);
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  box-sizing: content-box;
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Field = styled.div`
  margin-right: 15px;
  color: ${(p) => p.theme.colours.primary.dark3};
  font-size: 13px;
  font-weight: 400;
  margin-top: 4px;
  div:first-child {
    font-weight: 600;
    font-size: 14px;
    color: ${(p) => p.theme.colours.primary.dark2};
  }
`;

export const Actions = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 15px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Status = styled(CommonAlert)`
  padding: 2px 10px;
  border-radius: 9px;
  margin-right: 5px;
  font-size: 12px;
`;

export const ManualContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const ManualForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  > div {
    margin-bottom: 10px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StatusContainer = styled.div`
  margin-top: 10px;
  display: flex;
`;
