import { Container, Row } from './styled';

const DefaultCell = ({ children }) => {
  return children || '-';
};

const Table = ({ data, columns, onRowClick }) => {
  if (!data) return null;
  return (
    <Container columns={columns.length}>
      {columns.map((c, i) => (
        <div className='header' key={`table-header-${i}`}>
          {c.label}
        </div>
      ))}
      {data.map((d, i) => (
        <Row key={`table-row-${i}`} onClick={() => onRowClick(d)}>
          {columns.map((c, j) => {
            const Cell = c.cell || DefaultCell;
            return (
              <div className='cell' key={`table-row-${i}-cell-${j}`}>
                {<Cell {...d}>{d[c.key]}</Cell>}
                <div className='border' />
              </div>
            );
          })}
        </Row>
      ))}
    </Container>
  );
};

Table.defaultProps = {
  columns: [],
  onRowClick: () => {},
};

export default Table;
