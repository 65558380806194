import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Icon, NoScroll, Input as CommonInput } from 'components/common';
import { useWidth } from 'hooks';

import {
  Content,
  Image,
  ResultDetails,
  Highlights,
  Path,
  Highlight,
  Bar,
} from '../Results';

const ANIMATION_TIME = '0.3s';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background: white;
  z-index: 10;
  padding: 30px 25px;
  box-sizing: border-box;
  animation-duration: ${ANIMATION_TIME};
  animation-fill-mode: forwards;
  animation-name: ${(p) => p.transition};
  display: flex;
  flex-direction: column;

  @keyframes search-container-entry {
    from {
      left: -100vw;
    }

    to {
      left: 0vw;
    }
  }

  @keyframes search-container-exit {
    from {
      left: 0vw;
    }

    to {
      left: -100vw;
    }
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Close = styled(Icon)`
  width: 15px;
  fill: ${(p) => p.theme.colours.primary.dark1};
`;

const Input = styled(CommonInput)`
  margin-top: 25px;
  input {
    border-radius: 25px;
  }
`;

const ResultsContainer = styled.div`
  overflow: auto;
  padding-bottom: 100px;
`;

const Results = ({ results, loading, onClose }) => {
  const { push } = useHistory();

  const handleClick = (id) => {
    onClose();
    push(`/pet/${id}`);
  };

  if (loading)
    return (
      <>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
        </Content>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
          <Bar className='shimmer' />
        </Content>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
        </Content>
      </>
    );

  if (results?.length === 0) {
    return <div>No matching pets</div>;
  }

  return results?.map((result) => (
    <Content onClick={() => handleClick(result._id)} key={result._id}>
      <Image>
        {result.thumbnail?.url && (
          <img src={result.thumbnail?.url} alt={result.name} />
        )}
      </Image>
      <ResultDetails>
        <h5>{result.name}</h5>
        {result.highlights[0].path !== 'name' && (
          <Highlights>
            <Path>
              {result.highlights[0].path
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, function (str) {
                  return str.toUpperCase();
                })}
              :{' '}
            </Path>
            {result.highlights[0].texts.map((text) => (
              <Highlight hit={text.type === 'hit'} key={text.value}>
                {text.value}
              </Highlight>
            ))}
          </Highlights>
        )}
      </ResultDetails>
    </Content>
  ));
};

const Search = ({ shouldShow, onClose, value, onChange, results, loading }) => {
  const [show, setShow] = useState(shouldShow);
  const [transition, setTransition] = useState();
  const { isDesktop } = useWidth();
  const inputRef = useRef();

  useEffect(() => {
    let timeout;
    if (shouldShow) {
      setShow(true);
      setTransition('entry');
    } else {
      setTransition('exit');
      timeout = setTimeout(() => setShow(false), 300);
    }

    return () => clearTimeout(timeout);
  }, [shouldShow]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  if (!show || isDesktop) return null;

  return (
    <Container transition={`search-container-${transition}`}>
      <NoScroll />
      <Title>
        <h4>Search</h4>
        <Close icon='close' onClick={onClose} />
      </Title>
      <Input
        icon='search'
        placeholder='Search'
        value={value}
        onChange={onChange}
        ref={inputRef}
      />
      <ResultsContainer>
        {value && (
          <Results results={results} loading={loading} onClose={onClose} />
        )}
      </ResultsContainer>
    </Container>
  );
};

export default Search;
