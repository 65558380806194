import styled from 'styled-components';
import {
  useRef,
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

const NotificationContainer = styled.div`
  @keyframes notification-entry {
    0% {
      opacity: 0;
      transform: translateY(150%);
    }
    10% {
      opacity: 1;
      transform: translateY(0%);
    }
    90% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(150%);
    }
  }

  transform: translateY(500%);
  animation: notification-entry 3s 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;

  height: ${(p) => p.height}px;
  margin: 0px 0px 15px 15px;
  transition: height 0.3s ease, margin-top 0.3s ease, border 0.3s ease,
    padding 0.3s ease;
  overflow: hidden;
  background: ${(p) => p.theme.colours.shade.two};
  padding: ${(p) => (p.height ? '15px 30px' : '0px')};
  border-radius: 15px;
  border: ${(p) => (p.height ? '1px ' : '0px ')} solid
    ${(p) => p.theme.colours.secondary.two};
  filter: drop-shadow(3px 10px 30px rgba(0, 0, 0, 0.16));
  pointer-events: auto;
`;

const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;
`;

const Notification = ({ children, offset }) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.scrollHeight);
    }
  }, []);

  setTimeout(() => setHeight(0), 3300);

  return (
    <NotificationContainer
      ref={ref}
      height={height}
      bottom={height * offset * 3}
    >
      {children}
    </NotificationContainer>
  );
};

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const value = useGenerateNotification();

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <NotificationsContainer>{value.notifications}</NotificationsContainer>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);

let counter = 0;

const useGenerateNotification = () => {
  const notifications = useRef([]);
  const [, forceRender] = useState();

  const remove = (_id) => {
    notifications.current = notifications.current.filter(
      (x) => x.props.id !== _id
    );
    forceRender(notifications.current);
  };

  const notify = useCallback((content) => {
    counter++;
    const id = counter;
    const notification = (
      <Notification id={id} key={id} offset={notifications.current.length + 1}>
        {content}
      </Notification>
    );
    notifications.current = [...notifications.current, notification];
    forceRender(notifications.current);
    setTimeout(() => remove(id), 4000);
  }, []);

  return {
    notifications: notifications.current,
    notify,
  };
};
