import { useState } from 'react';

import Modal from './Modal';
import { Detail } from '../../shared';

const Automations = ({ settings, loading, onSave }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveSuccess = () => {
    setShowModal(false);
    onSave();
  };

  const { automations } = settings || {};

  return (
    <>
      <Detail
        onClick={handleShowModal}
        title='Update pet status after application limit'
        subtitle={
          automations?.changeStatusApplicationLimit?.isEnabled
            ? 'Enabled'
            : 'Disabled'
        }
        loading={loading}
      />
      <Modal
        onClose={handleCloseModal}
        onSaveSuccess={handleSaveSuccess}
        show={showModal}
        settings={settings}
      />
    </>
  );
};

export default Automations;
