import styled from 'styled-components';

import { useAuth } from 'hooks';
import { Alert, Button, Input, Select } from 'components/common';
import { useEffect, useState } from 'react';
import { useGet } from 'hooks';
import { publicOptions } from 'services/api';

const Container = styled.div`
  h5 {
    margin-top: 15px;
  }
`;

const Code = styled.pre`
  background: #e4ebee;
  padding: 15px;
  margin-top: 15px;
  white-space: pre-line;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const FiltersContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  > div {
    flex: 1;
    margin-right: 5px;
  }
  > button {
    align-self: flex-end;
    margin-bottom: 5px;
  }
`;

const SortingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  > div {
    flex: 1;
    margin-right: 5px;
  }
`;

const filterKeys = {
  status: 'Status',
  species: 'Species',
  location: 'Location',
};

const sortDirOptions = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];

const sortFieldOptions = [
  { label: 'First Made Available', value: 'firstMarkedAvailable' },
  { label: 'Name', value: 'name' },
];

const Filters = ({ filters, onChange, options }) => {
  const getFieldOptions = () => {
    return Object.entries(filterKeys).reduce((prev, [key, value]) => {
      return [...prev, { label: value, value: key }];
    }, []);
  };

  const handleChangeField = (index) => (field) => {
    const next = [...filters];
    if (next[index]) {
      next[index].field = field;
      next[index].value = null;
    } else {
      next[index] = { field };
    }

    onChange(next);
  };

  const handleChangeValue = (index) => (value) => {
    const next = [...filters];
    if (next[index]) {
      next[index].value = value;
    } else {
      next[index] = { value };
    }

    onChange(next);
  };

  const handleRemove = (index) => () => {
    const next = [...filters];
    next.splice(index, 1);
    onChange(next);
  };

  return filters.map((filter, i) => (
    <FiltersContainer key={i}>
      <Select
        label={i === 0 && 'Field'}
        options={getFieldOptions()}
        value={filter.field}
        onChange={handleChangeField(i)}
      />
      {filter.field === 'location' ? (
        <Input
          label={i === 0 && 'Value'}
          value={filter.value}
          onChange={handleChangeValue(i)}
        />
      ) : (
        <Select
          label={i === 0 && 'Value'}
          options={
            filter.field === 'status'
              ? options.embedStatus
              : options[filter.field]
          }
          value={filter.value}
          onChange={handleChangeValue(i)}
          multi
        />
      )}
      <Button onClick={handleRemove(i)}>Remove</Button>
    </FiltersContainer>
  ));
};

const Sorting = ({ sort, onChange }) => {
  const handleChangeField = (field) => {
    onChange((s) => ({ ...s, field }));
  };

  const handleChangeDir = (dir) => {
    onChange((s) => ({ ...s, dir }));
  };

  return (
    <>
      <SortingContainer>
        <Select
          label='Sort By'
          options={sortFieldOptions}
          onChange={handleChangeField}
          value={sort.field}
        />
        <Select
          options={sortDirOptions}
          onChange={handleChangeDir}
          value={sort.dir}
        />
      </SortingContainer>
    </>
  );
};

const generateQueryString = (filters, sort) => {
  const filtersAsParams = new URLSearchParams();

  if (filters && filters.length) {
    filters.forEach((filter) => {
      if (!filter.value || !filter.value.length) return;
      if (Array.isArray(filter.value)) {
        filter.value.forEach((v) =>
          filtersAsParams.append(`${filter.field}[]`, v.toString())
        );
      } else {
        filtersAsParams.set(filter.field, filter.value.toString());
      }
    });
  }

  if (sort.field !== 'firstMarkedAvailable') {
    filtersAsParams.set('sort', sort.field);
  }
  if (sort.dir !== 'desc') {
    filtersAsParams.set('sortDir', sort.dir);
  }

  const asString = filtersAsParams.toString();
  return !asString.length ? '' : '?' + asString;
};

const Integrations = () => {
  const { user } = useAuth();
  const [copyText, setCopyText] = useState('Copy to clipboard');
  const [filters, setFilters] = useState();
  const [sort, setSort] = useState({
    field: 'firstMarkedAvailable',
    dir: 'desc',
  });

  const [{ res: options, loading }] = useGet({
    url: publicOptions,
    onMount: true,
  });

  useEffect(() => {
    let timeout;
    if (copyText !== 'Copy to clipboard') {
      timeout = setTimeout(() => setCopyText('Copy to clipboard'), 2000);
    }
    return () => window.clearTimeout(timeout);
  }, [copyText]);

  const snippet = `<section id="pam-available-pets"></section>
<script src="https://www.pawrescuemanager.com/embed/${
    user.embedKey
  }${generateQueryString(filters, sort)}"></script>`;

  const handleCopy = async () => {
    await navigator.clipboard.writeText(snippet);
    setCopyText('Copied!');
  };

  const handleAdd = () => {
    setFilters((s) => (s ? [...s, {}] : [{}]));
  };

  return (
    <Container>
      <p>
        To embed your available animals onto any page on your website, simply
        copy and paste the code snippet below into the HTML code for your
        website.
        <br />
        <br />
        You can customise what animals are shown by adding filters and sorting
        options to the embed code using the controls below.
      </p>
      <Alert type='info' style={{ marginTop: 15 }}>
        By default, your embed code will show 'Available to adopt', 'Needs a
        foster', 'Pending', and 'Reserved' animals, and will sort by the date
        they were first marked as available on the platform.
      </Alert>
      <h5>Filters</h5>
      {loading || !filters?.length ? (
        <div style={{ padding: '10px 0px' }}>No filters applied</div>
      ) : (
        <Filters filters={filters} onChange={setFilters} options={options} />
      )}
      <Button onClick={handleAdd} disabled={loading}>
        Add Filter
      </Button>
      <h5>Sorting</h5>
      <Sorting sort={sort} onChange={setSort} />
      <Code>{snippet}</Code>
      <Actions>
        <Button onClick={handleCopy}>{copyText}</Button>
      </Actions>
    </Container>
  );
};

export default Integrations;
