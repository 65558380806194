import { forwardRef } from 'react';

import Prefix from './Prefix';
import { Container, Icon, StyledInput, Label, Suffix, Counter } from './styled';
import { Required } from '../styled';
import DateInput from './Date';

const components = {
  date: DateInput,
};

const Input = forwardRef(
  (
    {
      icon,
      iconPlacement,
      className,
      label,
      required,
      type,
      prefix,
      suffix,
      onChange,
      value,
      style,
      prefixOptions,
      onPrefixChange,
      disabled,
      validate,
      maxLength,
      ...rest
    },
    ref
  ) => {
    const handleChange = (e) => {
      onChange(e.target.value);
    };

    let _icon = icon;
    let _iconPlacement = iconPlacement;
    let _value = value || '';

    const pad = { left: false, right: false };
    if (_icon && _iconPlacement === 'start') {
      pad.left = true;
    }
    if (_icon && _iconPlacement === 'end') {
      pad.right = true;
    }
    if (prefix) {
      pad.left = 'prefix';
    }
    if (suffix) {
      pad.right = 'suffix';
    }

    const InputComponent = components[type] || StyledInput;

    return (
      <div className='input' style={{ opacity: disabled ? 0.5 : 1, ...style }}>
        {label && (
          <Label>
            {required && <Required>*</Required>}
            {label}
            {!value && required && <Required text>Required</Required>}
            {value && validate && !validate(value) && (
              <Required text>
                Please enter a valid {label.toLowerCase()}
              </Required>
            )}
          </Label>
        )}
        <Container className={className}>
          {maxLength > 0 && (
            <Counter>{maxLength - _value.length} characters left</Counter>
          )}
          {prefix && (
            <Prefix
              prefix={prefix}
              prefixOptions={prefixOptions}
              onChange={onPrefixChange}
            />
          )}
          {_icon && <Icon icon={_icon} iconPlacement={_iconPlacement} />}
          <InputComponent
            type={type}
            pad={pad}
            onChange={handleChange}
            value={_value}
            disabled={disabled}
            maxLength={maxLength}
            ref={ref}
            {...rest}
          />
          {suffix && <Suffix>{suffix}</Suffix>}
        </Container>
      </div>
    );
  }
);

Input.defaultProps = {
  iconPlacement: 'start',
  onChange: () => {},
  onPrefixChange: () => {},
  value: '',
};

export default Input;
