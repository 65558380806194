import styled from 'styled-components';

const numberOfPages = 5;
const itemsPerPage = 24;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

const Button = styled.div`
  border-top: 1px solid ${(p) => p.theme.colours.primary.border};
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.border};
  border-right: 1px solid ${(p) => p.theme.colours.primary.border};
  font-size: 14px;
  color: ${(p) => (p.isActive ? 'white' : p.theme.colours.primary.dark4)};
  background: ${(p) => (p.isActive ? p.theme.colours.primary.main : 'white')};
  padding: 8px 14px;
  cursor: pointer;
  user-select: none;
  :first-child {
    border-radius: 16px 0px 0px 16px;
    border-left: 1px solid ${(p) => p.theme.colours.primary.border};
  }
  :last-child {
    border-radius: 0px 16px 16px 0px;
  }
`;

const generatePageNumber = (index, currentPage, maxPages, numberOfPages) => {
  const middleIndex =
    numberOfPages % 2 === 0
      ? Math.floor(numberOfPages / 2) - 1
      : Math.floor(numberOfPages / 2);
  const adjustment = Array.from(Array(numberOfPages)).map((_, index) => {
    if (currentPage <= middleIndex) {
      return index;
    }
    if (currentPage + middleIndex >= maxPages - 1) {
      return index - numberOfPages + (maxPages - currentPage);
    }
    return index - middleIndex;
  });

  if (currentPage <= middleIndex) {
    return adjustment[index];
  }

  const label = currentPage + adjustment[index];
  return label;
};

const Pagination = ({ totalResults, page, onChangePage, loading, style }) => {
  const maxPages = Math.ceil(totalResults / itemsPerPage);
  const _numberOfPages = maxPages < numberOfPages ? maxPages : numberOfPages;

  const handlePrev = () => {
    if (page) {
      onChangePage(page - 1);
    }
  };

  const handleNext = () => {
    if (page + 1 === maxPages) return;
    onChangePage(page + 1);
  };

  if (!totalResults || loading || _numberOfPages === 0) return null;

  return (
    <Container style={style}>
      <Button onClick={handlePrev}>Prev</Button>
      {Array.from(Array(_numberOfPages)).map((_, index) => {
        const pageNumber = generatePageNumber(
          index,
          page,
          maxPages,
          _numberOfPages
        );
        return (
          <Button
            isActive={page === pageNumber}
            key={index}
            onClick={() => onChangePage(pageNumber)}
          >
            {pageNumber + 1}
          </Button>
        );
      })}
      <Button onClick={handleNext}>Next</Button>
    </Container>
  );
};

export default Pagination;
