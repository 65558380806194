import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';

const Container = styled.div`
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  background: white;
  position: absolute;
  left: 30px;
  top: 75px;
  border-radius: 10px;
  width: 665px;
  z-index: 2;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'normal')};
  padding: 8px 15px;
  :last-child {
    margin-bottom: 0px;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    :hover {
      background: ${(p) => (p.onClick ? '#f3f5f9' : 'white')};
    }
  }
`;
export const Image = styled.div`
  width: 50px;
  flex: 0 0 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  div {
    width: 100%;
    height: 100%;
  }
`;
export const ResultDetails = styled.div``;
export const Highlights = styled.div`
  font-size: 11px;
`;
export const Path = styled.span`
  font-weight: 700;
`;
export const Highlight = styled.span`
  font-weight: ${(p) => (p.hit ? 700 : 500)};
`;

export const Bar = styled.div`
  height: 10px;
  width: 100px;
`;

const Results = ({ results, loading, onClose }) => {
  const { push } = useHistory();
  const ref = useRef();

  const handleClick = (id) => {
    onClose();
    push(`/pet/${id}`);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) {
          onClose();
        }
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [onClose]);

  if (loading)
    return (
      <Container ref={ref}>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
        </Content>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
          <Bar className='shimmer' />
        </Content>
        <Content>
          <Image>
            <div className='shimmer' />
          </Image>
          <Bar className='shimmer' />
        </Content>
      </Container>
    );

  if (results.length === 0) {
    return (
      <Container ref={ref} style={{ padding: 15 }}>
        No matching pets
      </Container>
    );
  }

  return (
    <Container ref={ref}>
      {results?.map((result) => (
        <Content onClick={() => handleClick(result._id)} key={result._id}>
          <Image>
            {result.thumbnail?.url && (
              <img src={result.thumbnail?.url} alt={result.name} />
            )}
          </Image>
          <ResultDetails>
            <h5>{result.name}</h5>
            {result.highlights[0].path !== 'name' && (
              <Highlights>
                <Path>
                  {result.highlights[0].path
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                  :{' '}
                </Path>
                {result.highlights[0].texts.map((text) => (
                  <Highlight hit={text.type === 'hit'} key={text.value}>
                    {text.value}
                  </Highlight>
                ))}
              </Highlights>
            )}
          </ResultDetails>
        </Content>
      ))}
    </Container>
  );
};

export default Results;
