import { createGlobalStyle } from 'styled-components';

const NoScroll = createGlobalStyle`
  body {
    overflow: hidden;
    position: relative;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    body {
      overflow: auto;
    }
  }
`;

export default NoScroll;
