export const login = '/api/login';
export const setPassword = '/api/setPassword';
export const resetPassword = '/api/resetPassword';
export const charity = '/api/charity';
export const charities = '/api/charities';
export const user = '/api/user';
export const users = '/api/users';
export const listPets = '/api/pets';
export const pet = `/api/pet`;
export const petImages = '/api/pet/images';
export const petDeleteImage = `/api/pet/images/delete`;
export const petPrimaryImage = '/api/pet/primaryImage';
export const petThumbnail = '/api/pet/thumbnail';
export const questions = '/api/questions';
export const question = '/api/question';
export const fostererFeedback = '/api/pet/feedback';
export const sharePet = '/api/share';
export const petOptions = '/api/options/pet';
export const publicOptions = '/api/web/options/pet';
export const charitySettings = '/api/settings/charity';
export const userSettings = '/api/settings/user';
export const resendVerification = '/api/user/resendVerification';
export const signup = '/api/web/signup';
export const application = '/api/application';
export const selectApplication = '/api/selectApplication';
export const selectManualApplication = '/api/selectManualApplication';
export const searchDashboard = '/api/dashboard/search';
export const allApplications = '/api/applications';
