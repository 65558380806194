import { ContextMenu } from 'components/common';
import { format } from 'utils/date';

import {
  Container,
  Actions,
  ControlsContainer,
  Action,
  Details,
  DetailContainer,
} from './styled';

const Detail = ({ label, value }) => {
  return (
    <DetailContainer>
      <span>{label}</span>
      <span>{value}</span>
    </DetailContainer>
  );
};

const Controls = () => {
  return (
    <ControlsContainer>
      <Action>Update</Action>
      <Action>Delete</Action>
    </ControlsContainer>
  );
};

const MobileCard = ({ onClick, charity }) => {
  return (
    <Container onClick={() => onClick(charity)}>
      <Actions>
        <ContextMenu actions={<Controls />} orientation='horizontal' />
      </Actions>
      <Details>
        <Detail label='Name' value={charity.name} />
        <Detail label='Date Added' value={format(charity.createdAt)} />
        <Detail
          label='Last Login'
          value={charity.lastLogin ? format(charity.lastLogin) : '-'}
        />
        <Detail label='Users' value={charity.userCount} />
        <Detail label='Pets' value={charity.petCount} />
      </Details>
    </Container>
  );
};
export default MobileCard;
