import { Select, Slider, TextArea, Input } from 'components/common';

import { Row, SectionTitle } from './styled';

const Personality = ({ options, getFormProps, details }) => {
  const descLabel =
    details.status === 'Needs reclaimed' || details.status === 'Reclaimed'
      ? 'Reclaim Information'
      : 'Animal Personality Summary';
  return (
    <>
      <SectionTitle>Personality</SectionTitle>
      <Row>
        <Input
          label='Social Media Summary'
          maxLength={160}
          {...getFormProps('socialBlurb')}
        />
      </Row>
      <Row>
        <TextArea
          resize
          lines={8}
          label={descLabel}
          {...getFormProps('summary')}
        />
      </Row>
      <Row>
        <Select
          label='House Trained'
          options={options.houseTrained}
          {...getFormProps('houseTrained')}
        />
        <Select
          label='Favourite Things'
          options={options.favThings}
          {...getFormProps('favThings')}
          multi
        />
      </Row>
      <Row>
        <Slider
          labels={['Chilled', 'Hyper']}
          {...getFormProps('chillRating')}
        />
        <Slider
          labels={['Cuddler', 'Independent']}
          {...getFormProps('cuddleRating')}
        />
      </Row>
    </>
  );
};

export default Personality;
