import styled from 'styled-components';
import { useState, useEffect } from 'react';

import EditModal from './EditModal';
import AddModal from './AddModal';
import { Detail } from '../../shared';

import { usePost } from 'hooks';

import { charitySettings } from 'services/api';

const Container = styled.div`
  max-height: 380px;
  overflow: auto;
`;

const Required = styled.div`
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  background: ${(p) => p.theme.colours.shade.two};
  color: ${(p) => p.theme.colours.secondary.one};
  padding: 2px 4px;
  margin-left: 10px;
  border-radius: 5px;
`;

const Mandatory = styled.div`
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  background: ${(p) => p.theme.colours.shade.five};
  color: ${(p) => p.theme.colours.secondary.four};
  padding: 2px 4px;
  margin-left: 10px;
  border-radius: 5px;
`;

const typeLabels = {
  text: 'Text',
  multiText: 'Multiline Text',
  number: 'Number',
  select: 'Select',
  date: 'Date',
  checkbox: 'Checkbox',
};

const Subtitle = ({ value, required, mandatory }) => {
  return (
    <>
      {typeLabels[value]}
      {required && <Required>Required</Required>}
      {mandatory && <Mandatory>Mandatory</Mandatory>}
    </>
  );
};

const Fields = ({
  showAddModal,
  onCloseAddModal,
  settings,
  loading,
  onSave,
}) => {
  const [fieldToEdit, setFieldToEdit] = useState(null);

  const [{ res: saveRes }, saveSettings, setSaveRes] = usePost({
    url: charitySettings,
  });

  useEffect(() => {
    if (saveRes) {
      onCloseAddModal();
      setFieldToEdit();
      onSave();
      setSaveRes();
    }
  }, [saveRes, onSave, onCloseAddModal, setSaveRes]);

  const handleCloseEdit = () => {
    setFieldToEdit(null);
  };

  const handleCloseAdd = () => {
    onCloseAddModal();
  };

  const handleFieldClick = (field, index) => {
    setFieldToEdit({ field, index });
  };

  const handleEdit = (field) => {
    const payload = [...settings.fosterApplicationFields];
    payload[fieldToEdit.index] = { ...field };
    saveSettings({ ...settings, fosterApplicationFields: payload });
  };

  const handleDelete = () => {
    const payload = [...settings.fosterApplicationFields];
    payload.splice(fieldToEdit.index, 1);
    saveSettings({ ...settings, fosterApplicationFields: payload });
  };

  const handleAdd = (field) => {
    const payload = [...settings.fosterApplicationFields, field];
    saveSettings({ ...settings, fosterApplicationFields: payload });
  };

  return (
    <Container>
      {settings?.fosterApplicationFields.map((f, i) => (
        <Detail
          mandatory={f.mandatory}
          key={i}
          onClick={() => !f.mandatory && handleFieldClick(f, i)}
          title={f.label}
          subtitle={
            <Subtitle
              value={f.type}
              required={f.required}
              mandatory={f.mandatory}
            />
          }
        />
      ))}
      <EditModal
        show={fieldToEdit}
        onClose={handleCloseEdit}
        field={fieldToEdit?.field}
        onSave={handleEdit}
        onDelete={handleDelete}
      />
      <AddModal
        onSave={handleAdd}
        show={showAddModal}
        onClose={handleCloseAdd}
      />
    </Container>
  );
};

export default Fields;
