import styled from 'styled-components';

export const FormContainer = styled.div`
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  .input,
  .select,
  .checkbox {
    margin-top: 15px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  padding-bottom: 15px;
  padding-top: 30px;
  :first-child {
    padding-top: 10px;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
    > * {
      flex: 1;
      margin-left: 20px;
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;

export const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;
  > button {
    margin-left: 20px;
  }
`;
