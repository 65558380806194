import { Page } from 'components/common';
import { useAuth } from 'hooks';

import CharitySettings from './Charity';
import UserSettings from './User';

const Settings = () => {
  const { user } = useAuth();

  return (
    <Page>
      {user.isCharityAdmin && !user.isPawAdmin && <CharitySettings />}
      <UserSettings />
    </Page>
  );
};

export default Settings;
