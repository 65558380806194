import { useEffect } from 'react';
import { useParams } from 'react-router';

import { usePost, useNotification } from 'hooks';
import { petPrimaryImage, petDeleteImage } from 'services/api';

import Actions from './Actions';
import { ImageContainer, ContextMenu } from './styled';

const SavedImage = ({ image, onFetch }) => {
  const { id } = useParams();
  const { notify } = useNotification();
  const [{ res: deleteSuccess }, deleteImage] = usePost({
    url: petDeleteImage,
  });
  const [{ res: primarySucess }, setPrimaryImage] = usePost({
    url: petPrimaryImage,
  });

  useEffect(() => {
    if (deleteSuccess) {
      onFetch();
      notify('Successfully deleted image');
    }
  }, [deleteSuccess, onFetch, notify]);

  useEffect(() => {
    if (primarySucess) {
      onFetch();
      notify('Successfully set primary image');
    }
  }, [primarySucess, onFetch, notify]);

  const handleDelete = (imageId) => {
    deleteImage({ imageId, id });
  };

  const handleSetPrimary = (imageId) => {
    setPrimaryImage({ imageId, id });
  };

  return (
    <ImageContainer>
      <ContextMenu
        actions={
          <Actions
            id={image._id}
            onDelete={handleDelete}
            onSetPrimary={handleSetPrimary}
          />
        }
      />
      <img src={image.url} alt='Pet' />
    </ImageContainer>
  );
};

SavedImage.defaultProps = {
  onFetch: () => {},
};

export default SavedImage;
