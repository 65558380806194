const ThreeDots = (props) => {
  return (
    <svg
      viewBox='0 0 4 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0.939339 0.939339C0.353554 1.52512 0.353554 2.47488 0.939339 3.06066C1.52512 3.64645 2.47488 3.64645 3.06066 3.06066C3.64645 2.47488 3.64645 1.52512 3.06066 0.939339C2.4749 0.353554 1.52515 0.353554 0.939339 0.939339Z' />
      <path d='M0.939339 6.43934C0.353554 7.02512 0.353554 7.97488 0.939339 8.56066C1.52512 9.14645 2.47488 9.14645 3.06066 8.56066C3.64645 7.97488 3.64645 7.02512 3.06066 6.43934C2.4749 5.85355 1.52515 5.85355 0.939339 6.43934Z' />
      <path d='M0.939339 11.9393C0.353554 12.5251 0.353554 13.4749 0.939339 14.0607C1.52512 14.6464 2.47488 14.6464 3.06066 14.0607C3.64645 13.4749 3.64645 12.5251 3.06066 11.9393C2.4749 11.3536 1.52515 11.3536 0.939339 11.9393Z' />
    </svg>
  );
};

export default ThreeDots;
