import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { usePost, useNotification } from 'hooks';
import { petThumbnail } from 'services/api';
import { Modal as CommonModal, Alert, Button, Crop } from 'components/common';

const Images = styled.div`
  overflow: scroll;
  margin-top: 15px;
  text-align: center;
`;

const Image = styled.img`
  max-height: 150px;
  display: inline-block;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid white;
  transition: 0.2s all linear;
  :hover {
    border: 2px solid ${(p) => p.theme.colours.primary.main};
  }
`;

const Actions = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  > button {
    margin-left: 5px;
  }
`;

const Content = styled.div`
  height: 90%;
  width: 100%;
  overflow: ${(p) => (p.hasImage ? 'initial' : 'auto')};
`;

const ImageSelect = ({ images, onSelect }) => {
  return (
    <>
      <Alert type='info'>
        Select a base image for the face photo - we recommend choosing a clear,
        forward-facing photo of the pet - you can crop it in the next step.
      </Alert>
      <Images>
        {images.map((image, i) => (
          <Image key={i} src={image.url} onClick={() => onSelect(image)} />
        ))}
      </Images>
    </>
  );
};

const Modal = ({ show, onClose, onFetch, pet }) => {
  const [targetImage, setTargetImage] = useState();
  const [cropPosition, setCropPosition] = useState({});
  const [{ res, loading }, saveThumbnail, setRes] = usePost({
    url: petThumbnail,
  });
  const { notify } = useNotification();

  useEffect(() => {
    if (res) {
      setRes(null);
      onFetch();
      onClose();
      setTargetImage();
      notify('Thumbnail successfully updated');
    }
  }, [res, onClose, notify, setRes, onFetch]);

  const handleSelect = (img) => {
    setTargetImage(img);
  };

  const handleSave = () => {
    saveThumbnail({
      id: pet._id,
      crop: cropPosition,
      imageUrl: targetImage.url,
    });
  };

  const handleBack = () => setTargetImage(null);

  const handleClose = () => {
    onClose();
    setTargetImage();
  };

  if (!pet) return null;

  const { images } = pet;
  const image = targetImage || images[0];

  return (
    <CommonModal show={show} onClose={handleClose}>
      <Content hasImage={targetImage}>
        {!targetImage && (
          <ImageSelect images={images} onSelect={handleSelect} />
        )}
        {targetImage && <Crop src={image.url} onChange={setCropPosition} />}
      </Content>
      <Actions>
        {targetImage && (
          <Button disabled={loading} onClick={handleBack}>
            Back
          </Button>
        )}
        <Button disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleSave}>
          Save
        </Button>
      </Actions>
    </CommonModal>
  );
};

export default Modal;
