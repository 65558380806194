import { useState } from 'react';

import Modal from './Modal';
import { Detail } from '../../shared';

const Security = ({ goBack, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleShowModal = (tab) => () => {
    setActiveTab(tab);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTabChange = (i) => {
    setActiveTab(i);
  };

  return (
    <>
      <Detail
        onClick={handleShowModal(0)}
        title='Change Password'
        loading={loading}
      />
      <Modal
        onClose={handleCloseModal}
        onSaveSuccess={goBack}
        show={showModal}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </>
  );
};

export default Security;
