import { useState, useRef } from 'react';

import Label from './Label';
import MobileSelect from './MobileSelect';
import DesktopSelect from './Desktop';
import { Container } from './styled';

const Select = ({
  label,
  required,
  placeholder,
  options,
  filterable,
  multi,
  value,
  onChange,
  className,
  clearable,
  disabled,
}) => {
  const [filter, setFilter] = useState('');
  const selectRef = useRef();

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  let _options = options || [];

  if (typeof _options[0] === 'string') {
    _options = options.map((o) => ({ value: o, label: o }));
  }

  if (filter) {
    _options = _options.filter((o) =>
      o.label.toLowerCase().includes(filter.toLowerCase())
    );
  }

  const commonProps = {
    placeholder,
    multi,
    value: value || (multi ? [] : ''),
    onChange,
    options: _options,
    className: 'select-container',
    clearable,
    required,
    disabled,
  };

  const hasValue = multi ? value?.length : value;

  return (
    <Container
      className={'select ' + className}
      ref={selectRef}
      disabled={disabled}
    >
      <Label label={label} required={required} hasValue={hasValue} />
      <MobileSelect {...commonProps} />
      <DesktopSelect
        filterable={filterable}
        onFilterChange={handleFilterChange}
        filter={filter}
        clearable={clearable}
        {...commonProps}
      />
    </Container>
  );
};

Select.defaultProps = {
  options: [],
  onChange: () => {},
};

export default Select;
