import styled from 'styled-components';

import { Icon } from 'components/common';

export const StatContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-bottom: 0px;
    flex: 1;
    margin-right: 25px;
    align-items: center;
  }
`;

export const StatIcon = styled(Icon)`
  width: 24px;
  padding: 14px;
  background: ${(p) => p.colour}1A;
  fill: ${(p) => p.colour};
  border-radius: 8px;
  display: block;
`;

export const StatLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const PawPrint = styled(Icon)`
  position: absolute;
  right: 25px;
  width: 52px;
  fill: ${(p) => p.colour};
  opacity: 0.1;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 70px;
  }
`;

export const StatDetails = styled.div`
  margin-left: 20px;
  font-weight: 700;
`;

export const StatValue = styled.h3`
  color: ${(p) => p.colour};
`;
