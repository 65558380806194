const Exit = (props) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M9.25862 13C9.80209 13 10.2414 13.448 10.2414 14V18C10.2414 18.551 10.6817 19 11.2241 19H14.1724V4C14.1724 3.146 14.707 2.383 15.5109 2.099L15.8018 2H11.2241C10.6817 2 10.2414 2.449 10.2414 3V6C10.2414 6.552 9.80209 7 9.25862 7C8.71515 7 8.27586 6.552 8.27586 6V3C8.27586 1.346 9.59865 0 11.2241 0H21.7888C21.8261 0 21.8576 0.017 21.8939 0.022C21.9411 0.018 21.9863 0 22.0345 0C23.1185 0 24 0.897 24 2V20C24 20.854 23.4654 21.617 22.6615 21.901L16.7472 23.907C16.5468 23.97 16.3473 24 16.1379 24C15.0539 24 14.1724 23.103 14.1724 22V21H11.2241C9.59865 21 8.27586 19.654 8.27586 18V14C8.27586 13.448 8.71515 13 9.25862 13Z' />
      <path
        opacity='0.8'
        d='M0.297078 9.11097L4.34893 5.2489C4.63864 4.97276 5.07422 4.88973 5.45306 5.03938C5.8309 5.18904 6.07806 5.54145 6.07806 5.93152V8.82807H10.1299C10.6891 8.82807 11.1429 9.26062 11.1429 9.79359C11.1429 10.3266 10.6891 10.7591 10.1299 10.7591H6.07806V13.6557C6.07806 14.0457 5.8309 14.3981 5.45306 14.5478C5.07422 14.6975 4.63864 14.6144 4.34893 14.3383L0.297078 10.4762C-0.0989904 10.0987 -0.0989904 9.48849 0.297078 9.11097Z'
      />
    </svg>
  );
};

export default Exit;
