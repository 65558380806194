import { colours } from 'utils/theme';

import {
  StatContainer,
  StatIcon,
  StatLeft,
  PawPrint,
  StatDetails,
  StatValue,
} from './styled';

const Stat = ({ icon, colour, label, value }) => (
  <StatContainer>
    <StatLeft>
      <StatIcon icon={icon} colour={colour} />
      <StatDetails>
        <h5>{label}</h5>
        <StatValue colour={colour}>{value || '-'}</StatValue>
      </StatDetails>
    </StatLeft>
    <PawPrint icon='pawPrint' colour={colour} />
  </StatContainer>
);

const Stats = ({ counts }) => {
  return (
    <>
      <Stat
        label='Pending'
        value={counts?.pendingCount}
        colour={colours.secondary.four}
        icon='dog'
      />
      <Stat
        label='Available To Adopt'
        value={counts?.availableCount}
        colour={colours.secondary.two}
        icon='foster'
      />
      <Stat
        label='Adopted'
        value={counts?.adoptedCount}
        colour={colours.secondary.one}
        icon='rehome'
      />
    </>
  );
};

export default Stats;
