import styled from 'styled-components';

import { Required } from '../styled';

const Container = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark2};
  margin-bottom: 10px;
`;

const Label = ({ hasValue, label, required }) => {
  if (!label) return null;

  return (
    <Container>
      {required && <Required>*</Required>}
      {label}
      {!hasValue && required && <Required text>Required</Required>}
    </Container>
  );
};

export default Label;
