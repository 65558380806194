import { useState } from 'react';

import Modal from './Modal';
import { Detail } from '../../shared';

const getName = (charity) => {
  const { name, charityNumber } = charity || {};
  return charityNumber ? `${name} - (${charityNumber})` : name;
};

const getAddress = (charity) => {
  const { addressLine1, addressLine2, postcode, city, country } = charity || {};

  return (
    [addressLine1, addressLine2, postcode, city, country]
      .filter((a) => !!a)
      .join(', ') || '-'
  );
};

const getContact = (charity) => {
  const { email, telephone, website } = charity || {};

  return [email, website, telephone].filter((c) => !!c).join(' / ') || '-';
};

const Details = ({ details, loading }) => {
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleShowModal = (tab) => () => {
    setActiveTab(tab);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTabChange = (i) => {
    setActiveTab(i);
  };

  const handleSaveSuccess = () => {
    setShowModal(false);
  };

  return (
    <>
      <Detail
        onClick={handleShowModal(0)}
        title='Name'
        subtitle={getName(details)}
        loading={loading}
      />
      <Detail
        onClick={handleShowModal(0)}
        title='Description'
        subtitle={details?.description}
        loading={loading}
      />
      <Detail
        onClick={handleShowModal(1)}
        title='Address'
        subtitle={getAddress(details)}
        loading={loading}
      />
      <Detail
        onClick={handleShowModal(2)}
        title='Contact'
        subtitle={getContact(details)}
        loading={loading}
      />
      <Detail
        onClick={handleShowModal(2)}
        title='Donation Link'
        subtitle={details?.donationUrl}
        loading={loading}
      />
      <Detail
        onClick={handleShowModal(3)}
        title='Update Logo'
        loading={loading}
      />
      <Modal
        charityInfo={details}
        onClose={handleCloseModal}
        onSaveSuccess={handleSaveSuccess}
        show={showModal}
        activeTab={activeTab}
        onTabChange={handleTabChange}
      />
    </>
  );
};

export default Details;
