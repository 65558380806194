import { useState } from 'react';

import { Container, HiddenInput, Indicator, CheckIcon } from './styled';

const Checkbox = ({ onChange, label, value, size, ...rest }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => onChange(e.target.checked);
  const handleFocus = () => setIsFocused((s) => !s);
  const handleBlur = () => setIsFocused((s) => !s);

  return (
    <Container isFocused={isFocused} className='checkbox' size={size} {...rest}>
      <Indicator className='indicator' checked={value} size={size}>
        <CheckIcon icon='check' />
      </Indicator>
      {label}
      <HiddenInput
        type='checkbox'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        checked={value}
      />
    </Container>
  );
};

Checkbox.defaultProps = {
  onChange: () => {},
  value: false,
  size: 'small',
};

export default Checkbox;
