const Search = (props) => {
  return (
    <svg
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <ellipse
        cx='9.80553'
        cy='9.8413'
        rx='7.49047'
        ry='7.51806'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15.0153 15.4609L17.9519 18.4008'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Search;
