import styled from 'styled-components';

export const Pane = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: white;
  border-radius: 16px;
  padding: 30px 20px;
  display: flex;
  margin: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    flex: 1 0;
  }
`;

export const Thumbnail = styled.div`
  cursor: pointer;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${(p) => p.theme.colours.main};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;
  margin-top: 15px;
`;

export const Name = styled.div`
  font-weight: 600;
  margin-top: 5px;
`;

export const FilterContainer = styled.div`
  background: white;
  border-radius: 16px;
  padding: 15px;
  margin: 15px;
  width: 250px;
  margin-left: auto;
`;
