const Bin = (props) => {
  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M11.5938 1.75H9.1875V1.3125C9.1875 0.587617 8.59988 0 7.875 0H6.125C5.40012 0 4.8125 0.587617 4.8125 1.3125V1.75H2.40625C1.8022 1.75 1.3125 2.2397 1.3125 2.84375V3.71875C1.3125 3.96039 1.50836 4.15625 1.75 4.15625H12.25C12.4916 4.15625 12.6875 3.96039 12.6875 3.71875V2.84375C12.6875 2.2397 12.1978 1.75 11.5938 1.75ZM5.6875 1.3125C5.6875 1.07133 5.88383 0.875 6.125 0.875H7.875C8.11617 0.875 8.3125 1.07133 8.3125 1.3125V1.75H5.6875V1.3125Z' />
      <path d='M2.14306 5.03125C2.06499 5.03125 2.00279 5.09649 2.00651 5.17448L2.36744 12.7498C2.4008 13.4509 2.97666 14 3.6783 14H10.3223C11.0239 14 11.5998 13.4509 11.6331 12.7498L11.9941 5.17448C11.9978 5.09649 11.9356 5.03125 11.8575 5.03125H2.14306ZM8.7503 6.125C8.7503 5.88328 8.94608 5.6875 9.1878 5.6875C9.42952 5.6875 9.6253 5.88328 9.6253 6.125V11.8125C9.6253 12.0542 9.42952 12.25 9.1878 12.25C8.94608 12.25 8.7503 12.0542 8.7503 11.8125V6.125ZM6.5628 6.125C6.5628 5.88328 6.75858 5.6875 7.0003 5.6875C7.24201 5.6875 7.4378 5.88328 7.4378 6.125V11.8125C7.4378 12.0542 7.24201 12.25 7.0003 12.25C6.75858 12.25 6.5628 12.0542 6.5628 11.8125V6.125ZM4.3753 6.125C4.3753 5.88328 4.57108 5.6875 4.8128 5.6875C5.05451 5.6875 5.2503 5.88328 5.2503 6.125V11.8125C5.2503 12.0542 5.05451 12.25 4.8128 12.25C4.57108 12.25 4.3753 12.0542 4.3753 11.8125V6.125Z' />
    </svg>
  );
};

export default Bin;
