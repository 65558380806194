import { Input, Checkbox } from 'components/common';

import { FormContainer, SectionTitle, Row } from './styled';

const requiredFields = ['name', 'email'];

const Form = ({ details, onChange }) => {
  const getFormProps = (key) => ({
    value: details[key],
    onChange: onChange(key),
    required: requiredFields.includes(key),
  });

  return (
    <FormContainer>
      <SectionTitle>Details</SectionTitle>
      <Row>
        <Input label='Name' {...getFormProps('name')} />
        <Input label='Charity Number' {...getFormProps('charityNumber')} />
      </Row>
      <SectionTitle>Address</SectionTitle>
      <Row>
        <Input label='Address Line 1' {...getFormProps('addressLine1')} />
      </Row>
      <Row>
        <Input label='Address Line 2' {...getFormProps('addressLine2')} />
      </Row>
      <Row>
        <Input label='Postcode' {...getFormProps('postcode')} />
        <Input label='City' {...getFormProps('city')} />
        <Input label='Country' {...getFormProps('country')} />
      </Row>
      <SectionTitle>Contact</SectionTitle>
      <Row>
        <Input label='Email' {...getFormProps('email')} />
        <Input label='Telephone' {...getFormProps('telephone')} />
        <Input label='Website' {...getFormProps('website')} />
      </Row>
      <SectionTitle>Publicly Visible</SectionTitle>
      <Row>
        <Checkbox
          style={{ marginTop: 20 }}
          label='Visible'
          size='large'
          {...getFormProps('isEnabled')}
        />
      </Row>
    </FormContainer>
  );
};

export default Form;
