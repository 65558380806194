const Application = (props) => {
  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <mask
        id='mask0'
        mask-type='alpha'
        maskUnits='userSpaceOnUse'
        x='1'
        y='0'
        width='12'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.55554 0.65625H12.2807V13.143H1.55554V0.65625Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.43174 1.59967C3.38958 1.59967 2.52415 2.44371 2.49962 3.48461V10.2112C2.47635 11.2885 3.32543 12.176 4.39211 12.1999H9.46392C10.5136 12.1565 11.3451 11.2841 11.3375 10.2149V4.63621L8.42238 1.59967H4.43928H4.43174ZM4.43931 13.1433H4.37076C2.78456 13.1074 1.52164 11.7879 1.55624 10.2011V3.47329C1.59271 1.91288 2.88142 0.65625 4.43051 0.65625H4.4412H8.62367C8.75198 0.65625 8.87462 0.708452 8.96393 0.800907L12.1495 4.11984C12.2338 4.20727 12.281 4.32488 12.281 4.44626V10.2112C12.2923 11.7892 11.0633 13.0785 9.48281 13.1427L4.43931 13.1433Z'
        />
      </g>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.8063 5.04196H10.0735C8.92256 5.03881 7.98669 4.10043 7.98669 2.95072V1.12112C7.98669 0.860739 8.19802 0.649414 8.4584 0.649414C8.71878 0.649414 8.93011 0.860739 8.93011 1.12112V2.95072C8.93011 3.58218 9.44396 4.09665 10.0748 4.09854H11.8063C12.0667 4.09854 12.278 4.30987 12.278 4.57025C12.278 4.83063 12.0667 5.04196 11.8063 5.04196Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.34078 9.52252H4.94449C4.6841 9.52252 4.47278 9.31119 4.47278 9.05081C4.47278 8.79043 4.6841 8.5791 4.94449 8.5791H8.34078C8.60116 8.5791 8.81249 8.79043 8.81249 9.05081C8.81249 9.31119 8.60116 9.52252 8.34078 9.52252Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.05473 7.16314H4.944C4.68362 7.16314 4.47229 6.95181 4.47229 6.69143C4.47229 6.43105 4.68362 6.21973 4.944 6.21973H7.05473C7.31512 6.21973 7.52644 6.43105 7.52644 6.69143C7.52644 6.95181 7.31512 7.16314 7.05473 7.16314Z'
      />
    </svg>
  );
};

export default Application;
