import styled from 'styled-components';

import { Icon } from 'components/common';

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colours.primary.dark2};
  position: relative;
  ${(p) => p.theme.breakpoints.desktop} {
    h5 {
      font-size: 20px;
    }
  }
`;

export const Back = styled(Icon)`
  stroke: ${(p) => p.theme.colours.primary.dark2};
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
`;

export const Delete = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: ${(p) => p.theme.colours.primary.dark4};
  display: flex;
  align-items: center;
  right: 0px;
  position: absolute;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 12px;
    padding: 8px 16px;
    border: 1px solid ${(p) => p.theme.colours.primary.dark4};
    background: white;
    color: ${(p) => p.theme.colours.primary.dark4};
    cursor: pointer;
  }
`;

export const BinIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark4};
  margin-right: 5px;
  width: 14px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 16px;
  }
`;

export const FormContainer = styled.div`
  background: white;
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  .input,
  .select {
    margin-top: 15px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  padding-bottom: 15px;
  padding-top: 30px;
  :first-child {
    padding-top: 10px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 100px;
  > button {
    margin-left: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
    > * {
      flex: 1;
      margin-left: 20px;
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;
