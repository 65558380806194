import styled from 'styled-components';
import { cloneElement, useRef, useState, forwardRef, useEffect } from 'react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark2}1A;
  position: relative;
  margin-bottom: 10px;
  user-select: none;
  > div {
    margin-right: 30px;
  }
`;

const Indicator = styled.div`
  height: 3px;
  background: ${(p) => p.theme.colours.secondary.two};
  position: absolute;
  bottom: -2px;
  left: ${(p) => p.left}px;
  width: ${(p) => p.width}px;
  transition: 0.3s all ease;
`;

const TabContainer = styled.div`
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: 0.3s ease all;
  color: ${(p) =>
    p.isActive ? p.theme.colours.secondary.two : p.theme.colours.primary.dark3};
`;

const Content = styled.div`
  max-height: 100%;
  overflow: auto;
`;

const Controlled = ({ children, activeTab, onChange, ...rest }) => {
  const [indicatorPosition, setIndicatorPosition] = useState({});
  const activeRef = useRef();

  const _children = Array.isArray(children) ? children : [children];
  const activeTabChildren = _children[activeTab].props.children;

  useEffect(() => {
    if (activeRef) {
      const { offsetLeft, offsetWidth } = activeRef.current;
      setIndicatorPosition({ left: offsetLeft, width: offsetWidth });
    }
  }, [activeTab]);

  return (
    <Container {...rest}>
      <Tabs>
        {_children.map((c, i) => {
          const isActive = activeTab === i;
          return cloneElement(c, {
            isActive,
            onClick: () => onChange(i),
            ref: isActive ? activeRef : null,
            key: i,
          });
        })}
        <Indicator {...indicatorPosition} />
      </Tabs>
      <Content>{activeTabChildren}</Content>
    </Container>
  );
};

const Uncontrolled = () => {
  const [activeTab, setActiveTab] = useState(0);

  return <Controlled activeTab={activeTab} onChange={setActiveTab} />;
};

const TabbedPanel = (props) => {
  return props.onChange ? (
    <Controlled {...props} />
  ) : (
    <Uncontrolled {...props} />
  );
};

const Tab = forwardRef(({ label, onClick, isActive }, ref) => {
  return (
    <TabContainer onClick={onClick} ref={ref} isActive={isActive}>
      {label}
    </TabContainer>
  );
});

export { TabbedPanel, Tab };
