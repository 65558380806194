import styled from 'styled-components';

import { Icon } from 'components/common';

export const Container = styled.div`
  background: white;
  border-radius: 12px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px 20px 0px 0px;
    padding: 30px;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-right: 20px;
    font-size: 12px;
    color: ${(p) => p.theme.colours.primary.dark3};
  }
  ${(p) => p.theme.breakpoints.desktop} {
    h6 {
      font-size: 20px;
      font-weight: 700;
    }
  }
`;

export const UsersIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.secondary.one};
  padding: 11px;
  width: 20px;
  background: ${(p) => p.theme.colours.secondary.one}1A;
  border-radius: 50%;
  margin-right: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 28px;
    border-radius: 8px;
  }
`;

export const AddUser = styled.div`
  font-size: 13px;
  color: ${(p) => p.theme.colours.primary.dark4};
  font-weight: 500;
  display: flex;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 12px;
    padding: 8px 16px;
    border: 1px solid ${(p) => p.theme.colours.primary.dark4};
    background: white;
    color: ${(p) => p.theme.colours.primary.dark4};
    cursor: pointer;
  }
`;

export const AddUserIcon = styled(Icon)`
  width: 14px;
  fill: ${(p) => p.theme.colours.primary.dark4};
  margin-right: 5px;
`;
