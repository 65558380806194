import styled from 'styled-components';

import Icon from '../Icon';

export const ContextMenuOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark4};
  width: 145px;
  text-align: center;
`;

export const ContextMenuOption = styled.div`
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #d5d1e0;
  :last-child {
    border-bottom: 0px solid #d5d1e0;
  }
`;

export const Container = styled.div`
  background: ${(p) =>
    p.orientation === 'horizontal'
      ? 'none'
      : p.theme.colours.primary.main + 40};
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  position: relative;
  user-select: none;
`;

export const Dots = styled(Icon)`
  width: 4px;
  transform: rotate(
    ${(p) => (p.orientation === 'horizontal' ? '90deg' : '0deg')}
  );
  fill: ${(p) =>
    p.orientation === 'horizontal' ? p.theme.colours.primary.dark2 : 'white'};
`;
