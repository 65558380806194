const Foster = (props) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#fosterClip)'>
        <path d='M15.7465 6.10466C14.1893 6.43898 12.6548 5.44629 12.3209 3.8881C11.9874 2.32966 12.9793 0.796637 14.5375 0.462075C16.0952 0.128246 17.629 1.12094 17.9636 2.6784C18.2977 4.23659 17.305 5.77108 15.7465 6.10466Z' />
        <path d='M0.30692 18.6227L1.60412 18.108L3.73909 14.3464L6.47768 12.3359L7.71355 13.0539L8.98583 12.5908C9.15396 12.1856 9.55329 11.9004 10.0198 11.9004C12.6194 11.9004 14.1304 10.045 14.257 8.27032C14.3435 7.37514 15.0474 6.63637 15.7839 6.27957C15.7839 6.27957 16.0628 6.12707 16.38 6.06622C16.6833 6.0083 17.0357 6.00635 17.0357 6.00635L17.0442 6.00586C18.0433 6.03445 19.219 6.59165 19.4683 7.75223L20.8022 13.9796C20.8821 14.3513 20.8484 14.7055 20.7372 15.0297C20.7208 15.0918 19.3371 18.5103 19.3371 18.5103H22.4718C23.3154 18.5103 23.9995 19.1945 23.9995 20.0382C23.9995 20.8818 23.3154 21.5656 22.4718 21.5656H17.0684C16.5601 21.5656 16.085 21.3126 15.8006 20.8906C15.5168 20.4685 15.4616 19.9338 15.6529 19.4629L16.512 17.3499L14.6536 17.8812L15.3533 21.7259C15.5041 22.5558 14.9537 23.3505 14.1241 23.5023C14.0317 23.5192 13.9395 23.5277 13.8486 23.5277C13.125 23.5275 12.482 23.0108 12.3474 22.2735L11.3996 17.0669C11.2589 16.2937 11.7271 15.5405 12.4825 15.3245L15.4914 14.4642L15.0024 12.1807C13.8982 13.4696 12.224 14.1409 10.0203 14.1409C9.74831 14.1409 9.50246 14.0402 9.30842 13.8792L8.24558 14.266L5.33029 19.8412L5.45468 22.8439C5.46983 23.2081 5.18659 23.5157 4.82221 23.5311C4.81268 23.5316 4.80388 23.5316 4.79435 23.5316C4.44243 23.5316 4.14966 23.254 4.13524 22.8987L4.05557 20.9805L3.73982 23.0424C3.68997 23.3686 3.40892 23.6025 3.0878 23.6025C3.05456 23.6025 3.02083 23.6003 2.98711 23.5949C2.62639 23.5392 2.37932 23.2029 2.43431 22.8422L2.95045 19.4741L2.06577 18.9712L0.665924 19.5267C0.607515 19.5501 0.546419 19.5609 0.487033 19.5609C0.293478 19.5609 0.110433 19.445 0.0344285 19.2539C-0.0647925 19.0044 0.0574009 18.7214 0.30692 18.6227Z' />
        <path d='M6.21199 10.9355C6.12621 11.0167 6.03969 11.0983 5.95245 11.1794C5.89013 11.2376 6.24522 12.0634 6.24522 12.0634L3.57823 13.9928C3.39861 13.7115 3.21849 13.429 3.03887 13.1467C2.64321 12.5279 2.26612 11.8962 1.80056 11.3263C1.67372 11.1714 1.52294 11.0313 1.36458 10.9069C0.635326 10.3368 1.08866 9.94407 1.20597 9.87565C1.49826 9.70336 1.82085 9.73586 2.12217 9.89471C2.29007 9.98391 2.45625 10.076 2.62268 10.1689C2.67156 10.196 2.7087 10.1943 2.75489 10.1584C2.83285 10.0993 2.91814 10.0479 3.00123 9.99686C3.48219 9.70458 3.9497 9.75688 4.46413 9.53351C4.63838 9.45824 5.72492 8.21261 6.30119 8.51638C6.9295 8.84728 6.97472 9.53644 6.7731 10.0917C6.65481 10.4189 6.46517 10.6982 6.21199 10.9355Z' />
      </g>
      <defs>
        <clipPath id='fosterClip'>
          <rect width='24' height='24' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Foster;
