import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGet } from 'hooks';
import { allApplications } from 'services/api';
import { Application, Page, Pagination, Select } from 'components/common';

import { Pane, Thumbnail, Details, Name, FilterContainer } from './styled';
import { useState } from 'react';

const filterOptions = [
  { label: 'Maybe', value: 'approved' },
  { label: 'No', value: 'rejected' },
  { label: 'Unprocessed', value: 'unprocessed' },
];

const Applications = () => {
  const [{ res, loading }, getApplications] = useGet({
    url: allApplications,
    preserveLast: true,
  });
  const [filter, setFilter] = useState(['approved', 'unprocessed']);
  const [page, setPage] = useState(0);
  const { push } = useHistory();

  useEffect(() => {
    const reqFilter = { filter };
    getApplications({ query: { skip: page * 24, ...reqFilter } });
  }, [page, getApplications, filter]);

  if (res && !res.applications?.length)
    return (
      <Page>
        <FilterContainer>
          <Select
            label='Filter Applications'
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            multi
          />
        </FilterContainer>
        <Pane>No applications found</Pane>
      </Page>
    );

  const handleClick = (id) => {
    push(`/pet/${id}/applications`);
  };

  const handleRefetch = () => {
    const reqFilter = { filter };
    getApplications({ query: { skip: page * 24, ...reqFilter } });
  };

  const appStatuses = ['Adopted', 'Rainbow Bridge'];
  return (
    <Page>
      <FilterContainer>
        <Select
          label='Filter Applications'
          options={filterOptions}
          value={filter}
          onChange={setFilter}
          multi
        />
      </FilterContainer>
      {res &&
        res.applications?.map((application, i) => {
          const showUnprocessed = !appStatuses.includes(
            application.pet[0]?.status
          );
          return (
            <Pane key={application._id}>
              <Details>
                <Thumbnail onClick={() => handleClick(application.pet[0]?._id)}>
                  <img
                    src={application.pet[0]?.thumbnail.url}
                    alt='Pet thumbnail'
                  />
                </Thumbnail>
                <Name>{application.pet[0]?.name}</Name>
              </Details>
              <Application
                onUpdateApplicationStatus={handleRefetch}
                showUnprocessed={showUnprocessed}
                {...application}
              />
            </Pane>
          );
        })}
      <Pagination
        totalResults={res?.count}
        page={page}
        onChangePage={setPage}
        loading={loading}
        style={{ marginRight: 15 }}
      />
      <div style={{ marginBottom: 100 }} />
    </Page>
  );
};

export default Applications;
