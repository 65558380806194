import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import throttle from 'lodash.throttle';

import NoScroll from '../NoScroll';

import {
  Container,
  Line,
  Dot,
  Value,
  Fill,
  SliderContainer,
  Labels,
  Label,
} from './styled';

const Dots = ({ count, value, onDotClick }) => {
  const arrayForMap = Array.from(Array(count));
  return arrayForMap.map((x, i) => {
    const leftPercent = (i / (count - 1)) * 100;
    const isFilled = value >= leftPercent;

    return (
      <Dot
        key={i}
        isFilled={isFilled}
        left={leftPercent}
        onClick={(e) => onDotClick(e, i)}
      />
    );
  });
};

const Slider = ({ maxValue = 5, labels = [], steps = 5, value, onChange }) => {
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef();

  const getValueFromDrag = useCallback(
    (e) => {
      const { left, width } = containerRef.current.getBoundingClientRect();
      const dragX = e.clientX - left;
      const nearestDot = Math.round((dragX / width) * steps);
      let asValue = (maxValue / steps) * nearestDot;
      if (asValue < 0) asValue = 0;
      if (asValue > maxValue) asValue = maxValue;
      return asValue;
    },
    [maxValue, steps]
  );

  useEffect(() => {
    const handleMouseMove = throttle((e) => {
      if (isDragging) {
        const value = getValueFromDrag(e);
        onChange(Math.abs(value));
      }
    }, 100);

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('touchend', handleMouseUp);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('touchend', handleMouseUp);
    };
  }, [isDragging, steps, maxValue, getValueFromDrag, onChange]);

  const handleDotClick = (e, i) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(Math.abs((maxValue / steps) * i));
  };

  const handleLineClick = (e) => {
    const { left, width } = containerRef.current.getBoundingClientRect();
    const clickX = e.clientX - left;
    const nearestDot = Math.round((clickX / width) * steps);
    onChange(Math.abs((maxValue / steps) * nearestDot));
  };

  const throttledTouchUpdate = useMemo(
    () =>
      throttle((touch) => {
        const value = getValueFromDrag(touch);
        onChange(Math.abs(value));
      }, 100),
    [getValueFromDrag, onChange]
  );

  const handleTouchMove = (e) => {
    const { targetTouches } = e;
    const touch = targetTouches[0];
    throttledTouchUpdate(touch);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const valueAsPercent = (value / maxValue) * 100;

  return (
    <Container>
      <SliderContainer onClick={handleLineClick} ref={containerRef}>
        <Line />
        <Fill width={valueAsPercent || 0} />
        <Dots
          count={steps + 1}
          value={valueAsPercent}
          onDotClick={handleDotClick}
        />
        <Value
          left={valueAsPercent}
          value={value}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onMouseDown={handleMouseDown}
        />
        {isDragging && <NoScroll />}
      </SliderContainer>
      <Labels>
        {labels.map((l, i) => (
          <Label key={i}>{l}</Label>
        ))}
      </Labels>
    </Container>
  );
};

export default Slider;
