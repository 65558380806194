import { Button as CommonButton } from 'components/common';

import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 16px;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

export const AddNew = styled.div`
  padding: 32px 20px;
  display: flex;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

export const Button = styled(CommonButton)`
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 12px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, 1fr);
  gap: 12px;
  ${(p) => p.theme.breakpoints.desktop} {
    gap: 25px;
  }
`;
