const Location = (props) => {
  return (
    <svg viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.64579 4.81306C6.64579 4.17998 6.13281 3.66699 5.50019 3.66699C4.86711 3.66699 4.35413 4.17998 4.35413 4.81306C4.35413 5.44568 4.86711 5.95866 5.50019 5.95866C6.13281 5.95866 6.64579 5.44568 6.64579 4.81306Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.49978 9.625C4.95047 9.625 2.0625 7.28676 2.0625 4.84151C2.0625 2.92721 3.60117 1.375 5.49978 1.375C7.39838 1.375 8.9375 2.92721 8.9375 4.84151C8.9375 7.28676 6.04908 9.625 5.49978 9.625Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Location;
