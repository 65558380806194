import Logo from './Logo';
import PawPrint from './PawPrint';
import Email from './Email';
import Lock from './Lock';
import Menu from './Menu';
import Search from './Search';
import Back from './Back';
import Dog from './Dog';
import Charity from './Charity';
import Users from './Users';
import Settings from './Settings';
import Exit from './Exit';
import Foster from './Foster';
import Rehome from './Rehome';
import AvatarChevron from './AvatarChevron';
import Location from './Location';
import Application from './Application';
import Message from './Message';
import Close from './Close';
import ApplicationFill from './ApplicationFill';
import MessageFill from './MessageFill';
import Image from './Image';
import Bin from './Bin';
import Down from './Down';
import Calendar from './Calendar';
import Check from './Check';
import Upload from './Upload';
import UploadLarge from './UploadLarge';
import ThreeDots from './ThreeDots';
import AddUser from './AddUser';
import Profile from './Profile';
import Security from './Security';
import Notification from './Notification';
import Thumb from './Thumb';
import Code from './Code';
import Save from './Save';

const icons = {
  addUser: AddUser,
  application: Application,
  applicationFill: ApplicationFill,
  avatarChevron: AvatarChevron,
  back: Back,
  bin: Bin,
  calendar: Calendar,
  charity: Charity,
  check: Check,
  chevron: AvatarChevron,
  close: Close,
  code: Code,
  dog: Dog,
  down: Down,
  email: Email,
  exit: Exit,
  foster: Foster,
  image: Image,
  location: Location,
  lock: Lock,
  logo: Logo,
  menu: Menu,
  message: Message,
  messageFill: MessageFill,
  notification: Notification,
  pawPrint: PawPrint,
  profile: Profile,
  rehome: Rehome,
  search: Search,
  security: Security,
  settings: Settings,
  threeDots: ThreeDots,
  upload: Upload,
  uploadLarge: UploadLarge,
  users: Users,
  thumb: Thumb,
  save: Save,
};

// Any destructured properties here are
// to stop them being passed to the DOM
const Icon = ({
  icon,
  useStroke,
  expanded,
  iconPlacement,
  active,
  isActive,
  no,
  ...rest
}) => {
  if (!icons[icon]) return null;
  const Component = icons[icon];
  return <Component {...rest} />;
};

export default Icon;
