import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-radius: 20px;
  padding: 20px;
  margin-top: 20px;
`;

export const Actions = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #2d405a1a;
  padding-bottom: 8px;
`;

export const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  color: ${(p) => p.theme.colours.primary.dark4};
  width: 145px;
`;

export const Action = styled.div`
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  :first-child {
    border-bottom: 1px solid #d5d1e0;
  }
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  > span {
    font-size: 14px;
    color: ${(p) => p.theme.colours.primary.dark1};
    font-weight: 600;
    :first-child {
      color: ${(p) => p.theme.colours.primary.dark4};
    }
  }
`;
