import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  border-radius: 16px;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  .input,
  .select {
    margin-top: 15px;
  }
`;

export const ProgressionContainer = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
  padding: 10px 0px 30px;
  margin: 0px 15px 25px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 100%;
    max-width: 735px;
    margin: 0 auto 25px;
  }
`;

export const DotContainer = styled.div`
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Circle = styled.div`
  background: ${(p) =>
    p.active ? p.theme.colours.secondary.two : p.theme.colours.primary.dark5};
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  font-weight: 600;
  transition: 0.3s all ease;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 36px;
    height: 36px;
    font-size: 16px;
  }
`;

export const DotLabel = styled.div`
  position: absolute;
  font-size: 10px;
  /* transform: translateY(200%); */
  top: 48px;
  display: flex;
  text-align: center;
  justify-content: center;
  color: ${(p) =>
    p.active ? p.theme.colours.secondary.two : p.theme.colours.primary.dark3};
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 14px;
  }
`;

export const DotLine = styled.div`
  position: absolute;
  height: 3px;
  left: 0px;
  right: 0px;
  background: ${(p) => p.theme.colours.primary.dark5};
  ${(p) => p.theme.breakpoints.desktop} {
    height: 5px;
  }
`;

export const ActiveLine = styled.div`
  position: absolute;
  height: 3px;
  left: 0px;
  width: ${(p) => (p.activeArea - 1) * 25}%;
  background: ${(p) => p.theme.colours.secondary.two};
  transition: 0.3s all ease;
  ${(p) => p.theme.breakpoints.desktop} {
    height: 5px;
  }
`;

export const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  padding-bottom: 15px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 100px;
  ${(p) => p.theme.breakpoints.tablet} {
    justify-content: flex-end;
    > button {
      margin-left: 20px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
    > * {
      flex: 1;
      margin-left: 20px;
      :first-child {
        margin-left: 0px;
      }
    }
  }
`;

export const Required = styled.div`
  position: absolute;
  font-size: 9px;
  top: -15px;
  margin-right: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 900;
  width: 12px;
  height: 12px;
  color: ${(p) => p.theme.colours.secondary.three};
  background: ${(p) => p.theme.colours.shade.three};
  border: 1px solid ${(p) => p.theme.colours.secondary.three};
  ${(p) => p.theme.breakpoints.desktop} {
    top: 3px;
    right: -20px;
    font-size: 11px;
  }
`;
