import { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { usePost, useAuth } from 'hooks';
import { setPassword } from 'services/api';

import {
  FormContainer,
  Logo,
  H2,
  H5,
  Form,
  Button,
  Input,
  Alert,
} from './styled';

const SetPassword = () => {
  const [form, setForm] = useState({ password: '', confirmPassword: '' });
  const [{ res, loading, error }, postSetPassword] = usePost({
    url: setPassword,
  });
  const { setToken, user } = useAuth();
  const { token } = useParams();

  useEffect(() => {
    if (res) {
      setToken(res.token);
    }
  }, [res, setToken]);

  if (user) {
    return <Redirect to='/' />;
  }

  const handleChange = (key) => (value) =>
    setForm((s) => ({ ...s, [key]: value }));

  const handleSubmit = (e) => {
    e.preventDefault();
    postSetPassword({ password: form.password, token });
  };

  const disableSubmit =
    !form.password || (form.password && form.password !== form.confirmPassword);

  return (
    <FormContainer>
      <Logo icon='logo' />
      <H2>Set Password</H2>
      <H5>Enter and confirm a password for your account below</H5>
      <Form onSubmit={handleSubmit}>
        <Input
          disabled={loading}
          icon='lock'
          placeholder='Password'
          type='password'
          value={form.password}
          onChange={handleChange('password')}
        />
        <Input
          disabled={loading}
          icon='lock'
          placeholder='Confirm Password'
          type='password'
          value={form.confirmPassword}
          onChange={handleChange('confirmPassword')}
        />
        <Button
          disabled={disableSubmit}
          fullWidth
          size='large'
          onClick={handleSubmit}
        >
          Set Password
        </Button>
      </Form>
      {error && (
        <Alert type='error'>
          Unable to set password, please contact your account administrator.
        </Alert>
      )}
    </FormContainer>
  );
};

export default SetPassword;
