import { useEffect, useState } from 'react';
import NoScroll from '../NoScroll';

import { Container, Content, Title, Message, Actions, Button } from './styled';

const Prompt = ({ show, onYes, onNo }) => {
  const [transition, setTransition] = useState('entry');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let visibleTimeout;
    let animationTimeout;
    if (show) {
      setVisible(true);
    } else {
      setTransition('exit');
      visibleTimeout = setTimeout(() => {
        setVisible(false);
      }, 300);
      animationTimeout = setTimeout(() => setTransition('entry'), 600);
    }
    return () => {
      clearTimeout(visibleTimeout);
      clearTimeout(animationTimeout);
      setTransition('entry');
    };
  }, [show]);

  if (!visible) return null;

  return (
    <Container transition={`prompt-container-${transition}`}>
      <Content transition={`prompt-content-${transition}`}>
        <Title>Hold Up!</Title>
        <Message>
          You have unsaved changes, are you sure you want to leave?
        </Message>
        <Actions>
          <Button type='secondary' onClick={onNo}>
            No, stay
          </Button>
          <Button onClick={onYes}>Yes, leave</Button>
        </Actions>
      </Content>
      <NoScroll />
    </Container>
  );
};

export default Prompt;
