import { PrefixContainer, Select } from './styled';

const Prefix = ({ prefix, prefixOptions, onChange }) => {
  if (prefixOptions) {
    return (
      <PrefixContainer>
        <Select
          required
          options={prefixOptions}
          value={prefix}
          onChange={onChange}
        />
      </PrefixContainer>
    );
  }

  return <PrefixContainer>{prefix}</PrefixContainer>;
};

export default Prefix;
