const Image = (props) => {
  return (
    <svg
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#imageClip)'>
        <path d='M12.4173 0.938477H1.58343C0.710146 0.938477 0 1.64839 0 2.5227V12.4797C0 13.353 0.710146 14.0621 1.58343 14.0621H12.4173C13.2906 14.0621 14 13.353 14 12.4797V2.5227C14 1.64839 13.2906 0.938477 12.4173 0.938477ZM9.05311 3.29452C9.89762 3.29452 10.5825 3.97946 10.5825 4.82392C10.5825 5.66838 9.89757 6.35331 9.05311 6.35331C8.20837 6.35331 7.52372 5.66838 7.52372 4.82392C7.52372 3.97946 8.20837 3.29452 9.05311 3.29452ZM11.9207 12.5985H6.99986H2.29822C1.87582 12.5985 1.6877 12.2929 1.87812 11.9158L4.50284 6.71708C4.69302 6.3401 5.05547 6.30645 5.31215 6.64186L7.95141 10.0909C8.20809 10.4264 8.65673 10.4549 8.95366 10.1544L9.59932 9.50058C9.89603 9.20004 10.3332 9.23725 10.5753 9.58316L12.2472 11.9714C12.4889 12.3178 12.3431 12.5985 11.9207 12.5985Z' />
      </g>
      <defs>
        <clipPath id='imageClip'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Image;
