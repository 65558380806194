import styled from 'styled-components';

import { Icon, ContextMenuOptions, ContextMenuOption } from 'components/common';

const DeleteIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark4};
  width: 14px;
  margin-right: 5px;
`;

const ImageIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark4};
  width: 14px;
  margin-right: 5px;
`;

const Actions = ({ onDelete, onSetPrimary, id }) => {
  return (
    <ContextMenuOptions>
      <ContextMenuOption onClick={() => onDelete(id)}>
        <DeleteIcon icon='bin' />
        Delete
      </ContextMenuOption>
      <ContextMenuOption onClick={() => onSetPrimary(id)}>
        <ImageIcon icon='image' />
        Make primary
      </ContextMenuOption>
    </ContextMenuOptions>
  );
};

export default Actions;
