import styled from 'styled-components';

import Thumbnail from './Thumbnail';
import List from './List';

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

const Images = (props) => {
  return (
    <Container>
      {props.pet?.images?.length > 0 && <Thumbnail {...props} />}
      <List {...props} />
    </Container>
  );
};

export default Images;
