import styled from 'styled-components';

import {
  Icon,
  Button as CommonButton,
  Input as CommonInput,
  Alert as CommonAlert,
} from 'components/common';

export const Container = styled.div`
  display: flex;
  width: 100vw;
  min-height: 100vh;
`;

export const Banner = styled.div`
  display: none;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 45vw;
    min-height: 100%;
    display: flex;
    justify-content: center;
    background: ${(p) => p.theme.colours.primary.main};
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  ${(p) => p.theme.breakpoints.desktop} {
    justify-content: center;
    align-items: flex-start;
    padding: 0 100px;
  }
`;

export const Logo = styled(Icon)`
  width: 140px;
  margin-top: 80px;
  fill: ${(p) => p.theme.colours.primary.main};
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const WhiteLogo = styled(Icon)`
  width: 250px;
  fill: white;
  margin-top: -100px;
`;

export const PawPrint = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.main};
  position: fixed;
  width: 130px;
  opacity: 0.05;
  z-index: 1;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 250px;
  }
`;

export const PawPrintTop = styled(PawPrint)`
  rotate: 180deg;
  top: -30px;
  left: -30px;
  ${(p) => p.theme.breakpoints.desktop} {
    fill: white;
    top: -50px;
    left: -50px;
  }
`;

export const PawPrintBottom = styled(PawPrint)`
  bottom: -20px;
  right: -20px;
  ${(p) => p.theme.breakpoints.desktop} {
    bottom: -40px;
    right: -40px;
  }
`;

export const H2 = styled.h2`
  margin-top: 50px;
  color: ${(p) => p.theme.colours.secondary.one};
`;

export const H5 = styled.h5`
  margin-bottom: 20px;
  max-width: 550px;
  text-align: center;
  padding: 0px 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    text-align: left;
    padding: 0px;
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 550px;
  box-sizing: border-box;
  padding: 0px 16px;
  margin-top: 20px;
  > * {
    margin-bottom: 20px;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    max-width: 450px;
    padding: 0px;
    button {
      width: auto;
      margin-right: 15px;
    }
    > * {
      margin-bottom: 40px;
    }
  }
`;

export const Input = styled(CommonInput)`
  font-weight: 600;
`;

export const Button = styled(CommonButton)``;

export const Alert = styled(CommonAlert)`
  max-width: 550px;
  ${(p) => p.theme.breakpoints.desktop} {
    max-width: 450px;
  }
`;
