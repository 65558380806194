import { Container, StyledTextArea, Label, Counter, Required } from './styled';

const Input = ({
  className,
  label,
  required,
  onChange,
  value,
  style,
  lines = 5,
  resize = false,
  maxLength,
  disabled,
  ...rest
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  let _value = value;

  return (
    <div
      className='input'
      style={{ ...style, opacity: disabled ? '0.5' : '1' }}
    >
      {label && (
        <Label>
          {required && <Required>*</Required>}
          {label}
          {!value && required && <Required text>Required</Required>}
        </Label>
      )}
      <Container className={className} padForMaxLength={maxLength > 0}>
        {maxLength > 0 && (
          <Counter>{maxLength - _value.length} characters left</Counter>
        )}
        <StyledTextArea
          resize={resize}
          lines={lines}
          onChange={handleChange}
          value={_value}
          maxLength={maxLength}
          disabled={disabled}
          {...rest}
        />
      </Container>
    </div>
  );
};

Input.defaultProps = {
  onChange: () => {},
  value: '',
  rows: 5,
};

export default Input;
