import qs from 'query-string';
import { useEffect, useState } from 'react';
import {
  useParams,
  useHistory,
  useLocation,
  Prompt as RouterPrompt,
} from 'react-router';

import { usePost, usePrevious, useNotification } from 'hooks';
import { Button, Prompt } from 'components/common';
import { pet as petEndpoint } from 'services/api';
import ChooseApplicant from 'components/ChooseApplicant';

import Progression from './Progression';
import DetailsForm from './Details';
import HealthForm from './Health';
import FinancialForm from './Financial';
import PersonalityForm from './Personality';
import HomeRequirementsForm from './HomeRequirements';
import { Actions, Container } from './styled';
import { getChooseApplicantApplications } from '../helpers';

const forms = {
  1: DetailsForm,
  2: HealthForm,
  3: FinancialForm,
  4: HomeRequirementsForm,
  5: PersonalityForm,
};

const defaultValues = {
  needsSomeoneHome: 'No',
  experiencedHome: 'No',
  secureGarden: 'No',
  liveWithDogs: 'Unknown',
  liveWithCats: 'Unknown',
  species: 'Dog',
  // arrivedDate: new Date().toISOString(),
};

const Details = ({ pet, options, loading, onFetch }) => {
  const [activeArea, setActiveArea] = useState(1);
  const [hasChanges, setHasChanges] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showChooseApplicant, setShowChooseApplicant] = useState(false);
  const [details, setDetails] = useState({ ...defaultValues });
  const { id } = useParams();
  const { push } = useHistory();
  const { notify } = useNotification();

  const { search } = useLocation();
  const { mode } = qs.parse(search);

  const prevId = usePrevious(id);

  const [{ res: savedPet, loading: savingPet }, postPet, setSavedPet] = usePost(
    {
      url: petEndpoint,
    }
  );

  let requiredFields = [
    'arrivedDate',
    'colour',
    'dateOfBirth',
    'location',
    'name',
    'rehomingFee',
    'sex',
    'species',
    'status',
  ];

  if (details.status === 'Available to adopt') {
    requiredFields.push(
      'minimumKidAge',
      'liveWithDogs',
      'liveWithCats',
      'socialBlurb'
    );
  }

  if (details.species === 'Dog') {
    requiredFields.push('size', 'dominantBreed');
  }

  if (details.status === 'Needs reclaimed' || details.status === 'Reclaimed') {
    requiredFields = requiredFields.filter(
      (f) => f !== 'rehomingFee' && f !== 'dateOfBirth'
    );
  }

  useEffect(() => {
    if (prevId && !id) {
      setActiveArea(1);
      setSavedPet();
      setHasChanges();

      if (mode) {
        setDetails((s) => {
          delete s._id;
          delete s.chipNumber;
          delete s.createdAt;
          delete s.firstAvailableToAdopt;
          delete s.healthIssues;
          delete s.healthIssuesOther;
          delete s.images;
          delete s.lastShared;
          delete s.name;
          delete s.sex;
          delete s.updatedAt;
          delete s.notify;
          delete s.thumbnail;

          return { ...s };
        });
      } else {
        setDetails({ ...defaultValues });
      }
    }
  }, [id, prevId, setSavedPet, mode]);

  useEffect(() => {
    if (pet) {
      setDetails({ ...defaultValues, ...pet });
    }
  }, [pet]);

  useEffect(() => {
    if (savedPet && !id) {
      push(`/pet/${savedPet._id}`);
      setHasChanges();
      setSavedPet();
      notify('Successfully added');
    } else if (savedPet) {
      setHasChanges();
      notify('Successfully updated');
      onFetch();
    }
  }, [savedPet, id, push, onFetch, notify, setSavedPet]);

  useEffect(() => {
    if (
      hasChanges &&
      details.status === 'Adopted' &&
      !pet?.successfulApplication
    ) {
      setShowChooseApplicant(true);
    }
  }, [hasChanges, details.status, pet?.successfulApplication]);

  const handleAreaChange = (area) => {
    setActiveArea(area);
  };

  const handleSave = () => {
    postPet(details);
  };

  const handleChange = (key) => (value) => {
    setDetails((s) => ({ ...s, [key]: value }));
    setHasChanges(true);
  };

  const handleCancel = () => {
    push('/');
  };

  const handleNext = () => {
    setActiveArea((s) => s + 1);
  };

  const handleBack = () => {
    setActiveArea((s) => s - 1);
  };

  const handleYes = () => {
    push(showPrompt.pathname);
  };

  const handleNo = () => {
    setShowPrompt(false);
  };

  const handleApplicantSuccess = () => {
    setShowChooseApplicant(false);
    notify('Email sent to unsuccessful applicants!');
    handleSave();
  };

  const handleCloseChooseApplicant = () => {
    setDetails((s) => ({ ...s, status: pet.status }));
    setShowChooseApplicant(false);
  };

  const shouldAllowNav = (location) => {
    if (hasChanges && !showPrompt) {
      setShowPrompt(location);
      return false;
    }
    return true;
  };

  const getFormProps = (key) => ({
    value: details[key],
    onChange: handleChange(key),
    required: requiredFields.includes(key),
  });

  const ActiveForm = forms[activeArea];

  const disabled = loading || savingPet;
  const disableSave =
    !process.env.REACT_APP_PAW_LOCAL &&
    (requiredFields.some((f) => !details[f]) || disabled);

  const chooseApplicantApplications = getChooseApplicantApplications(
    pet?.applications
  );

  return (
    <>
      <RouterPrompt message={shouldAllowNav} when={hasChanges && !savedPet} />
      <Prompt show={showPrompt} onYes={handleYes} onNo={handleNo} />
      <Container>
        <Progression
          activeArea={activeArea}
          onChange={handleAreaChange}
          details={details}
          loading={loading}
        />
        <ActiveForm
          disabled={disabled}
          options={options || {}}
          details={details}
          getFormProps={getFormProps}
          onChange={handleChange}
        />
      </Container>
      <Actions>
        <Button type='secondary' disabled={disabled} onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleBack} disabled={disabled || activeArea === 1}>
          Back
        </Button>
        <Button onClick={handleNext} disabled={disabled || activeArea === 5}>
          Next
        </Button>
        <Button disabled={disableSave} onClick={handleSave}>
          Save
        </Button>
      </Actions>
      <ChooseApplicant
        show={showChooseApplicant}
        applications={chooseApplicantApplications}
        onSuccess={handleApplicantSuccess}
        onClose={handleCloseChooseApplicant}
        petId={id}
      />
    </>
  );
};

export default Details;
