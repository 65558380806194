import debounce from 'lodash.debounce';
import { useMemo, useEffect, useState } from 'react';

import { useAuth, useMenu, usePost } from 'hooks';
import { searchDashboard } from 'services/api';

import Search from './Search';
import Results from './Results';
import {
  Container,
  Logo,
  MenuIcon,
  AvatarContainer,
  AvatarInfo,
  AvatarName,
  AvatarRole,
  AvatarImage,
  AvatarChevron,
  TopBar,
  LeftIcons,
  RightIcons,
  Content,
  SearchInput,
  MobileSearchInput,
} from './styled';

const Avatar = () => {
  const { user } = useAuth();

  return (
    <AvatarContainer>
      <AvatarInfo>
        <AvatarName>{user.name}</AvatarName>
        <AvatarRole>{user.charityName}</AvatarRole>
      </AvatarInfo>
      <AvatarImage></AvatarImage>
      <AvatarChevron icon='avatarChevron' />
    </AvatarContainer>
  );
};

const Page = ({ children }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searching, setSearching] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [{ res, loading }, postSearch] = usePost({
    url: searchDashboard,
  });

  const debouncedSearch = useMemo(
    () => debounce((query) => postSearch({ query }), 300),
    [postSearch]
  );

  useEffect(() => {
    if (searchTerm) {
      setShowResults(true);
      debouncedSearch(searchTerm);
    }
  }, [searchTerm, debouncedSearch]);

  useEffect(() => {
    if (!loading) {
      setSearching(false);
    }
  }, [loading]);

  const { setShowMenu } = useMenu();

  const handleToggleMenu = () => setShowMenu((s) => !s);

  const handleToggleSearch = () => setShowSearch((s) => !s);

  const handleChangeSearch = (value) => {
    setSearching(true);
    setSearchTerm(value);
  };

  const handleClose = () => {
    setSearchTerm('');
    setShowResults(false);
  };

  return (
    <Container>
      <Search
        shouldShow={showSearch}
        onClose={handleToggleSearch}
        value={searchTerm}
        onChange={handleChangeSearch}
        results={res}
        loading={loading || searching}
      />
      <Content>
        <TopBar>
          <LeftIcons>
            <MenuIcon icon='menu' onClick={handleToggleMenu} />
            <Logo icon='logo' />
          </LeftIcons>
          <RightIcons>
            <div>
              <MobileSearchInput
                icon='search'
                placeholder='Search'
                onFocus={handleToggleSearch}
              />
              <SearchInput
                icon='search'
                placeholder='Search'
                value={searchTerm}
                onChange={handleChangeSearch}
              />
            </div>
            {showResults && searchTerm && (
              <Results
                results={res}
                loading={loading || searching}
                onClose={handleClose}
              />
            )}
            <Avatar />
          </RightIcons>
        </TopBar>
        {children}
      </Content>
    </Container>
  );
};

export default Page;
