import { Link } from 'components/common';
import { usePost } from 'hooks';
import { resendVerification } from 'services/api';

import { capitalise } from 'utils/string';

const Status = ({ status, _id }) => {
  const [{ res, loading }, resendEmail] = usePost({ url: resendVerification });

  const handleResend = (e) => {
    e.stopPropagation();
    resendEmail({ userId: _id });
  };

  if (res) return 'Pending';
  if (status === 'expired')
    return (
      <Link disabled={loading} onClick={handleResend}>
        Resend Email
      </Link>
    );
  return capitalise(status);
};

export default Status;
