import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import NoScroll from '../NoScroll';
import { useWidth, useAuth, useMenu } from 'hooks';

import permissions from 'utils/permissions';
import {
  Container,
  Content,
  TopIcons,
  PawLogo,
  CharityLogo,
  Back,
  Items,
  ItemContainer,
  NavIcon,
  TopItems,
  BottomItems,
} from './styled';

const Item = ({
  icon,
  children,
  path,
  onClick,
  activeWith = [],
  needs,
  exact,
  ...rest
}) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { setShowMenu } = useMenu();
  const { user } = useAuth();

  if (needs && user && !user[needs]) return null;

  const handleClick = () => {
    path && push(path);
    onClick && onClick();
    setShowMenu(false);
  };

  let isActive =
    activeWith.some((x) => pathname.includes(x)) || pathname.includes(path);
  if (path === '/') {
    isActive =
      pathname === path || activeWith.some((x) => pathname.includes(x));
  }
  if (exact) {
    isActive = pathname === path;
  }

  return (
    <ItemContainer onClick={handleClick} active={isActive} {...rest}>
      <NavIcon icon={icon} />
      {children}
    </ItemContainer>
  );
};

const Menu = () => {
  const { showMenu, setShowMenu } = useMenu();
  const [visible, setVisible] = useState(showMenu);
  const [transition, setTransition] = useState();
  const { isDesktop } = useWidth();
  const { logOut, user } = useAuth();

  useEffect(() => {
    let timeout;
    if (showMenu) {
      setVisible(true);
      setTransition('entry');
    } else {
      setTransition('exit');
      timeout = setTimeout(() => setVisible(false), 300);
    }

    return () => clearTimeout(timeout);
  }, [showMenu]);

  if (!visible && !isDesktop) return null;

  return (
    <Container transition={`menu-container-${transition}`}>
      <Content transition={`menu-content-${transition}`}>
        <TopIcons>
          {user?.charityLogo ? (
            <CharityLogo src={user.charityLogo} />
          ) : (
            <PawLogo icon='logo' />
          )}
          <Back icon='back' onClick={() => setShowMenu(false)} />
        </TopIcons>
        <Items>
          <TopItems>
            <Item path='/' activeWith={['/pet']} icon='dog'>
              Pets
            </Item>
            <Item path='/applications' icon='applicationFill' exact>
              Applications
            </Item>
            <Item
              path='/charities'
              activeWith={['/charities', '/charity']}
              icon='charity'
              needs={permissions.PAW_ADMIN}
            >
              Charities
            </Item>
            <Item
              path='/users'
              activeWith={['/users', '/user']}
              icon='users'
              needs={permissions.CHARITY_ADMIN}
            >
              Users
            </Item>
            <Item path='/settings' icon='settings'>
              Settings
            </Item>
          </TopItems>
          <BottomItems>
            <a
              href={`https://petadoptionwebsite.com/${user?.charitySlug}`}
              target='_blank'
              rel='noreferrer'
            >
              <Item bottom icon='pawPrint'>
                Public Page
              </Item>
            </a>
            <Item bottom icon='exit' onClick={logOut}>
              Sign Out
            </Item>
          </BottomItems>
        </Items>
      </Content>
      <NoScroll />
    </Container>
  );
};

export default Menu;
