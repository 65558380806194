import Input from '../Input';

const currencyOptions = [
  { label: '£', value: 'GBP', expandedLabel: '£ (GBP)' },
  { label: '€', value: 'EUR', expandedLabel: '€ (EUR)' },
];

const CurrencyInput = ({ value, onChange, ...rest }) => {
  let _value = value || { currency: 'GBP', amount: '' };
  const handleChange = (amount) => {
    onChange({
      ..._value,
      amount,
    });
  };

  const handlePrefixChange = (currency) => {
    onChange({
      ..._value,
      currency,
    });
  };

  return (
    <Input
      prefixOptions={currencyOptions}
      prefix={_value.currency}
      value={_value.amount}
      onChange={handleChange}
      onPrefixChange={handlePrefixChange}
      {...rest}
    />
  );
};

export default CurrencyInput;
