const Down = (props) => {
  return (
    <svg
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M6.29289 9.29289L2.70711 5.70711C2.07714 5.07714 2.52331 4 3.41421 4H10.5858C11.4767 4 11.9229 5.07714 11.2929 5.70711L7.70711 9.29289C7.31658 9.68342 6.68342 9.68342 6.29289 9.29289Z' />
    </svg>
  );
};

export default Down;
