import styled from 'styled-components';

import { useGet } from 'hooks';
import { Page } from 'components/common';
import { charities } from 'services/api';

import Header from './Header';
import List from './List';

const Container = styled.div`
  padding: 20px 16px;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px 25px;
  }
`;

const Charities = () => {
  const [{ res, loading }] = useGet({ url: charities, onMount: true });
  return (
    <Page>
      <Container>
        <Header charityCount={res?.length} />
        <List charities={res} loading={loading} />
      </Container>
    </Page>
  );
};

export default Charities;
