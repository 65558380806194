import styled, { css } from 'styled-components';

import Icon from '../Icon';

const sharedStyle = css`
  display: none;
  -webkit-appearance: none;
  background: #f3f5f9;
  box-sizing: border-box;
  border: 1px solid ${(p) => p.theme.colours.primary.main}00;
  border-radius: 20px;
  outline: none;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  min-height: 51px;
  padding: ${(p) => (p.icon ? '15px 15px 15px 50px' : '15px')};
  color: ${(p) => p.theme.colours.primary.dark2};
  user-select: none;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.colours.primary.main}00;
  transition: 0.3s border ease;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    display: flex;
  }
  :focus {
    border: 1px solid
      ${(p) => (p.disabled ? 'transparent' : p.theme.colours.primary.main)}FF;
  }
`;

export const Container = styled.div`
  opacity: ${(p) => (p.disabled ? '0.5' : 1)};
  position: relative;
`;

export const DesktopSelect = styled.div`
  ${sharedStyle}
`;

export const DesktopInput = styled.input`
  ${sharedStyle}
`;

export const DesktopOptions = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  z-index: 2;
  filter: drop-shadow(3px 10px 30px rgba(0, 0, 0, 0.16));
  border-radius: 12px;
  overflow: auto;
  margin-top: 10px;
  top: 100%;
  transform: translateY(${(p) => p.translateY}px);
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : '75vh')};
`;

export const DesktopOption = styled.div`
  padding: 10px 15px;
  transition: 0.3s all ease;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  cursor: pointer;
  :hover {
    background: ${(p) => p.theme.colours.primary.dark5}1A;
  }
`;

export const DownIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark5};
  width: 14px;
  position: absolute;
  right: 17px;
  pointer-events: none;
  transition: 0.3s all ease;
  transform: ${(p) => (p.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const ClearIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.dark5};
  background: ${(p) => p.theme.colours.primary.dark5}40;
  padding: 5px;
  border-radius: 50%;
  width: 14px;
  position: absolute;
  right: 12px;
  pointer-events: none;
  transition: 0.3s all ease;
  opacity: 0;
  pointer-events: all;
  cursor: pointer;
`;

const iconHoverStyles = css`
  :hover {
    .select-clear-icon {
      opacity: 1;
    }
    .select-down-icon {
      opacity: 0;
    }
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  display: ${(p) => (p.mobile ? 'flex' : 'none')};
  ${(p) => p.theme.breakpoints.desktop} {
    display: ${(p) => (p.mobile ? 'none' : 'flex')};
    ${(p) => p.clearable && !p.disabled && iconHoverStyles}
  }
`;
