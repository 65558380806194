import { useEffect, useState } from 'react';

import permissions from 'utils/permissions';
import { useWidth, useGet, useAuth } from 'hooks';
import { Page, Pagination } from 'components/common';
import { listPets, petOptions } from 'services/api';

import Stats from './Stats';
import Pet from './Pet';
import Filter from './Filter';
import AddModal from './AddModal';
import { Container, AddNew, Row, Button, Grid } from './styled';

const Dashboard = () => {
  const { width } = useWidth();
  const { user } = useAuth();
  const [filter, setFilter] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [page, setPage] = useState(0);

  const [{ res: options, loading: loadingOptions }] = useGet({
    url: petOptions,
    onMount: true,
  });

  useEffect(() => {
    const cachedData = JSON.parse(window.sessionStorage.getItem('cache'));
    if (cachedData) {
      setFilter(cachedData);
    }
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem('cache', JSON.stringify(filter));
  }, [filter]);

  const [{ res: dashboardRes, loading }, getPets] = useGet({
    url: listPets,
  });

  useEffect(() => {
    const reqFilter = { status: filter.status, charityId: filter.charityId };
    if (!filter.charityId) {
      delete reqFilter.charityId;
    }
    getPets({ query: { skip: page * 24, ...reqFilter } });
  }, [page, getPets, filter]);

  const handleAddNew = () => {
    setShowAddModal(true);
  };

  const handleFilterChange = (key) => (value) => {
    setFilter((s) => ({ ...s, [key]: value }));
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  const potentialColumns = Math.floor(width / 190);
  const columns = potentialColumns > 4 ? 4 : potentialColumns;
  const canAdd = user[permissions.CHARITY_ADMIN];

  const _pets = dashboardRes?.data?.filter((d) => {
    return Object.entries(filter).every(([key, value]) => {
      let result = true;
      if (key === 'status' && value?.length) {
        result = result && value.includes(d.status);
      }
      if (key === 'charityId' && value) {
        result = result && d.charityId === value;
      }

      return result;
    });
  });

  return (
    <>
      <Page>
        <Container>
          <Row>
            <Stats counts={dashboardRes?.counts} />
            {canAdd && (
              <AddNew>
                <Button onClick={handleAddNew}>Add New Pet</Button>
              </AddNew>
            )}
          </Row>
          <Filter
            onChange={handleFilterChange}
            value={filter}
            options={options}
            loading={loadingOptions}
          />
          <Grid columns={columns}>
            {_pets?.map((d) => (
              <Pet key={d._id} getPets={getPets} {...d} />
            ))}
          </Grid>
        <Pagination
          totalResults={dashboardRes?.counts.total}
          page={page}
          onChangePage={setPage}
          loading={loading}
        />
        </Container>
      </Page>
      <AddModal
        show={showAddModal}
        onClose={handleCloseModal}
        options={options}
        loading={loadingOptions}
      />
    </>
  );
};

export default Dashboard;
