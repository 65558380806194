import styled from 'styled-components';

import { default as CommonSelect } from '../../Select';
import { fixBase } from '../styled';

export const PrefixContainer = styled.div`
  ${fixBase}
  cursor: ${(p) => (p.hasOptions ? 'pointer' : 'default')};
  border-radius: 16px 0px 0px 16px;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 20px 0px 0px 20px;
  }
`;

export const Select = styled(CommonSelect)`
  margin-top: 0px !important;
  width: 100%;
  .select-component {
    border-radius: 16px 0px 0px 16px;
    background: none;
    font-size: 14px;
    font-family: inherit;
    font-weight: 600;
    margin-left: -3px;
  }

  .select-options {
    width: auto;
    > * {
      white-space: nowrap;
    }
  }

  svg {
    right: 8px;
  }

  ${(p) => p.theme.breakpoints.desktop} {
    .select-control {
      border-radius: 20px 0px 0px 20px;
      background: none;
      font-size: 14px;
    }
  }
`;
