import { useMemo, useEffect, useState, useCallback } from 'react';
import { useAuth } from '../useAuth';

const states = {
  UNSENT: 0,
  OPENED: 1,
  HEADERS_RECEIVED: 2,
  LOADING: 3,
  DONE: 4,
};

const useXHR = (onMount, preserveLast) => {
  const [state, setState] = useState({
    loading: onMount,
    res: null,
    error: null,
  });

  const { logOut } = useAuth();

  const req = useMemo(() => new XMLHttpRequest(), []);

  const handleRequestStart = () => {
    if (preserveLast) {
      setState((s) => ({ res: s.res, loading: true, error: null }));
    } else {
      setState(() => ({ res: null, loading: true, error: null }));
    }
  };

  const handleError = () => {
    setState((s) => ({ ...s, loading: false, error: true }));
  };

  const handleRequestDone = () => {
    if (req.status >= 200 && req.status <= 299) {
      const res = req.responseText
        ? JSON.parse(req.responseText)
        : { status: 'OK' };
      setState({ loading: false, res, error: null });
    } else if (req.status === 500) {
      const { error } = JSON.parse(req.responseText);
      if (error.indexOf('UnauthorizedError') > -1) {
        logOut();
      } else {
        handleError();
      }
    } else if (req.status === 401) {
      logOut();
    } else {
      handleError();
    }
  };

  const handleReadyStateChange = () => {
    switch (req.readyState) {
      case states.OPENED:
        handleRequestStart();
        break;
      case states.DONE:
        handleRequestDone();
        break;
      default:
        break;
    }
  };

  const setRes = useCallback((res) => setState((s) => ({ ...s, res })), []);

  useEffect(() => {
    req.addEventListener('readystatechange', handleReadyStateChange);
    req.addEventListener('error', handleError);

    return () => {
      req.removeEventListener('readystatechange', handleReadyStateChange);
      req.removeEventListener('error', handleError);
    };
  });

  return [state, req, setRes];
};

export default useXHR;
