import styled from 'styled-components';

import { colours } from 'utils/theme';

const statusColor = {
  Adopted: colours.secondary.one,
  'Available to adopt': colours.secondary.two,
  'Needs a foster': colours.secondary.four,
  'Needs reclaimed': colours.secondary.four,
  'Awaiting home check': colours.secondary.two,
  'Rainbow Bridge': colours.secondary.three,
  'In assessment': colours.secondary.four,
  'Not yet arrived': colours.secondary.four,
  Pending: colours.secondary.two,
  Rehoming: colours.secondary.two,
  Processing: colours.secondary.four,
  Reserved: colours.secondary.four,
  Reclaimed: colours.secondary.one,
};

const StatusContainer = styled.div`
  background: ${(p) => statusColor[p.status]}1A;
  color: ${(p) => statusColor[p.status]};
  border-radius: 20px;
  font-weight: 600;
  font-size: 10px;
  padding: 4px 10px;
  display: inline-block;
  margin-bottom: 10px;
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 11px;
  }
`;

const Status = ({ status }) => {
  return <StatusContainer status={status}>{status}</StatusContainer>;
};

export default Status;
