import styled from 'styled-components';
import { useState, useEffect } from 'react';

import { usePost, useNotification } from 'hooks';
import { charity } from 'services/api';
import {
  Modal,
  TabbedPanel,
  Tab,
  Input,
  Button,
  ImageUpload,
  Alert,
  TextArea,
} from 'components/common';

import { FormContainer, Row } from '../../shared';

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    color: ${(p) => p.theme.colours.primary.dark1};
    text-align: left;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  > button {
    margin-left: 15px;
  }
`;

const Panel = styled(TabbedPanel)`
  flex: 1;
`;

const DetailsModal = ({
  onClose,
  charityInfo,
  onSaveSuccess,
  show,
  onTabChange,
  activeTab,
}) => {
  const { notify } = useNotification();
  const [form, setForm] = useState({ ...charityInfo });
  const [{ res: saveRes, loading: saving }, saveCharity, setSaveRes] = usePost({
    url: charity,
  });

  useEffect(() => {
    if (charityInfo) {
      setForm({ ...charityInfo });
    }
  }, [charityInfo]);

  useEffect(() => {
    if (saveRes) {
      onSaveSuccess();
      setSaveRes();
      notify('Successfully updated');
    }
  }, [saveRes, onSaveSuccess, notify, setSaveRes]);

  const getFormProps = (key) => ({
    value: form[key],
    onChange: (value) => setForm((s) => ({ ...s, [key]: value })),
  });

  const handleLogoChange = (logo) => {
    setForm((s) => ({ ...s, logo }));
  };

  const handleSave = () => {
    saveCharity(form);
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Content>
        <h5>Update Charity Details</h5>
        <Panel onChange={onTabChange} activeTab={activeTab}>
          <Tab label='Name'>
            <FormContainer>
              <Row>
                <Input label='Name' {...getFormProps('name')} />
                <Input
                  label='Charity Number'
                  {...getFormProps('charityNumber')}
                />
              </Row>
              <Row>
                <Input
                  label='Register of Sellers or Supplies of Pet Animals Number'
                  {...getFormProps('rsspaNumber')}
                />
              </Row>
              <Row>
                <TextArea
                  lines={7}
                  label='Description'
                  {...getFormProps('description')}
                />
              </Row>
            </FormContainer>
          </Tab>
          <Tab label='Address'>
            <FormContainer>
              <Input label='Address Line 1' {...getFormProps('addressLine1')} />
              <Input label='Address Line 2' {...getFormProps('addressLine2')} />
              <Row>
                <Input label='Postcode' {...getFormProps('postcode')} />
                <Input label='City' {...getFormProps('city')} />
                <Input label='Country' {...getFormProps('country')} />
              </Row>
            </FormContainer>
          </Tab>
          <Tab label='Contact'>
            <FormContainer>
              <Input label='Email' {...getFormProps('email')} />
              <Input label='Telephone' {...getFormProps('telephone')} />
              <Input label='Website' {...getFormProps('website')} />
              <Input label='Donation Link' {...getFormProps('donationUrl')} />
              <Alert type='info' style={{ marginTop: 15 }}>
                Please specify the full URL in your links e.g.
                https://www.example.com
              </Alert>
            </FormContainer>
          </Tab>
          <Tab label='Logo'>
            <ImageUpload
              placeholder={'Click here to select a new image'}
              multiple={false}
              showBlanks={false}
              onChange={handleLogoChange}
              value={form.logo}
            />
          </Tab>
        </Panel>
        <Actions>
          <Button disabled={saving} type='secondary' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={saving} onClick={handleSave}>
            Save
          </Button>
        </Actions>
      </Content>
    </Modal>
  );
};

export default DetailsModal;
