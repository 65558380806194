import styled from 'styled-components';
import { cloneElement, useState, useRef, useEffect } from 'react';

import { useWidth } from 'hooks';

import Header from './Header';

const Container = styled.div`
  background: white;
  position: relative;
  overflow: hidden;
  height: ${(p) => p.height};
  transition: all 0.3s ease;
  border-radius: 20px;
  ${(p) => p.theme.breakpoints.desktop} {
    display: flex;
    height: auto;
    overflow: unset;
  }
`;

const Options = styled.div`
  padding: 16px;
`;

const Column = styled.div`
  ${(p) => p.theme.breakpoints.desktop} {
    min-width: 33%;
  }
`;

const Controlled = ({
  children,
  activeIndex,
  onChangeIndex,
  title,
  avatar,
  subtitle,
}) => {
  const [containerHeight, setContainerHeight] = useState('auto');
  const [offset, setOffset] = useState(0);
  const [hide, setHide] = useState();
  const { isDesktop } = useWidth();
  const optionsRef = useRef();
  const contentRef = useRef();
  const headerRef = useRef();

  let _activeIndex = activeIndex;
  if (isDesktop) {
    _activeIndex = activeIndex || 0;
  }

  useEffect(() => {
    let height;
    let offset = 0;
    if (contentRef.current) {
      height = contentRef.current.getBoundingClientRect().height;
    } else if (!_activeIndex) {
      height = optionsRef.current.getBoundingClientRect().height;
    }
    if (headerRef.current) {
      offset = headerRef.current.getBoundingClientRect().height;
    }
    setContainerHeight(`${height + offset}px`);
    setOffset(offset);
  }, [_activeIndex]);

  const handleClick = (index) => {
    onChangeIndex(index);
  };

  const handleClose = () => {
    setHide(true);
    setTimeout(() => {
      onChangeIndex();
      setHide(false);
    }, 300);
  };

  const _children = Array.isArray(children) ? children : [children];

  const activeItem = _children[_activeIndex]?.props.children;

  return (
    <Container height={containerHeight}>
      <Column>
        <Header
          title={title}
          avatar={avatar}
          subtitle={subtitle}
          ref={headerRef}
        />
        <Options ref={optionsRef}>
          {_children.map((c, i) =>
            cloneElement(c, {
              key: i,
              isActive: i === _activeIndex,
              onClick: () => handleClick(i),
            })
          )}
        </Options>
      </Column>
      {activeItem &&
        cloneElement(activeItem, {
          onClose: handleClose,
          ref: contentRef,
          minHeight: containerHeight,
          offset,
          hide,
        })}
    </Container>
  );
};

const Uncontrolled = (props) => {
  const [activeIndex, setActiveIndex] = useState();

  return (
    <Controlled
      activeIndex={activeIndex}
      onChangeIndex={setActiveIndex}
      {...props}
    />
  );
};

const Nav = (props) =>
  props.onChangeIndex ? <Controlled {...props} /> : <Uncontrolled {...props} />;

export default Nav;
