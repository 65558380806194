import { useEffect, useState } from 'react';

import Popover from '../Popover';

import {
  ContextMenuOptions,
  ContextMenuOption,
  Container,
  Dots,
} from './styled';

const ContextButton = ({ className, actions, orientation }) => {
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    const handleClick = (e) => {
      if (showPopover) {
        setShowPopover(false);
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [showPopover]);

  const togglePopover = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setShowPopover((s) => !s);
  };

  return (
    <Container
      onClick={togglePopover}
      className={className}
      orientation={orientation}
    >
      {showPopover && <Popover content={actions} />}
      <Dots icon='threeDots' orientation={orientation} />
    </Container>
  );
};

export default ContextButton;
export { ContextMenuOptions, ContextMenuOption };
