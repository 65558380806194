import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const format = (date, _format = 'DD/MM/YYYY', utc = true) => {
  if (utc) {
    return dayjs.utc(date).format(_format).padStart(_format.length, '0');
  } else {
    return dayjs(date).format(_format).padStart(_format.length, '0');
  }
};
