const Check = (props) => {
  return (
    <svg viewBox='0 0 9 7' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1 3.42758L3.374 5.66913L8.12 1.18604'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeinejoin='round'
      />
    </svg>
  );
};

export default Check;
