import { useEffect, useRef, useState } from 'react';

import { Alert, Button, Modal, Input } from 'components/common';
import { usePost } from 'hooks';
import { selectApplication, selectManualApplication } from 'services/api';

import {
  Container,
  SummaryContainer,
  Fields,
  Field,
  Actions,
  Status,
  ActionContainer,
  ManualContainer,
  ManualForm,
  LeftContainer,
  StatusContainer,
} from './styled';

const getApplicantName = (fields) => {
  const nameValue = fields.find(
    (field) => field.label.trim().toLowerCase() === 'name'
  )?.value;

  const firstNameValue = fields.find(
    (field) => field.label.trim().toLowerCase() === 'first name'
  )?.value;

  const lastNameValue = fields.find(
    (field) => field.label.trim().toLowerCase() === 'last name'
  )?.value;

  if (nameValue) {
    return nameValue;
  }

  if (firstNameValue) {
    let name = firstNameValue;
    if (lastNameValue) {
      name = `${firstNameValue} ${lastNameValue}`;
    }
    return name;
  }

  return '-';
};

const ChooseApplicant = ({ applications, onSuccess, petId, ...rest }) => {
  const [selected, setSelected] = useState();
  const [showManualForm, setShowManualForm] = useState(false);
  const [manualForm, setManualForm] = useState({});
  const containerRef = useRef();

  const [{ res, loading }, postSelect, setRes] = usePost({
    url: selectApplication,
  });

  const [{ res: manualRes, loading: loadingManual }, postManual, setManualRes] =
    usePost({
      url: selectManualApplication,
    });

  useEffect(() => {
    if (res || manualRes) {
      onSuccess();
      setRes();
      setManualRes();
    }
  }, [res, onSuccess, setRes, manualRes, setManualRes]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [selected]);

  const handleConfirm = () => {
    postSelect({ application: selected, petId: selected.petId });
  };

  const handleManual = () => {
    setShowManualForm(true);
  };

  const handleConfirmManual = () => {
    postManual({ ...manualForm, petId });
  };

  const handleChange = (key) => (value) => {
    setManualForm((s) => ({ ...s, [key]: value }));
  };

  let content = (
    <Container ref={containerRef}>
      <h3 style={{ textAlign: 'center' }}>Choose Successful Applicant</h3>
      <Alert type='info'>
        Listed below is a summary of all approved and unprocessed applications
        for this pet. Clicking "Select" will open an expanded view of the
        application where you can confirm your selection.
      </Alert>
      {applications.map((app) => (
        <SummaryContainer key={app._id}>
          <LeftContainer>
            <Fields>
              <Field>
                <div>Name</div>
                <div>{getApplicantName(app.fields)}</div>
              </Field>
              <Field>
                <div>Email</div>
                <div>
                  {app.fields.find(
                    (field) =>
                      field.label.trim().toLowerCase() === 'email' ||
                      field.label.trim().toLowerCase() === 'email address'
                  )?.value || '-'}
                </div>
              </Field>
            </Fields>
            <StatusContainer>
              {app.status === 'approved' && (
                <Status type='success'>Approved</Status>
              )}
              {app.type === 'manual' && <Status type='info'>Manual</Status>}
            </StatusContainer>
          </LeftContainer>
          <ActionContainer>
            <Button onClick={() => setSelected(app)}>Select</Button>
          </ActionContainer>
        </SummaryContainer>
      ))}
      <ManualContainer>
        <Button onClick={handleManual}>Manually add details</Button>
      </ManualContainer>
    </Container>
  );

  if (selected) {
    content = (
      <Container ref={containerRef}>
        <h3 style={{ textAlign: 'center' }}>Choose Successful Applicant</h3>
        <Alert type='info'>
          Clicking "Confirm" will mark this pet as adopted, and{' '}
          <b>send an email to all unsuccessful applicants</b> informing them of
          the outcome of their applications.
        </Alert>
        <SummaryContainer>
          <Fields>
            {selected.fields.map((field) => (
              <Field key={field.label}>
                <div>{field.label}</div>
                <div>{field.value}</div>
              </Field>
            ))}
          </Fields>
        </SummaryContainer>
        <Actions>
          <Button
            type='secondary'
            onClick={() => setSelected()}
            disabled={loading}
          >
            Go back
          </Button>
          <Button onClick={handleConfirm} disabled={loading}>
            Confirm
          </Button>
        </Actions>
      </Container>
    );
  }

  const manualIncomplete =
    !manualForm.firstName || !manualForm.lastName || !manualForm.email;

  if (showManualForm) {
    content = (
      <Container ref={containerRef}>
        <h3 style={{ textAlign: 'center' }}>Choose Successful Applicant</h3>
        <Alert type='info'>
          Below you can enter the details of the successful applicant if they
          did not submit an application through our system.
          <br />
          <br />
          Clicking "Confirm" will mark this pet as adopted, and{' '}
          <b>send an email to all unsuccessful applicants</b> informing them of
          the outcome of their applications.
        </Alert>
        <ManualForm>
          <Input
            label='First Name'
            onChange={handleChange('firstName')}
            value={manualForm.firstName}
            required
          />
          <Input
            label='Last Name'
            onChange={handleChange('lastName')}
            value={manualForm.lastName}
            required
          />
          <Input
            label='Email'
            onChange={handleChange('email')}
            value={manualForm.email}
            required
          />
        </ManualForm>
        <Actions>
          <Button
            type='secondary'
            onClick={() => setShowManualForm(false)}
            disabled={loadingManual}
          >
            Go back
          </Button>
          <Button
            onClick={handleConfirmManual}
            disabled={loadingManual || manualIncomplete}
          >
            Confirm
          </Button>
        </Actions>
      </Container>
    );
  }

  return <Modal {...rest}>{content}</Modal>;
};

export default ChooseApplicant;
