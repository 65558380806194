const ApplicationFill = (props) => {
  return (
    <svg viewBox='0 0 14 15' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#applicationFillClip)'>
        <path d='M2.89844 14.5H11.1016C11.7801 14.5 12.332 13.948 12.332 13.2695V4.60156H9.46094C8.78243 4.60156 8.23047 4.0496 8.23047 3.37109V0.5H2.89844C2.21993 0.5 1.66797 1.05196 1.66797 1.73047V13.2695C1.66797 13.948 2.21993 14.5 2.89844 14.5ZM4.53906 6.26953H9.46094C9.68764 6.26953 9.87109 6.45298 9.87109 6.67969C9.87109 6.90639 9.68764 7.08984 9.46094 7.08984H4.53906C4.31236 7.08984 4.12891 6.90639 4.12891 6.67969C4.12891 6.45298 4.31236 6.26953 4.53906 6.26953ZM4.53906 7.91016H9.46094C9.68764 7.91016 9.87109 8.09361 9.87109 8.32031C9.87109 8.54702 9.68764 8.73047 9.46094 8.73047H4.53906C4.31236 8.73047 4.12891 8.54702 4.12891 8.32031C4.12891 8.09361 4.31236 7.91016 4.53906 7.91016ZM4.53906 9.55078H9.46094C9.68764 9.55078 9.87109 9.73423 9.87109 9.96094C9.87109 10.1876 9.68764 10.3711 9.46094 10.3711H4.53906C4.31236 10.3711 4.12891 10.1876 4.12891 9.96094C4.12891 9.73423 4.31236 9.55078 4.53906 9.55078ZM4.53906 11.1914H7.82031C8.04702 11.1914 8.23047 11.3749 8.23047 11.6016C8.23047 11.8283 8.04702 12.0117 7.82031 12.0117H4.53906C4.31236 12.0117 4.12891 11.8283 4.12891 11.6016C4.12891 11.3749 4.31236 11.1914 4.53906 11.1914Z' />
        <path d='M9.46094 3.78116H12.0917L9.05078 0.740234V3.371C9.05078 3.5973 9.23464 3.78116 9.46094 3.78116Z' />
      </g>
      <defs>
        <clipPath id='applicationFillClip'>
          <rect
            width='14'
            height='14'
            fill='white'
            transform='translate(0 0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ApplicationFill;
