const Lock = (props) => {
  return (
    <svg
      viewBox='0 0 15 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.6862 6.87272V5.08355C11.6862 2.98939 9.98783 1.29105 7.89366 1.29105C5.79949 1.28189 4.09449 2.97189 4.08533 5.06689V5.08355V6.87272'
        stroke='#768197'
        strokeWidth='1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0694 16.7076H4.70187C2.95687 16.7076 1.54187 15.2935 1.54187 13.5476V9.97348C1.54187 8.22764 2.95687 6.81348 4.70187 6.81348H11.0694C12.8144 6.81348 14.2294 8.22764 14.2294 9.97348V13.5476C14.2294 15.2935 12.8144 16.7076 11.0694 16.7076Z'
        stroke='#768197'
        strokeWidth='1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.88578 10.835V12.6858'
        stroke='#768197'
        strokeWidth='1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Lock;
