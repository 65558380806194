const Settings = (props) => {
  return (
    <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M21.2618 11.1244C21.191 10.2449 20.9975 9.3893 20.6835 8.56932L22.8464 6.83705L19.3318 2.44852L17.182 4.17001C16.4399 3.67004 15.6372 3.28398 14.7858 3.01782V0.286133H9.16313V3.03563C8.27287 3.32054 7.43742 3.73707 6.66806 4.27918L4.49583 2.59098L1.04536 7.03055L3.25603 8.74829C2.96086 9.57015 2.78513 10.4248 2.73127 11.301L0 11.9546L1.30823 17.4227L3.22978 16.963C4.17633 16.0165 5.03086 15.162 6.1545 14.0383C4.66102 9.98499 7.66345 5.65205 12.004 5.65205C15.4455 5.65205 18.2358 8.44234 18.2358 11.8839C18.2358 16.1345 14.0648 19.1358 10.0431 17.8003C9.72183 18.1216 6.39366 21.4497 5.87663 21.9668L9.58434 23.7145L10.8176 21.0981C11.6694 21.2077 12.5194 21.1988 13.3548 21.077L14.6181 23.6587L19.6687 21.1876L18.4087 18.6124C19.0328 18.0187 19.5679 17.3463 20.0051 16.6046L22.7719 17.2241L24 11.7372L21.2618 11.1244Z' />
      <path
        opacity='0.8'
        d='M15.9981 10.2685L15.6035 9.27613L13.2294 11.6498L12.1629 10.5834L14.5371 8.20968L13.5447 7.81466C9.6603 6.26962 6.06921 10.5193 8.10757 14.0727C2.86389 19.3164 7.34519 14.8356 1.04547 21.1353L2.88269 22.9725C6.54227 19.313 2.9876 22.8681 10.0104 15.8453C13.5954 17.5839 17.4768 13.9836 15.9981 10.2685Z'
      />
    </svg>
  );
};

export default Settings;
