const Menu = (props) => {
  return (
    <svg
      viewBox='0 0 17 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M7.5 1L2 6.5M2 6.5L7.5 12M2 6.5H16.5' strokeWidth='2' />
    </svg>
  );
};

export default Menu;
