import styled from 'styled-components';

import { colours } from 'utils/theme';

const padding = {
  large: '15px 40px',
  small: '10px 22px',
};

const fontSize = {
  large: '16px',
  small: '13px',
};

const mobileRadius = {
  large: '16px',
  small: '12px',
};

const background = {
  primary: colours.primary.main,
  secondary: 'none',
};

const color = {
  primary: 'white',
  secondary: colours.primary.main,
};

const StyledButton = styled.button`
  -webkit-appearance: none;
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  outline: none;
  border: none;
  cursor: pointer;
  background: ${(p) => background[p.type]};
  border-radius: ${(p) => mobileRadius[p.size]};
  border: 1px solid ${(p) => p.theme.colours.primary.main};
  padding: ${(p) => padding[p.size]};
  color: ${(p) => color[p.type]};
  font-family: 'Nunito';
  font-weight: 600;
  font-size: ${(p) => fontSize[p.size]};
  line-height: 18px;
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
  pointer-events: ${(p) => (p.disabled ? 'none' : 'all')};
  ${(p) => p.theme.breakpoints.desktop} {
    transition: 0.3s ease all;
    :hover {
      opacity: 0.9;
    }
  }
`;

const Button = (props) => {
  return <StyledButton {...props} />;
};

Button.defaultProps = {
  fullWidth: false,
  size: 'small',
  type: 'primary',
};

export default Button;
