import { useState, useEffect, useRef } from 'react';

import Options from './Options';
import {
  DesktopSelect as StyledSelect,
  DesktopInput,
  InputContainer,
  DownIcon,
  ClearIcon,
} from '../styled';

const Input = ({
  onToggleExpanded,
  expanded,
  onFilterChange,
  filter = '',
  value = '',
  disabled,
}) => {
  const handleClick = () => {
    onToggleExpanded();
  };

  return (
    <DesktopInput
      disabled={disabled}
      onChange={onFilterChange}
      value={expanded ? filter : value}
      onClick={handleClick}
    />
  );
};

const Select = ({
  onToggleExpanded,
  expanded,
  placeholder,
  value,
  className,
  disabled,
}) => {
  return (
    <StyledSelect
      disabled={disabled}
      onClick={onToggleExpanded}
      expanded={expanded}
      tabIndex='0'
      className={className}
    >
      {value || placeholder}
    </StyledSelect>
  );
};

const DesktopSelect = ({
  placeholder,
  options,
  filterable,
  onFilterChange,
  filter,
  multi,
  onChange,
  value,
  clearable,
  required,
  className,
  disabled,
}) => {
  const [expanded, setExpanded] = useState();
  const [maxOptionsHeight, setMaxOptionsHeight] = useState();
  const controlRef = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (expanded) {
        setExpanded(false);
      }
    };
    window.addEventListener('click', handleClick);
    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [expanded]);

  useEffect(() => {
    if (controlRef) {
      const { bottom } = controlRef.current.getBoundingClientRect();
      const diff = window.scrollMaxY + window.innerHeight - bottom - 30;
      setMaxOptionsHeight(diff);
    }
  }, []);

  const handleToggleExpanded = () => {
    if (disabled) return;
    setExpanded((s) => !s);
  };

  const handleClear = () => {
    if (disabled) return;
    onChange(null);
  };

  const getControlLabel = () => {
    if (multi) {
      if (!value || value.length === 0) return placeholder;
      if (value.length === 1)
        return options.find((o) => o.value === value[0])?.label;
      return `${value.length} selected`;
    }
    return options.find((o) => o.value === value)?.label || placeholder;
  };

  const Control = filterable ? Input : Select;

  const isClearable = clearable || !required;

  return (
    <>
      <InputContainer
        className={className}
        clearable={isClearable}
        ref={controlRef}
        disabled={disabled}
      >
        {isClearable && (
          <ClearIcon
            icon='close'
            className='select-clear-icon'
            onClick={handleClear}
          />
        )}
        <DownIcon
          icon='down'
          className='select-down-icon'
          expanded={expanded}
        />
        <Control
          onToggleExpanded={handleToggleExpanded}
          expanded={expanded}
          placeholder={placeholder}
          onFilterChange={onFilterChange}
          filter={filter}
          value={getControlLabel()}
          className='select-control'
          disabled={disabled}
        />
      </InputContainer>
      {expanded && (
        <Options
          multi={multi}
          value={value}
          onChange={onChange}
          options={options}
          filterable={filterable}
          maxHeight={maxOptionsHeight}
        />
      )}
    </>
  );
};

DesktopSelect.defaultProps = {
  onFilterChange: () => {},
};

export default DesktopSelect;
