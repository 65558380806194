import styled, { keyframes } from 'styled-components';
import { forwardRef } from 'react';

import { Icon } from 'components/common';

const entryAnimation = keyframes`
  from { 
    opacity: 0;
    transform: translateX(100%); 
  }
  to { 
    opacity: 1;
    transform: translateX(0%);
  }
`;

const exitAnimation = keyframes`
  from { 
    opacity: 1;
    transform: translateX(0%);
  }
  to { 
    opacity: 0;
    transform: translateX(100%);
  }
`;

const Container = styled.div`
  position: absolute;
  width: 100%;
  min-height: calc(${(p) => p.minHeight} - ${(p) => p.offset}px);
  background: white;
  top: 0px;
  animation: ${(p) => (p.hide ? exitAnimation : entryAnimation)} 0.3s ease;
  padding: 20px 30px;
  box-sizing: border-box;
  margin-top: ${(p) => p.offset}px;
  ${(p) => p.theme.breakpoints.desktop} {
    position: relative;
    animation: none;
    margin-top: 0px;
    min-height: auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 25px;
  margin-top: 10px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  h6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
`;

const BackIcon = styled(Icon)`
  stroke: ${(p) => p.theme.colours.primary.dark2};
  width: 16px;
  padding-right: 10px;
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

const Panel = (
  { children, onClose, hide, minHeight, title, offset, actions },
  ref
) => {
  return (
    <Container hide={hide} ref={ref} minHeight={minHeight} offset={offset}>
      <TitleContainer>
        <Title onClick={onClose}>
          <BackIcon icon='back' />
          <h6>{title}</h6>
        </Title>
        {actions}
      </TitleContainer>
      {children}
    </Container>
  );
};

export default forwardRef(Panel);
