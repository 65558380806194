import { Select } from 'components/common';

import { SectionTitle, Row } from './styled';

const HomeRequirements = ({ getFormProps, options }) => {
  return (
    <>
      <SectionTitle>Home Requirements</SectionTitle>
      <Row>
        <Select
          label='Minimum age of children'
          options={options.minimumKidAge}
          {...getFormProps('minimumKidAge')}
        />
        <Select
          label='Can live with dogs'
          options={options.liveWithDogs}
          {...getFormProps('liveWithDogs')}
        />
        <Select
          label='Can live with cats'
          options={options.liveWithCats}
          {...getFormProps('liveWithCats')}
        />
      </Row>
      <Row>
        <Select
          label='Must have someone home a lot'
          options={options.needsSomeoneHome}
          {...getFormProps('needsSomeoneHome')}
        />
        <Select
          label='Experienced home required'
          options={options.experiencedHome}
          {...getFormProps('experiencedHome')}
        />
        <Select
          label='Extra secure garden required'
          options={options.secureGarden}
          {...getFormProps('secureGarden')}
        />
      </Row>
    </>
  );
};

export default HomeRequirements;
