import { Input, Select, CharitySelect } from 'components/common';
import { useAuth } from 'hooks';

import { SectionTitle, Row } from './styled';

const Details = ({ getFormProps, options, details }) => {
  const { user } = useAuth();

  return (
    <>
      <SectionTitle>Basic Details</SectionTitle>
      {user.isPawAdmin && <CharitySelect {...getFormProps('charityId')} />}
      <Row>
        <Input label='Name' {...getFormProps('name')} />
        <Select
          label='Species'
          options={options.species}
          {...getFormProps('species')}
        />
        <Select
          label='Status'
          options={options.status}
          {...getFormProps('status')}
        />
        <Input label='Location (Town/City)' {...getFormProps('location')} />
      </Row>
      <Row>
        <Input
          label='Arrived Date'
          type='date'
          {...getFormProps('arrivedDate')}
        />
        <Select
          label='Arrived From'
          options={options.arrivedFrom}
          {...getFormProps('arrivedFrom')}
        />
        <Input
          label='Arrived From (Other)'
          {...getFormProps('arrivedFromOther')}
        />
      </Row>
      <Row>
        <Select label='Sex' options={options.sex} {...getFormProps('sex')} />
        <Select
          label='Main Colour(s)'
          options={options.colour}
          {...getFormProps('colour')}
          multi
        />
        {details.species === 'Dog' && (
          <Select
            label='Size (when adult)'
            options={options.size}
            {...getFormProps('size')}
          />
        )}
        <Input
          label='Date of Birth'
          type='date'
          {...getFormProps('dateOfBirth')}
        />
      </Row>
      {details.species === 'Dog' && (
        <Row>
          <Select
            label='Dominant Breed'
            options={options.breeds}
            {...getFormProps('dominantBreed')}
            filterable
          />
          <Select
            label='Cross Breed'
            options={options.breeds}
            {...getFormProps('crossBreed')}
            filterable
          />
        </Row>
      )}
      <Row>
        <Select
          label='Fosterer'
          options={options.fosterers}
          {...getFormProps('fostererId')}
        />
        <Input label='Chip Number' {...getFormProps('chipNumber')} />
        <Input label='Chip Company' {...getFormProps('chipCompany')} />
      </Row>
    </>
  );
};

export default Details;
