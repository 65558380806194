import styled from 'styled-components';

import Icon from '../Icon';

const sizes = {
  small: '14px',
  large: '28px',
};

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  user-select: none;
  flex: 1;
  cursor: pointer;
`;

export const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const Indicator = styled.div`
  height: ${(p) => sizes[p.size]};
  width: ${(p) => sizes[p.size]};
  min-width: ${(p) => sizes[p.size]};
  border-radius: 14px;
  border-radius: 3px;
  background: ${(p) =>
    p.checked ? p.theme.colours.secondary.two : p.theme.colours.shade.six};
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    transition: 0.3s ease all;
    opacity: ${(p) => (p.checked ? 1 : 0)};
  }
`;

export const CheckIcon = styled(Icon)`
  width: 10px;
  stroke: white;
  fill: none;
`;
