import { Input, Checkbox } from 'components/common';

import { FormContainer, SectionTitle, Row } from './styled';

const requiredFields = ['name', 'email'];

const validateEmail = (value) => value.includes('@') && value.includes('.');

const Form = ({ details, onChange }) => {
  const getFormProps = (key) => ({
    value: details[key],
    onChange: onChange(key),
    required: requiredFields.includes(key),
  });

  return (
    <FormContainer>
      <SectionTitle>Details</SectionTitle>
      <Row>
        <Input label='Name' {...getFormProps('name')} />
      </Row>
      <SectionTitle>Address</SectionTitle>
      <Row>
        <Input label='Address Line 1' {...getFormProps('addressLine1')} />
      </Row>
      <Row>
        <Input label='Address Line 2' {...getFormProps('addressLine2')} />
      </Row>
      <Row>
        <Input label='Postcode' {...getFormProps('postcode')} />
        <Input label='City' {...getFormProps('city')} />
        <Input label='Country' {...getFormProps('country')} />
      </Row>
      <SectionTitle>Contact</SectionTitle>
      <Row>
        <Input
          label='Email'
          validate={validateEmail}
          {...getFormProps('email')}
        />
        <Input label='Telephone' {...getFormProps('telephone')} />
      </Row>
      <SectionTitle>Roles</SectionTitle>
      <Row>
        <Checkbox
          label='Fosterer'
          size='large'
          {...getFormProps('isFosterer')}
        />
        <Checkbox
          label='User'
          size='large'
          {...getFormProps('isCharityUser')}
        />
        <Checkbox
          label='Admin'
          size='large'
          {...getFormProps('isCharityAdmin')}
        />
      </Row>
    </FormContainer>
  );
};

export default Form;
