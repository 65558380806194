const AddUser = (props) => {
  return (
    <svg
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.125 9.41526C9.19093 9.41526 10.847 7.73779 10.847 5.64513C10.847 3.55247 9.19093 1.875 7.125 1.875C5.05908 1.875 3.40303 3.55247 3.40303 5.64513C3.40303 7.73779 5.05908 9.41526 7.125 9.41526ZM7.125 11.2614C4.09067 11.2614 1.5 11.7466 1.5 13.6848C1.5 15.6224 4.0749 16.125 7.125 16.125C10.1586 16.125 12.75 15.6399 12.75 13.7016C12.75 11.7633 10.1751 11.2614 7.125 11.2614ZM14.9235 7.1909H15.8258C16.1972 7.1909 16.5 7.49798 16.5 7.87461C16.5 8.25124 16.1972 8.55832 15.8258 8.55832H14.9235V9.44129C14.9235 9.81792 14.6214 10.125 14.2492 10.125C13.8778 10.125 13.575 9.81792 13.575 9.44129V8.55832H12.6742C12.3021 8.55832 12 8.25124 12 7.87461C12 7.49798 12.3021 7.1909 12.6742 7.1909H13.575V6.30871C13.575 5.93208 13.8778 5.625 14.2492 5.625C14.6214 5.625 14.9235 5.93208 14.9235 6.30871V7.1909Z'
      />
    </svg>
  );
};

export default AddUser;
