import styled from 'styled-components';

import {
  Button as CommonButton,
  ContextMenu as CommonContextMenu,
  Icon,
} from 'components/common';

export const Pane = styled.div`
  width: 100%;
  box-sizing: border-box;
  flex: 1;
  background: white;
  border-radius: 16px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    flex: 1 0;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.dark5}33;
  padding-bottom: 15px;
  align-items: center;
  h6 {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const Upload = styled.div`
  font-size: 13px;
  color: ${(p) => p.theme.colours.primary.dark4};
  font-weight: 500;
  display: flex;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    border-radius: 12px;
    padding: 8px 16px;
    border: 1px solid ${(p) => p.theme.colours.primary.dark4};
    background: white;
    color: ${(p) => p.theme.colours.primary.dark4};
    cursor: pointer;
  }
`;

export const UploadIcon = styled(Icon)`
  width: 14px;
  fill: ${(p) => p.theme.colours.primary.dark4};
  margin-right: 5px;
`;

export const NoImages = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding: 70px 0px;
  color: ${(p) => p.theme.colours.primary.dark2};
`;

export const Button = styled(CommonButton)`
  margin-top: 20px;
`;

export const SavedImages = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  width: 100%;
  margin-top: 12px;
  justify-items: center;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  > img {
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
    max-height: 180px;
  }
`;

export const ContextMenu = styled(CommonContextMenu)`
  position: absolute;
  top: 10px;
  right: 10px;
`;
