export const getNextPosition = (
  currentPosition,
  movement,
  target,
  boundary
) => {
  const { movementX, movementY } = movement;
  const { top, left, width, height } = currentPosition;

  let _top = top;
  let _left = left;
  let _width = width;
  let _height = height;

  if (target === 'base') {
    _top += movementY;
    _left += movementX;
  }
  if (target === 'nw') {
    _top += (movementY * 3) / 4;
    _left += movementY;
    _width -= movementY;
  }
  if (target === 'ne') {
    _top += (movementY * 3) / 4;
    _width -= movementY;
  }
  if (target === 'se') {
    _width += movementY;
  }
  if (target === 'sw') {
    _left -= movementY;
    _width += movementY;
  }
  _height = (_width * 3) / 4;

  if (_top < 0 || _top + _height > boundary.height) {
    _top = top;
    _width = width;
    _height = height;
  }

  if (_left < 0 || _left + _width > boundary.width) {
    _left = left;
    _width = width;
    _height = height;
  }

  return {
    top: _top,
    left: _left,
    width: _width,
    height: _height,
  };
};
