import styled, { css } from 'styled-components';

const containerPositions = {
  bottom: css`
    top: 45px;
  `,
  left: css`
    right: calc(100% + 15px);
  `,
};

const pointerPositions = {
  bottom: css`
    top: -7px;
  `,
  left: css`
    right: -7px;
  `,
};

export const PopoverContainer = styled.div`
  position: absolute;
  filter: drop-shadow(3px 10px 30px rgba(0, 0, 0, 0.16));
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  animation-name: popover-container-entry;
  animation-duration: 0.2s;
  ${(p) => containerPositions[p.position]};

  @keyframes popover-container-entry {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;

export const Content = styled.div`
  background: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  z-index: 2;
`;

export const Point = styled.div`
  position: absolute;
  height: 14px;
  width: 14px;
  transform: rotate(45deg);
  background: white;
  border-radius: 0px 0px 3px 0px;
  ${(p) => pointerPositions[p.position]};
`;
