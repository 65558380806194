import styled from 'styled-components';

import { Icon } from 'components/common';

export const HiddenInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

export const UploadArea = styled.label`
  border: 1px dashed #d5d1e0;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  cursor: pointer;
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
  }
`;

export const UploadIcon = styled(Icon)`
  width: 60px;
  margin-bottom: 35px;
`;

export const ImagesContainer = styled.div`
  display: grid;
  ${(p) =>
    p.multiple
      ? 'grid-template-columns: repeat(3, 1fr)'
      : 'justify-content: center'};
  gap: 12px;
  width: 100%;
  overflow: auto;
  margin-top: 12px;
  ${(p) => p.theme.breakpoints.desktop} {
    gap: 20px;
    margin-top: 20px;
  }
`;

export const Placeholder = styled.div`
  flex: 1;
  height: 55px;
  border: 1px dashed #d5d1e0;
  border-radius: 6px;
  margin-left: 16px;
  margin-top: 20px;
  :first-child {
    margin-left: 0px;
  }
`;

export const UploadedImageContainer = styled.div`
  border: 1px dashed #d5d1e0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 100px;
  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    height: 120px;
  }
`;

export const DeleteImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease all;
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const DeleteIcon = styled(Icon)`
  fill: white;
  width: 50px;
`;
