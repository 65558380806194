import styled from 'styled-components';
import { useEffect, useState } from 'react';

import { useNotification } from 'hooks';
import { Application } from 'components/common';

import { Container, Header, Select, Button } from './styled';
import ChooseApplicant from 'components/ChooseApplicant';

import { getChooseApplicantApplications } from '../helpers';

const filterOptions = [
  { label: 'Maybe', value: 'approved' },
  { label: 'No', value: 'rejected' },
  { label: 'Unprocessed', value: 'unprocessed' },
];

const Badge = styled.div`
  color: white;
  background: ${(p) => p.theme.colours.shade.five};
  color: ${(p) => p.theme.colours.secondary.four};
  border: 1px solid ${(p) => p.theme.colours.secondary.four};
  border-radius: 10px;
  font-size: 11px;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 6px;
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 12px;
  }
`;

const SummaryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h6 {
    margin-right: 10px;
  }
`;

const Applications = ({ pet, onFetch }) => {
  const [filter, setFilter] = useState(['approved', 'unprocessed']);
  const [applications, setApplications] = useState(pet?.applications);
  const [showChooseApplicant, setShowChooseApplicant] = useState(false);
  const { notify } = useNotification();

  useEffect(() => {
    if (pet && !applications) {
      setApplications(pet.applications);
    }
  }, [pet, applications]);

  const handleUpdateApplicationStatus =
    (id) =>
    ({ status, notes }) => {
      const found = applications.find((app) => app._id === id);
      const without = applications.filter((app) => app._id !== id);

      setApplications([...without, { ...found, status, notes }]);
    };

  const chooseApplicantApplications =
    getChooseApplicantApplications(applications);

  let _applications = filter?.length ? [] : applications;
  if (filter && filter.length && applications) {
    const sorted = [...applications];
    sorted.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    if (filter.includes('unprocessed')) {
      const unprocessed = sorted.filter((app) => !app.status);
      _applications.push(...unprocessed);
    }
    if (filter.includes('approved')) {
      const approved = sorted.filter((app) => app.status === 'approved');
      _applications.push(...approved);
    }
    if (filter.includes('rejected')) {
      const rejected = sorted.filter((app) => app.status === 'rejected');
      _applications.push(...rejected);
    }
    if (pet?.successfulApplication) {
      _applications = _applications.filter(
        (app) => app._id !== pet?.successfulApplication
      );
      _applications.unshift(
        applications.find((app) => app._id === pet?.successfulApplication)
      );
    }
  }

  const handleSuccess = () => {
    onFetch();
    setShowChooseApplicant(false);
    notify('Email sent to unsuccessful applicants!');
  };

  const unprocessedCount = applications?.filter((app) => !app.status).length;
  const appStatuses = ['Adopted', 'Rainbow Bridge'];
  const showUnprocessed = !appStatuses.includes(pet?.status);

  return (
    <>
      {chooseApplicantApplications.length > 0 &&
        !pet?.successfulApplication && (
          <Button onClick={() => setShowChooseApplicant(true)}>
            Choose successful applicant
          </Button>
        )}
      <Container>
        <Header>
          <SummaryContainer>
            <h6>{applications?.length} Applications</h6>
            {unprocessedCount > 0 && showUnprocessed && (
              <Badge>{unprocessedCount} Unprocessed</Badge>
            )}
          </SummaryContainer>
          <Select
            label='Filter Applications'
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            multi
          />
        </Header>
        {_applications?.map((a, i) => (
          <Application
            {...a}
            key={a._id}
            isSuccessful={a._id === pet?.successfulApplication}
            onUpdateApplicationStatus={handleUpdateApplicationStatus(a._id)}
            showUnprocessed={showUnprocessed}
          />
        ))}
      </Container>
      <ChooseApplicant
        show={showChooseApplicant}
        applications={chooseApplicantApplications}
        onClose={() => setShowChooseApplicant(false)}
        onSuccess={handleSuccess}
      />
    </>
  );
};

export default Applications;
