import { useEffect, useRef, useState } from 'react';

import { format } from 'utils/date';
import { application } from 'services/api';
import { usePost } from 'hooks';

import {
  ApplicationContainer,
  FieldsContainer,
  Fields,
  Field,
  Label,
  Value,
  Expand,
  IconContainer,
  ExpandIcon,
  Details,
  Calendar,
  SubmittedDate,
  Actions,
  ActionIconContainer,
  ActionIcon,
  RightActions,
  Alert,
  SaveIconContainer,
  SaveIcon,
} from './styled';
import Input from '../Input';

const Application = ({
  fields,
  createdAt,
  _id,
  status,
  onUpdateApplicationStatus,
  isSuccessful,
  type,
  showUnprocessed,
  notes: _notes,
}) => {
  const fieldsRef = useRef();
  const [expanded, setExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState(150);
  const [notes, setNotes] = useState(_notes);
  const [{ res, loading }, postApplication, setRes] = usePost({
    url: application,
  });

  useEffect(() => {
    if (expanded) {
      const { height } = fieldsRef.current.getBoundingClientRect();
      setContainerHeight(height + 50);
    } else {
      setContainerHeight(150);
    }
  }, [expanded]);

  useEffect(() => {
    if (res) {
      onUpdateApplicationStatus(res);
      setRes();
    }
  }, [res, onUpdateApplicationStatus, setRes]);

  const toggleExpand = () => setExpanded((s) => !s);

  const handleStatusChange = (newStatus) => {
    if (newStatus !== status) {
      postApplication({ id: _id, status: newStatus });
    }
  };

  const handleSaveNotes = () => {
    if (notes !== _notes) {
      postApplication({ id: _id, notes });
    }
  };

  return (
    <ApplicationContainer>
      <FieldsContainer onClick={toggleExpand} height={containerHeight}>
        <Fields ref={fieldsRef}>
          {fields.map(({ label, value }, i) => (
            <Field key={i}>
              <Label>{label}</Label>
              <Value>
                {value === true
                  ? 'Yes'
                  : Array.isArray(value)
                  ? value.join(', ')
                  : value}
              </Value>
            </Field>
          ))}
        </Fields>
        <Expand>
          <IconContainer>
            <ExpandIcon icon='chevron' expanded={expanded} />
          </IconContainer>
        </Expand>
      </FieldsContainer>
      <RightActions>
        {isSuccessful && <Alert type='success'>Successful Application</Alert>}
        {type === 'manual' && <Alert type='info'>Manual</Alert>}
        {showUnprocessed && !status && (
          <Alert type='warning'>Unprocessed</Alert>
        )}
        <Input
          label='Notes'
          style={{ marginBottom: 15 }}
          value={notes}
          onChange={setNotes}
          suffix={
            <SaveIconContainer onClick={handleSaveNotes}>
              <SaveIcon icon='save' />
            </SaveIconContainer>
          }
        />
        <Details>
          <SubmittedDate>
            <Calendar icon='calendar' />
            {format(createdAt, 'DD/MM/YYYY', false)} @{' '}
            {format(createdAt, 'HH:mm', false)}
          </SubmittedDate>
          <Actions disabled={loading}>
            <ActionIconContainer
              isActive={status === 'approved'}
              onClick={() => handleStatusChange('approved')}
            >
              <ActionIcon icon='thumb' isActive={status === 'approved'} />
            </ActionIconContainer>
            <ActionIconContainer
              no
              isActive={status === 'rejected'}
              onClick={() => handleStatusChange('rejected')}
            >
              <ActionIcon icon='thumb' no isActive={status === 'rejected'} />
            </ActionIconContainer>
          </Actions>
        </Details>
      </RightActions>
    </ApplicationContainer>
  );
};

export default Application;
