import styled from 'styled-components';

const StyledLink = styled.span`
  border-bottom: 1px solid ${(p) => p.theme.colours.primary.main};
  color: ${(p) => p.theme.colours.primary.main};
  font-weight: 600;
  opacity: ${(p) => (p.disabled ? 0.6 : 1)};
`;

const Link = ({ children, onClick, ...rest }) => {
  return (
    <StyledLink onClick={onClick} {...rest}>
      {children}
    </StyledLink>
  );
};

export default Link;
