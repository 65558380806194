import styled from 'styled-components';

import { Button as CommonButton } from 'components/common';

const ANIMATION_TIME = '0.3s';

export const Container = styled.div`
  @keyframes prompt-container-entry {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }
  }

  @keyframes prompt-container-exit {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }
  }

  animation-duration: ${ANIMATION_TIME};
  animation-fill-mode: forwards;
  animation-name: ${(p) => p.transition};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 10;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 0;
  }
`;

export const Content = styled.div`
  @keyframes prompt-content-entry {
    from {
      bottom: -75vw;
      opacity: 0;
    }

    to {
      bottom: 0vw;
      opacity: 1;
    }
  }

  @keyframes prompt-content-exit {
    from {
      bottom: 0vw;
      opacity: 1;
    }

    to {
      bottom: -75vw;
      opacity: 0;
    }
  }

  animation-duration: ${ANIMATION_TIME};
  animation-name: ${(p) => p.transition};
  border-radius: 8px;
  background: white;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: auto;
    margin-top: -40px;
  }
`;

export const Title = styled.h3`
  text-align: left;
  width: 100%;
`;

export const Message = styled.div`
  width: 100%;
  text-align: left;
`;

export const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const Button = styled(CommonButton)`
  margin-left: 15px;
  margin-top: 15px;
`;
