import { useAuth } from 'hooks';
import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
} from 'react-router';

import { NavContainer, OptionContainer, NavIcon, ComingSoon } from './styled';

const NavOption = ({ route, icon, children, disabled, comingSoon, badge }) => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { pathname } = useLocation();

  const isActive = pathname.replace(url, '') === route;

  const handleClick = () => {
    const destination = `${url}${route}`;
    if (destination !== pathname) {
      push(destination);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <OptionContainer
        active={isActive}
        disabled={disabled}
        onClick={handleClick}
      >
        <NavIcon icon={icon} active={isActive} />
        {children}
      </OptionContainer>
      {comingSoon && <ComingSoon>Coming soon</ComingSoon>}
    </div>
  );
};

const Nav = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const isNew = !id;

  const canSeeApplications =
    user.isPawAdmin || user.isCharityAdmin || user.isCharityUser;

  return (
    <NavContainer>
      <NavOption route='' icon='dog'>
        Details
      </NavOption>
      <NavOption route='/images' icon='image' disabled={isNew}>
        Images
      </NavOption>
      {canSeeApplications && (
        <NavOption
          route='/applications'
          icon='applicationFill'
          disabled={isNew}
        >
          Applications
        </NavOption>
      )}
      <NavOption route='messages' icon='messageFill' disabled comingSoon>
        Messages
      </NavOption>
    </NavContainer>
  );
};

export default Nav;
