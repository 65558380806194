import styled from 'styled-components';

import Icon from '../Icon';

const ANIMATION_TIME = '0.3s';

export const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  animation-duration: ${ANIMATION_TIME};
  animation-fill-mode: forwards;
  animation-name: ${(p) => p.transition};
  z-index: 10;

  ${(p) => p.theme.breakpoints.desktop} {
    position: fixed;
    width: 275px;
    animation-name: none;
  }

  @keyframes menu-container-entry {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }
  }

  @keyframes menu-container-exit {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }
  }
`;

export const Content = styled.div`
  width: 75vw;
  min-height: 100%;
  background: ${(p) => p.theme.colours.primary.main};
  position: absolute;
  animation-duration: ${ANIMATION_TIME};
  animation-name: ${(p) => p.transition};
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.desktop} {
    width: 100%;
    animation-name: none;
  }

  @keyframes menu-content-entry {
    from {
      left: -75vw;
    }

    to {
      left: 0vw;
    }
  }

  @keyframes menu-content-exit {
    from {
      left: 0vw;
    }

    to {
      left: -75vw;
    }
  }
`;

export const TopIcons = styled.div`
  height: 74px;
  border-bottom: 1px solid #c4c4c41a;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => p.theme.breakpoints.desktop} {
    justify-content: center;
    padding: 30px 0;
  }
`;

export const PawLogo = styled(Icon)`
  fill: white;
  width: 45px;
  margin-left: 40px;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-left: 0;
    width: 80px;
  }
`;

export const CharityLogo = styled.img`
  margin-left: 40px;
  max-height: 60px;
  ${(p) => p.theme.breakpoints.desktop} {
    margin-left: 0;
    max-height: 110px;
  }
`;

export const Back = styled(Icon)`
  width: 17px;
  padding: 26px;
  stroke: white;
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 14px;
  padding: 15px 20px;
  border-radius: 12px;
  background: ${(p) => (p.active ? 'rgba(255,255,255,0.1)' : 'none')};
  margin: 15px 20px;
  opacity: ${(p) => (p.active ? 1 : 0.7)};
  cursor: pointer;
  transition: 0.3s all ease;
  user-select: none;
  ${(p) => p.theme.breakpoints.desktop} {
    font-size: 16px;
    :hover {
      background: rgba(255, 255, 255, 0.1);
      opacity: 1;
    }
  }
`;

export const NavIcon = styled(Icon)`
  fill: white;
  width: 24px;
  margin-right: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    width: 28px;
  }
`;

export const Items = styled.div`
  flex: 1;
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TopItems = styled.div``;

export const BottomItems = styled.div`
  a {
    text-decoration: none;
  }
  padding-bottom: 100px;
  ${(p) => p.theme.breakpoints.desktop} {
    padding-bottom: 15px;
  }
`;
