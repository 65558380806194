import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { Page, Button } from 'components/common';
import { useGet, usePost, useDel } from 'hooks';
import { user } from 'services/api';

import Form from './Form';
import { Container, Header, Back, BinIcon, Delete, Actions } from './styled';

const User = () => {
  const { id } = useParams();
  const { push, goBack } = useHistory();
  const [details, setDetails] = useState({});

  const [{ res: userInfo, loading }] = useGet({
    url: `${user}/${id}`,
    onMount: !!id,
  });

  const [{ res }, saveUser] = usePost({ url: user });

  const [{ res: deleteRes }, deleteUser] = useDel({ url: `${user}/${id}` });

  useEffect(() => {
    if (res || deleteRes) {
      push('/users');
    }
  }, [res, deleteRes, push]);

  useEffect(() => {
    if (userInfo) {
      setDetails(userInfo);
    }
  }, [userInfo]);

  const handleSave = () => {
    const { charityId } = qs.parse(window.location.search);
    saveUser(charityId ? { ...details, charityId } : details);
  };

  const handleChange = (key) => (value) =>
    setDetails((s) => ({ ...s, [key]: value }));

  const getPageTitle = () => {
    if (id && !userInfo) return '-';
    if (userInfo) return userInfo.name;
    return 'New User';
  };

  const hasInvalidData =
    details.email &&
    (!details.email.includes('@') || !details.email.includes('.'));

  const disableSave =
    ['name', 'email'].some((f) => !details[f]) || hasInvalidData;

  return (
    <Page>
      <Container>
        <Header>
          <Back onClick={goBack} icon='back' />
          <h5>{getPageTitle()}</h5>
          {id && (
            <Delete onClick={deleteUser}>
              <BinIcon icon='bin' />
              <span>Delete</span>
            </Delete>
          )}
        </Header>
        <Form details={details} onChange={handleChange} loading={loading} />
        <Actions>
          <Button type='secondary' onClick={goBack}>
            Cancel
          </Button>
          <Button onClick={handleSave} disabled={disableSave}>
            Save
          </Button>
        </Actions>
      </Container>
    </Page>
  );
};

export default User;
