import styled from 'styled-components';
import { useState } from 'react';

import { Crop, Alert, Button } from 'components/common';

const ImagesContainer = styled.div`
  display: grid;
  ${(p) =>
    p.multiple
      ? 'grid-template-columns: repeat(3, 1fr)'
      : 'justify-content: center'};
  gap: 12px;
  width: 100%;
  overflow: auto;
  margin-top: 12px;
  ${(p) => p.theme.breakpoints.desktop} {
    gap: 20px;
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  border: 1px dashed #d5d1e0;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  height: 100px;
  cursor: pointer;
  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    height: 120px;
  }
`;

const Image = ({ src, name, onClick }) => {
  return (
    <ImageContainer onClick={() => onClick(src, name)}>
      <img src={src} alt={name} />
    </ImageContainer>
  );
};

const ImageSelector = ({
  images,
  onChangeCrop,
  onChangeDetails,
  onChangeCropper,
}) => {
  const [activeImage, setActiveImage] = useState();

  const handleClickImage = (src, name) => {
    setActiveImage(src);
    onChangeDetails((s) => ({ ...s, cropImageName: name }));
  };

  if (images.length === 1) {
    return (
      <div style={{ height: '85%' }}>
        <Crop src={images[0].src} onChange={onChangeCrop} />
        <Button onClick={() => onChangeCropper(false)}>Set Face Pic</Button>
      </div>
    );
  }

  if (activeImage)
    return (
      <div style={{ height: '85%' }}>
        <Crop src={activeImage} onChange={onChangeCrop} />
        <Button onClick={() => onChangeCropper(false)}>Set Face Pic</Button>
      </div>
    );

  return (
    <div>
      <Alert type='info'>
        Select a base image for the face photo - we recommend choosing a clear,
        forward-facing photo of the pet - you can crop it in the next step.
      </Alert>
      <ImagesContainer multiple>
        {images.map((image, i) => (
          <Image {...image} key={i} onClick={handleClickImage} />
        ))}
      </ImagesContainer>
    </div>
  );
};

export default ImageSelector;
