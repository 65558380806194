import { useState } from 'react';

import BaseModal from './BaseModal';

const AddModal = ({ onSave, ...rest }) => {
  const [field, setField] = useState({ type: 'text' });

  const handleChangeField = (key, value) => {
    setField((s) => ({ ...s, [key]: value }));
  };

  return (
    <BaseModal
      {...rest}
      title='Add Application Field'
      field={field}
      onChangeField={handleChangeField}
      onSave={() => onSave(field)}
    />
  );
};

export default AddModal;
