import styled from 'styled-components';

import { Icon, Input } from 'components/common';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f3f5f9;
  min-height: 100vh;
  height: 100%;
  ${(p) => p.theme.breakpoints.desktop} {
    flex-direction: row;
    margin-left: 275px;
  }
`;

export const Content = styled.div`
  ${(p) => p.theme.breakpoints.desktop} {
    flex: 1;
  }
`;

export const Logo = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.main};
  width: 45px;
`;

export const MenuIcon = styled(Icon)`
  fill: ${(p) => p.theme.colours.primary.main};
  display: block;
  width: 30px;
  padding: 0px 16px;
`;

export const SearchIcon = styled(Icon)`
  width: 20px;
  padding: 0 16px;
  stroke: ${(p) => p.theme.colours.primary.dark3};
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AvatarInfo = styled.div`
  display: none;
  ${(p) => p.theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
  }
`;

export const AvatarName = styled.h5``;

export const AvatarRole = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.colours.primary.dark4};
  text-align: right;
`;

export const AvatarImage = styled.div`
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background ${(p) => p.theme.colours.primary.main};
  margin: 0px 16px;
  ${(p) => p.theme.breakpoints.desktop} {
    margin: 0px 16px;
  }
`;

export const AvatarChevron = styled(Icon)`
  display: none;
  fill: #a0a7a9;
  width: 10px;
  margin-right: 25px;
  ${(p) => p.theme.breakpoints.desktop} {
    display: block;
  }
`;

export const TopBar = styled.div`
  height: 74px;
  display: flex;
  justify-content: space-between;
  background: white;
`;

export const LeftIcons = styled.div`
  display: flex;
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;

export const RightIcons = styled.div`
  display: flex;
  align-items: center;
  ${(p) => p.theme.breakpoints.desktop} {
    flex: 1;
    justify-content: space-between;
  }
`;

export const SearchInput = styled(Input)`
  display: none;
  ${(p) => p.theme.breakpoints.desktop} {
    display: flex;
    margin-left: 25px;
    width: auto;
    input {
      width: 700px;
      border-radius: 25px;
      font-size: 14px;
    }
  }
`;

export const MobileSearchInput = styled(Input)`
  display: flex;
  margin-left: 25px;
  width: auto;
  input {
    border-radius: 25px;
    font-size: 14px;
  }
  ${(p) => p.theme.breakpoints.desktop} {
    display: none;
  }
`;
