import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { usePost, useAuth } from 'hooks';
import { login } from 'services/api';

import {
  FormContainer,
  Logo,
  H2,
  H5,
  Form,
  Button,
  Input,
  Alert,
} from './styled';

const Login = () => {
  const [form, setForm] = useState({ email: '', password: '' });
  const [{ res, loading, error }, postLogin] = usePost({ url: login });
  const { setToken, user } = useAuth();
  const { push } = useHistory();

  useEffect(() => {
    if (res) {
      setToken(res.token);
    }
  }, [res, setToken]);

  if (user) {
    return <Redirect to='/' />;
  }

  const handleChange = (key) => (value) =>
    setForm((s) => ({ ...s, [key]: value }));

  const handleReset = () => push('/resetPassword');

  const handleSignup = () => push('/signup');

  const handleSubmit = (e) => {
    e.preventDefault();
    postLogin(form);
  };

  return (
    <FormContainer>
      <Logo icon='logo' />
      <H2>Welcome</H2>
      <H5>Log in to your account</H5>
      <Form onSubmit={handleSubmit}>
        <Input
          disabled={loading}
          icon='email'
          placeholder='Email'
          type='email'
          value={form.email}
          onChange={handleChange('email')}
        />
        <Input
          disabled={loading}
          icon='lock'
          placeholder='Password'
          type='password'
          value={form.password}
          onChange={handleChange('password')}
        />
        <Button
          disabled={loading}
          fullWidth
          size='large'
          onClick={handleSubmit}
        >
          Log In
        </Button>
        <Button
          disabled={loading}
          type='secondary'
          fullWidth
          size='large'
          onClick={handleReset}
        >
          Reset Password
        </Button>
        <Button
          disabled={loading}
          type='secondary'
          fullWidth
          size='large'
          onClick={handleSignup}
        >
          New Rescue Signup
        </Button>
      </Form>
      {error && (
        <Alert type='error'>
          Unable to log in, are your credentials correct?
        </Alert>
      )}
    </FormContainer>
  );
};

export default Login;
