import { Input, Select, ListInput } from 'components/common';

import { Row, SectionTitle } from './styled';

const Health = ({ options, getFormProps }) => {
  const medicalInputs = [
    { label: 'Date', key: 'date', type: 'date' },
    { label: 'Veterinary Practice', key: 'vet' },
    {
      label: 'Type of Appointment',
      key: 'type',
      type: 'select',
      options: options.appointmentTypes,
    },
    { label: 'Cost', key: 'cost', type: 'currency' },
    { label: 'Notes', key: 'notes', newRow: true, type: 'textarea' },
  ];

  return (
    <>
      <SectionTitle>Health</SectionTitle>
      <Row>
        <Select
          label='Vaccination Status'
          options={options.vaccinationStatus}
          {...getFormProps('vaccinationStatus')}
        />
        <Select
          label='Neutered Status'
          options={options.neuteredStatus}
          {...getFormProps('neuteredStatus')}
        />
        <Input
          label='Date Deflead'
          type='date'
          {...getFormProps('dateDeflead')}
        />
        <Input
          label='Date Dewormed'
          type='date'
          {...getFormProps('dateDewormed')}
        />
      </Row>
      <Row>
        <Select label='Diet' options={options.diet} {...getFormProps('diet')} />
        <Select
          label='Health Issues'
          options={options.healthIssues}
          {...getFormProps('healthIssues')}
          multi
        />
        <Input
          label='Health Issues (Other)'
          {...getFormProps('healthIssuesOther')}
        />
      </Row>
      <SectionTitle style={{ marginTop: 15 }}>
        Medical Appointments
      </SectionTitle>
      <Row>
        <ListInput
          alwaysShowLabel
          inputProps={medicalInputs}
          {...getFormProps('medicalProcedures')}
        />
      </Row>
    </>
  );
};

export default Health;
