import { useState } from 'react';

import { useWidth } from 'hooks';

import {
  HiddenInput,
  UploadArea,
  UploadIcon,
  ImagesContainer,
  Placeholder,
  UploadedImageContainer,
  DeleteImage,
  DeleteIcon,
} from './styled';

const UploadedImage = ({ src, onDelete }) => {
  const [tapped, setTapped] = useState(false);
  const { isDesktop } = useWidth();

  const handleDelete = () => {
    if (isDesktop) {
      onDelete();
    } else {
      tapped && onDelete();
    }
  };

  return (
    <UploadedImageContainer onClick={() => setTapped(true)}>
      <img src={src} alt='' />
      <DeleteImage onClick={handleDelete}>
        <DeleteIcon icon='bin' />
      </DeleteImage>
    </UploadedImageContainer>
  );
};

const Images = ({ value, onDelete, showBlanks, multiple }) => {
  if (multiple) {
    return (
      <ImagesContainer multiple={multiple}>
        {value?.length === 0 &&
          showBlanks &&
          Array.from(Array(3)).map((_, i) => <Placeholder key={i} />)}
        {value?.map((image, i) => (
          <UploadedImage
            key={i}
            src={image.src}
            onDelete={() => onDelete(image.name)}
          />
        ))}
      </ImagesContainer>
    );
  }
  return (
    <ImagesContainer>
      {!value &&
        showBlanks &&
        Array.from(Array(3)).map((_, i) => <Placeholder key={i} />)}
      {value && (
        <UploadedImage src={value.src} onDelete={() => onDelete(value.name)} />
      )}
    </ImagesContainer>
  );
};

const ImageUpload = ({
  onChange,
  value,
  placeholder,
  multiple,
  showBlanks,
}) => {
  const handleOnLoad = (e, file) => {
    const image = new Image();
    image.src = e.target.result;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const resizeCanvas = document.createElement('canvas');
      const rCtx = resizeCanvas.getContext('2d');
      const maxSize = 1024;

      resizeCanvas.width = image.width;
      resizeCanvas.height = image.height;

      rCtx.drawImage(image, 0, 0, image.width, image.height);

      let width = image.width;
      let height = image.height;

      while (width > maxSize || height > maxSize) {
        const resizedWidth = Math.round(width * 0.75);
        const resizedHeight = Math.round(height * 0.75);
        rCtx.drawImage(
          resizeCanvas,
          0,
          0,
          width,
          height,
          0,
          0,
          resizedWidth,
          resizedHeight
        );
        width = resizedWidth;
        height = resizedHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(resizeCanvas, 0, 0);
      if (multiple) {
        onChange((s) => [
          ...s,
          { name: file.name, src: canvas.toDataURL('image/jpeg', 0.8) },
        ]);
      } else {
        onChange({ name: file.name, src: canvas.toDataURL('image/jpeg', 0.8) });
      }
    };
  };

  const handleFiles = (e) => {
    const { files } = e.target;
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = (loaded) => handleOnLoad(loaded, file);
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = (name) => {
    if (multiple) {
      const without = [...value].filter((image) => image.name !== name);
      onChange(without);
    } else {
      onChange();
    }
  };

  return (
    <>
      <HiddenInput
        type='file'
        name='file'
        id='file'
        accept='image/*'
        onChange={handleFiles}
        multiple={multiple}
      />
      <UploadArea htmlFor='file'>
        <UploadIcon icon='uploadLarge' />
        <span>{placeholder}</span>
      </UploadArea>
      <Images
        multiple={multiple}
        value={value}
        onDelete={handleDelete}
        showBlanks={showBlanks}
      />
    </>
  );
};

ImageUpload.defaultProps = {
  onChange: () => {},
  placeholder: 'Click here to select images',
  multiple: true,
  showBlanks: true,
};

export default ImageUpload;
