import { useEffect, useRef, useState } from 'react';
import { PopoverContainer, Content, Point } from './styled';

const Popover = ({ content }) => {
  const ref = useRef();
  const [position, setPosition] = useState('bottom');

  useEffect(() => {
    if (ref.current) {
      const { x, width } = ref.current.getBoundingClientRect();
      const x1 = x + width;
      if (x1 > window.innerWidth) {
        setPosition('left');
      }
    }
  }, [ref]);

  return (
    <PopoverContainer ref={ref} position={position}>
      <Content position={position}>{content}</Content>
      <Point position={position} />
    </PopoverContainer>
  );
};

export default Popover;
