import styled from 'styled-components';

import { Button as CommonButton, Icon } from 'components/common';

const ANIMATION_TIME = '0.3s';

export const Container = styled.div`
  @keyframes modal-container-entry {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }
  }

  @keyframes modal-container-exit {
    from {
      background: ${(p) => p.theme.colours.primary.dark1}99;
    }

    to {
      background: ${(p) => p.theme.colours.primary.dark1}00;
    }
  }

  animation-duration: ${ANIMATION_TIME};
  animation-fill-mode: forwards;
  animation-name: ${(p) => p.transition};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  box-sizing: border-box;
  z-index: 10;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 0;
  }
`;

export const Content = styled.div`
  @keyframes modal-content-entry {
    from {
      bottom: -75vw;
      opacity: 0;
    }

    to {
      bottom: 0vw;
      opacity: 1;
    }
  }

  @keyframes modal-content-exit {
    from {
      bottom: 0vw;
      opacity: 1;
    }

    to {
      bottom: -75vw;
      opacity: 0;
    }
  }

  animation-duration: ${ANIMATION_TIME};
  animation-name: ${(p) => p.transition};
  border-radius: 8px;
  background: white;
  width: 100%;
  height: 75%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  ${(p) => p.theme.breakpoints.desktop} {
    max-width: 670px;
    padding: 60px 50px 20px;
    height: 55vh;
    margin-top: -40px;
  }
`;

export const CloseIcon = styled(Icon)`
  width: 15px;
  fill: ${(p) => p.theme.colours.primary.dark3};
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 15px;
  ${(p) => p.theme.breakpoints.desktop} {
    padding: 30px;
  }
`;

export const Button = styled(CommonButton)`
  margin-top: 20px;
`;
