import { CurrencyInput, Checkbox, Select, Input } from 'components/common';

import { Row, SectionTitle } from './styled';

const Financial = ({ getFormProps, options, details }) => {
  return (
    <>
      <SectionTitle>Financial</SectionTitle>
      <Row>
        <CurrencyInput label='Rehoming Fee' {...getFormProps('rehomingFee')} />
        <CurrencyInput label='VAT Due' {...getFormProps('vatDue')} />
        <Checkbox
          style={{ marginTop: 40 }}
          label='Paid'
          size='large'
          {...getFormProps('paid')}
        />
      </Row>
      <Row>
        <Select
          disabled={!details.paid}
          label='Payment Method'
          options={options.paymentMethod}
          {...getFormProps('paymentMethod')}
        />
        <Input
          disabled={details.paymentMethod !== 'Other'}
          label='Payment Method (Other)'
          {...getFormProps('paymentMethodOther')}
        />
      </Row>
    </>
  );
};

export default Financial;
