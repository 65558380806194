import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 15px 0px;
  cursor: pointer;
  margin: 0px 5px;
`;

export const Line = styled.div`
  background: ${(p) => p.theme.colours.shade.six};
  height: 4px;
  width: 100%;
  position: absolute;
  ${(p) => p.theme.breakpoints.desktop} {
    height: 5px;
  }
`;

export const Fill = styled.div`
  height: 4px;
  transition: all 0.2s ease;
  position: absolute;
  width: ${(p) => p.width}%;
  background: ${(p) => p.theme.colours.secondary.one};
  ${(p) => p.theme.breakpoints.desktop} {
    height: 5px;
  }
`;

export const Dot = styled.div`
  cursor: pointer;
  z-index: 1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transition: all 0.2s ease;
  background: ${(p) =>
    p.isFilled ? p.theme.colours.secondary.one : p.theme.colours.shade.six};
  position: absolute;
  left: ${(p) => p.left}%;
  right: auto;
  transform: translateX(-50%);
`;

export const Value = styled.div`
  position: absolute;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: ${(p) =>
    !p.value && p.value !== 0
      ? p.theme.colours.shade.six
      : p.theme.colours.secondary.one};
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease;
  left: ${(p) => p.left}%;
  right: auto;
  transform: translateX(-50%);
  user-select: none;
`;

export const Labels = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 600;
`;

export const Label = styled.div``;
