import styled from 'styled-components';

export const positions = {
  topLeft: 'top: -15px; left: -15px;',
  topRight: 'top: -15px; right: -15px;',
  bottomLeft: 'bottom: -15px; left: -15px;',
  bottomRight: 'bottom: -15px; right: -15px;',
};

export const cursors = {
  topLeft: 'nw-resize',
  topRight: 'ne-resize',
  bottomLeft: 'sw-resize',
  bottomRight: 'se-resize',
};

export const Container = styled.div`
  position: relative;
  touch-action: none;
  user-select: none;
  height: 100%;
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Boundary = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
  height: ${(p) => (p.height ? p.height + 'px' : 'auto')};
  overflow: hidden;
`;

export const Cropper = styled.div.attrs((props) => ({
  style: {
    top: `${props.top}px`,
    left: `${props.left}px`,
    width: `${props.width}px`,
    height: `${props.height}px`,
  },
}))`
  position: absolute;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  cursor: move;
`;

export const ResizeHandleContainer = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  user-select: none;
  cursor: ${(p) => cursors[p.position]};
  ${(p) => positions[p.position]}
`;

export const ResizeHandle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 1);
  cursor: ${(p) => cursors[p.position]};
`;
